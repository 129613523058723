/*

"Side by Side" (sidebyside) and "Search" (search) header


- .pageheader__background
  Is used to display the background a little wider than the normal page content.
  We do need to give the textpanel the same background color, just so accessibility checker which aren't smart enough
  to see this is the background don't think the white text is on a white background.


- We give the .pageheader__background and .pageheader__text
  - a margin-top and margin-bottom to give the impression the image sticks out

*/
/* input and compact searchbutton (icon only) connected to eachother */
/* NOTE: use ~, we can have a crumblepath inbetween */
.pageheader--sidebyside-flex ~ .page__body > .page__contentarea {
  --header-to-content-margin: var(--header-sidebysideflex-margin-to-content);
}

/*
.page__contentarea:has(.pageheader--sidebyside-flex)
{
  --header-to-content-margin:  var(--header-sidebyside-margin-to-content);
}
*/
html.pageheadertype--sidebyside-flex .header-crumblepath {
  border-bottom: 0;
}

@media (max-width: 767px) {
  .pageheader--sidebyside-flex {
    --formcontrols-height: 48px;
    /*
      .spc-search__heading
      {
        display: block;
        width: 100%;
        margin-bottom: 12px;

        font: var(--rtd-h3-font);
        color: var(--rtd-heading-color);
      }
    */
  }
  .pageheader--sidebyside-flex .spc-searchbar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }
  .pageheader--sidebyside-flex .spc-searchbar__input.spc-searchbar__input {
    flex: 1 1 0;
    /* have input and button side-by-side */
    border-radius: var(--header-searchbar-radius);
    margin: 0; /* Safari has some default margins on <input> */
    padding-right: 55px;
  }
  .pageheader--sidebyside-flex .spc-searchbar__searchbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    flex: none;
    border-radius: 0;
    font-size: 0;
    height: var(--formcontrols-height);
    position: absolute;
    right: 0;
    /*
        margin-left: calc(-1 * 55px);

        width: 30px;
        padding-right: 25px;
    */
    color: var(--color-purple1);
    /*
        // @include wh-form-hidenativecontrol;
        position: absolute;
        height: 0;
        width: 0;
        min-height: 0;
        padding: 0;
        margin: 0;
        border: 0;
        overflow: hidden;
    */
  }
  .pageheader--sidebyside-flex .spc-searchbar__searchbutton::after {
    font: var(--fontawesome-regular);
    font-size: 20px;
    content: var(--fa-search);
  }
}
@media (min-width: 768px) {
  .pageheader--sidebyside-flex {
    --formcontrols-height: 58px;
  }
  .pageheader--sidebyside-flex .spc-searchbar {
    grid-column: 1/-1;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    --formcontrols-height: 58px;
  }
  .pageheader--sidebyside-flex .spc-search__heading {
    display: none;
  }
  .pageheader--sidebyside-flex .spc-searchbar__input.spc-searchbar__input {
    flex: 1 1 0px;
    border-radius: 6px;
    margin: 0; /* Safari has a default margin for <input> */
  }
  .pageheader--sidebyside-flex .spc-searchbar__searchbutton {
    margin-left: 13px;
  }
  .pageheader--sidebyside-flex .spc-searchbar__searchbutton::after {
    vertical-align: middle;
    font: var(--fontawesome-regular);
    font-size: 17px;
    content: var(--fa-search);
    margin-left: 30px;
  }
}
.pageheader--sidebyside-flex .spc-eventtile__specsheet {
  color: var(--currenttheme-text-color);
}
.pageheader--sidebyside-flex .spc-eventtile__specsheet .icon {
  color: var(--color-theme);
}

/*
.pageheader--withfiltertoggle .pageheader__text
{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;

  column-gap: 30px;
}

.pageheader--withfiltertoggle .pageheader__text > *
{
  flex: 0 0 100%;
}
.pageheader--withfiltertoggle .pageheader__text > .pageheader__togglefilterbutton
{
  flex: none;
}
*/
.pageheader--sidebyside-flex {
  --formcontrols-focus-boxshadow: 0 0 0 3px #FFFFFF70;
  --formcontrols-focus-outline: 2px solid #FFFFFF70;
  --header-background: var(--currenttheme-background);
  --header-togglefilter-textcolor: #FFFFFF;
  /*
  NOTE: Most website will use this news icon

    .pageheader__when::before
    {
      font: var(--header-when-icon-font);
      content: var(--header-when-icon-content);

      margin-right: 10px;
    }
  */
  /*
    .pageheader--withfiltertoggle .pageheader__text > .pageheader__togglefilterbutton
    {
      // flex: none;
      margin-left: auto;
    }
  */
  /* shift so it connects to the visual header bar (from which the image protrudes) */
  /* .pageheader--sidebyside-flex.pageheader--withimage */
  /*
  Mobile layout uses the !!! sidebyside !!! mobile layout:
  - title at top
  - image after title
  - description and filter toggle at the bottom
  */
  /* When this happens depends on the aspect-ratio of the image
     and whether the text content is content with little space */
}
.pageheader--sidebyside-flex.pageheader--type-zvi-home {
  --formcontrols-focus-outline: unset;
}
.pageheader--sidebyside-flex .pageheader__tags {
  margin-bottom: 22px;
}
.pageheader--sidebyside-flex .pageheader__when {
  color: var(--header-sidebyside-pagedate-color);
  font: var(--header-sidebyside-pagedate-font);
  margin-bottom: var(--header-sidebyside-pagedate-spacing);
}
.pageheader--sidebyside-flex .pageheader__when__prefix {
  font-weight: bold;
  margin-right: 10px;
}
.pageheader--sidebyside-flex .pageheader__title {
  color: var(--currenttheme-heading-color);
  font: var(--header-sidebyside-title-font);
  text-wrap: balance;
}
.pageheader--sidebyside-flex .pageheader__subtitle {
  font: var(--header-sidebyside-subtitle-font);
  text-wrap: balance;
}
.pageheader--sidebyside-flex .pageheader__source {
  font: var(--header-sidebyside-source-font);
  color: inherit;
}
.pageheader--sidebyside-flex .pageheader__text > p {
  text-wrap: balance;
}
.pageheader--sidebyside-flex .pageheader__text__specsheet {
  margin-top: 15px;
}
@media screen {
  .pageheader--sidebyside-flex {
    padding-top: var(--header-sidebysideflex-margin-above);
  }
  .pageheader--sidebyside-flex.pageheader {
    --rtd-heading-color: var(--currenttheme-heading-color);
    --rtd-text-color: var(--currenttheme-text-color);
    --rtd-link-color: var(--currenttheme-text-color);
  }
  .pageheader--sidebyside-flex.pageheader--search {
    --header-background: #ECE4EC;
    --rtd-heading-color: var(--currenttheme-heading-color);
    --rtd-text-color: #000000;
    --rtd-link-color: #000000;
    --header-togglefilter-textcolor: var(--currenttheme-headin);
  }
  .pageheader--sidebyside-flex.pageheader--search .spc-searchbar__searchbutton {
    --button-color: var(--color-purple-dark);
    --button-bordercolor: var(--color-purple-dark);
  }
  .pageheader--sidebyside-flex:focus .pageheader__background {
    outline: var(--formcontrols-focus-outline);
    outline-offset: var(--formcontrols-focus-outline-offset);
  }
  .pageheader--sidebyside-flex .pageheader__text {
    color: var(--rtd-text-color);
  }
}
.pageheader--sidebyside-flex.pageheader {
  display: flex;
  column-gap: 63px;
  row-gap: 30px;
  flex-wrap: wrap;
  grid-column: var(--pagegrid-col-verywide-outsidegutter-text);
}
.pageheader--sidebyside-flex .pageheader__background {
  display: none;
}
.pageheader--sidebyside-flex .pageheader__imagewrapper {
  flex: none;
  aspect-ratio: var(--header-sidebyside-image-aspectratio);
  width: clamp(350px, 50vw, 500px);
}
.pageheader--sidebyside-flex .pageheader__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pageheader--sidebyside-flex .pageheader__imagecredits {
  margin-top: 5px;
  justify-self: end; /* at the right */
  color: var(--common-caption-color);
  font: var(--pageheader-credits-font);
}
.pageheader--sidebyside-flex + .page__body > .pagetabs:first-child {
  margin-top: calc(var(--header-sidebyside-image-protrude-amount) * -1);
  z-index: -1;
}
.pageheader--sidebyside-flex .pageheader__image {
  border-radius: var(--pageheader-photo-border-radius);
  display: block;
  aspect-ratio: var(--header-sidebyside-image-aspectratio);
  box-shadow: var(--pageheader-photo-box-shadow);
}
.pageheader--sidebyside-flex * + .pageheader__searchpanel {
  margin-top: 25px;
}
.pageheader--sidebyside-flex .pageheader__togglefilterbutton {
  display: none;
}
.pageheader--sidebyside-flex:not(.pageheader-wrapping) .pageheader__text {
  flex: 1 0 0;
  min-width: clamp(min(500px, 40vw), min(500px, 100vw), 30vw);
}
.pageheader--sidebyside-flex:not(.pageheader-wrapping) .pageheader__title + .pageheader__text__description > *:first-child {
  margin-top: 30px;
}
.pageheader--sidebyside-flex.pageheader-wrapping {
  grid-column: var(--pagegrid-col-verywide);
  column-gap: 0;
  row-gap: 0;
  /* Image fullwidth */
  /* Image inset (header background around the image) *****************
  &.pageheader
  {
    padding-bottom: 30px;
  }

  .pageheader__image
  {
    // border-radius: var(--contentpanels-radius);
  }
  /*************************/
  /*
  .pageheader__when
  .pageheader__title
  .pageheader__imagewrapper
  .pageheader__text__description
  .pageheader__togglefilterbutton
  */
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__image {
  min-width: 0;
  max-width: none;
  min-height: 0;
}
.pageheader--sidebyside-flex.pageheader-wrapping.pageheader {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-template-rows: min-content min-content min-content min-content;
  background: var(--header-background);
  padding-top: 15px;
  --header-sidebyside-image-aspectratio: 16 / 9;
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__text {
  min-width: 0;
  padding-top: 25px;
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__title {
  grid-row: 1;
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__title + .pageheader__text__description > *:first-child {
  margin: 0;
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__imagewrapper {
  grid-row: 2;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__text > *,
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__imagewrapper {
  grid-column: var(--pagegrid-col-verywide);
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__text__specsheet {
  margin: 0;
  margin-bottom: 20px;
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__imagewrapper {
  grid-column: var(--pagegrid-col-verywide); /* for illustration */
}
.pageheader--sidebyside-flex.pageheader-wrapping.pageheader--search {
  padding-top: 18px;
  padding-bottom: 20px;
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__background {
  display: none;
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__text {
  display: contents;
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__image {
  display: block;
  height: auto;
  aspect-ratio: var(--header-sidebyside-image-aspectratio);
  box-shadow: var(--pageheader-photo-box-shadow);
}
.pageheader--sidebyside-flex.pageheader-wrapping .pageheader__imagecredits {
  display: none;
}
.pageheader--sidebyside-flex.pageheader-wrapping * + .pageheader__searchpanel {
  margin-top: 20px;
}
.pageheader--sidebyside-flex.pageheader-wrapping .spc-search__heading {
  font-size: 16px;
  margin-bottom: 6px;
}