.page__currentlevel_nav
{
  padding: 15px;
  width: 100%;

  border: 1px solid #DEDEDE;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.25);

  pointer-events: auto; /* reenable (the .deeplinks has pointer-events: none;) */



  /* Togglers level 1 */
  --sidebar-menu-level1-toggleicon-font:              var(--fontawesome-regular);
  --sidebar-menu-level1-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level1-toggleicon-expanded-content:  var(--fa-chevron-down);
  --sidebar-menu-level1-toggleicon-fontsize:          14px;

  /* Togglers level 2 */
  --sidebar-menu-level2-toggleicon-font:              var(--fontawesome-regular);
  --sidebar-menu-level2-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level2-toggleicon-expanded-content:  var(--fa-chevron-down);
  --sidebar-menu-level2-toggleicon-fontsize:          14px;

  /* Togglers level 3 */
  --sidebar-menu-level3-toggleicon-font:              var(--fontawesome-regular);
  --sidebar-menu-level3-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level3-toggleicon-expanded-content:  var(--fa-chevron-down);
  --sidebar-menu-level3-toggleicon-fontsize:          14px;



  /* Togglers level 1 */
  --sidebar-menu-level1-font:      14px/19px var(--rtd-heading-fontfamily);
  --sidebar-menu-level1-padding:   4px 0px 8px 20px;
  --sidebar-menu-level1-expandedbgcolor: transparent;
  --sidebar-menu-level1-textcolor: #333333;

  /* Menu item at level 2 */
  /* Levels 2, 3 and 4 are within the pulldown */
  --sidebar-menu-level2-font:      14px/19px var(--rtd-heading-fontfamily);
  --sidebar-menu-level2-padding:   8px 10px 8px 39px;
  --sidebar-menu-level2-expandedbgcolor: transparent;
  --sidebar-menu-level2-textcolor: #333333;

  /* Menu item at level 3 */
  --sidebar-menu-level3-font:      14px/19px var(--rtd-heading-fontfamily);
  --sidebar-menu-level3-padding:   8px 10px 8px 56px; /* + 17px */
  --sidebar-menu-level3-textcolor: var(--color-purple-dark);

  /* Menu item at level 4 */
  --sidebar-menu-level4-font:      12px/16px var(--rtd-heading-fontfamily);
  --sidebar-menu-level4-padding:   8px 10px 8px 73px; /* + 17px */
  --sidebar-menu-level4-textcolor: var(--color-purple-dark);
}

.content-sidemenu__parentlinks
{
/*   font: 14px/19px var(--rtd-heading-fontfamily);*/
  font: var(--sidebar-menu-level1-font);
}

.content-sidemenu__parentlinks a
{
  display: block;
  text-decoration: none;
  color: #333333;
  padding: var(--sidebar-menu-level1-padding);
  padding-left: 0;
}

.content-sidemenu__parentlinks a .icon
{

}

.content-sidemenu__parentlinks a .icon::before
{
  content: var(--fa-chevron-left);
  font: var(--fontawesome-solid);
  font-size: 12px;
  color: var(--color-theme);

  margin-right: 12px;
}

.content-sidemenu__parentlinks a:hover
{
  color: var(--color-theme);
}
.content-sidemenu__parentlinks a:hover .label
{
  text-decoration: underline;
}

.content-sidemenu__parentlinks a + a
{
  margin-top: 2px;
}

.content-sidemenu__currentlevel
{
  margin-top: 10px;
}
