.form__uploadfieldselect {
  margin-left: 16px;
}

.wh-form__dateinputgroup input,
.wh-form__timeinputgroup input {
  text-transform: uppercase;
}

.wh-form__arrayinput {
  width: 0;
}

:root {
  --wh-field-textinput-height: 46px;
  --wh-label-font-size: 19px;
  --wh-button-background-color: #5F0EAE;
  --wh-button-border: 0 none;
  --wh-button-color: #fff;
  --wh-button-padding: 12px 15px;
  --wh-button-border-radius: 3px;
  --wh-button-height: 46px;
  --wh-field-font-size: 16px;
  --wh-field-padding: 12px 15px;
  --wh-field-border-radius: 3px;
  --wh-field-color: #979797;
  --wh-field-color--hover: inherit;
  --wh-field-color--focus: inherit;
  --wh-field-color--error: #D50000;
  --wh-field-color--disabled: #C5C5C5;
  --wh-field-border-color: #767676;
  --wh-field-border-color--hover: #767676;
  --wh-field-border-color--focus: #0000AA;
  --wh-field-border-color--error: #D40819;
  --wh-field-border-color--disabled: #F2F2F2;
  --wh-field-background-color: #fff;
  --wh-field-background-color--hover: #fff;
  --wh-field-background-color--focus: #fff;
  --wh-field-background-color--error: #fff;
  --wh-field-background-color--disabled: #ddd;
  --wh-field-border-box-shadow: none;
  --wh-field-border-box-shadow--hover: none;
  --wh-field-border-box-shadow--focus: 0 0 0 3px #540A5130;
  --wh-field-border-box-shadow--error: none;
  --wh-field-border-box-shadow--disabled: none;
  --wh-imgedit-padding: 4px;
  --wh-imgedit-margin: 2px 3px;
  --wh-imgedit-width: 150px;
  --wh-imgedit-height: 200px;
  --wh-imgedit-explain-padding: 5px;
  --wh-imgedit-uploadicon-width: 50px;
  --wh-imgedit-uploadicon-height: 50px;
  --wh-imgedit-uploadicon-margin: 0 0 20px;
  --wh-fileedit-name-margin-right: 5px;
  --wh-fileedit-margin-top: 10px;
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.wh-form__richtext .centercontent {
  padding-right: 0;
  padding-left: 0;
}

.wh-form {
  /* FIXME: this is under the assumption that the first fieldgroup is visible. */
  /*
  2 col layout

    .wh-form__fieldgroup > .wh-form__label
    {
      flex: 0 0 var(--formlayout-label-width);
      min-width: var(--formlayout-label-width);
      max-width: var(--formlayout-label-width);
      word-break: break-word; / * if there's no other option allow breaking inside a word * /
    }


    .wh-form__fieldgroup > .wh-form__fields
    {
      max-width: calc(100% - var(--formlayout-label-width));
    }

    .wh-form__fieldgroup--richtext > .wh-form__fields
    {
      max-width: 100%;
    }
  */
  /*
  // Mark: fix om te voorkomen dat lange select options de layout oprekken
  select:not(.wh-rtd__toolbarstyle)
  {
    flex: 1 1 auto;
    width: 0;
  }
  */
  /* date/time */
  /*
  FILL WHOLE AREA

    .wh-form__buttongroup.wh-form__navbuttons > *
    {
      justify-content: center;
      flex: 1 0 auto;
    }
  */
}
.wh-form .wh-form__label {
  color: var(--formlayout-label-color);
  font: var(--formlayout-label-font);
  padding-right: var(--formlayout-label-spacing-h);
  /* STACKED */
  margin-bottom: var(--formlayout-label-spacing-v);
}
.wh-form .wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  /* FIXME */
  margin-left: -17px;
  margin-right: -17px;
  margin-top: calc(var(--formlayout-fieldlines-spacing) - var(--formlayout-fieldgroup-padding-v));
  padding: var(--formlayout-fieldgroup-padding);
  margin-bottom: var(--formlayout-fieldgroup-spacing);
}
.wh-form .wh-form__fieldgroup.wh-form__fieldgroup:first-child {
  margin-top: 0;
}
.wh-form .wh-form__fieldgroup--richtext.wh-form__fieldgroup--richtext {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
.wh-form .wh-form__fieldgroup.wh-form__fieldgroup--nomargin:not(.wh-form__fieldgroup--hidden) {
  margin-bottom: 0;
}
.wh-form .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-form .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-form .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
.wh-form .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
.wh-form .wh-form__optionlabel, .wh-form .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-form .wh-form__label:empty::after {
  display: none;
}
.wh-form .wh-form__fieldgroup {
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
  /*
        & > .wh-form__label
      , & > .wh-form__fields
      {
        flex: none;
        max-width: calc(100vw - 40px);
        min-width: 0;
      }
  */
}
.wh-form .wh-form__fieldgroup > .wh-form__label.wh-form__label {
  flex: 0 0 auto;
  padding-bottom: 3px;
}
.wh-form .wh-form__fieldgroup .wh-form__subfield {
  flex-wrap: wrap;
}
.wh-form .wh-form__fields {
  width: 100%;
}
.wh-form select + .wh-form__subfield > label,
.wh-form .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form input, .wh-form select:not(.wh-rtd__toolbarstyle),
.wh-form .wh-form__dateinputgroup,
.wh-form .wh-form__timeinputgroup {
  max-width: 100%;
  flex: 1;
}
.wh-form input:focus, .wh-form select:not(.wh-rtd__toolbarstyle):focus,
.wh-form .wh-form__dateinputgroup:focus,
.wh-form .wh-form__timeinputgroup:focus {
  outline: none;
  box-shadow: 0 0 3px rgb(0, 150, 255);
}
.wh-form textarea {
  padding-top: 11px;
  padding-bottom: 11px;
}
.wh-form textarea {
  min-height: 140px;
}
.wh-form .wh-form__fieldgroup--error input, .wh-form .wh-form__fieldgroup--error select,
.wh-form .wh-form__fieldgroup--error textarea,
.wh-form .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  background-color: var(--formcontrols-backgroundcolor-error);
  border-color: var(--formcontrols-border-color-error);
  color: var(--formcontrols-textcolor-error);
}
.wh-form .wh-form__fieldgroup--error {
  background-color: var(--formlayout-fieldgroup-bgcolor-error);
  border-top: 3px solid #FFFFFF;
}
.wh-form .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: var(--formlayout-fieldgroup-error-textcolor);
  font: var(--formlayout-fieldgroup-error-font);
  padding: 8px 0 0;
}
.wh-form .wh-form__error > * {
  grid-column: 2;
}
.wh-form .wh-form__error::before {
  grid-column: 1;
  grid-row: 1;
  font: var(--fontawesome-regular);
  content: var(--fa-exclamation-triangle);
  font-size: 17px;
  margin-right: 10px;
}
.wh-form .wh-form__fieldgroup--error .wh-form__error {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: baseline;
}
.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-form .wh-form__dateinputgroup, .wh-form .wh-form__timeinputgroup {
  flex: none;
  padding: 0;
}
.wh-form .wh-form__dateinputgroup input, .wh-form .wh-form__timeinputgroup input {
  box-shadow: none;
}
.wh-form .wh-form__dateinputgroup__line::after, .wh-form .wh-form__timeinputgroup__line::after {
  top: 12px;
}
.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form .wh-form__optiondata > * {
  padding-left: var(--formlayout-option-label-spacing-h);
}
.wh-form .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}

.wh-form__buttongroup {
  display: flex;
  margin: var(--formlayout-buttongroup-margin);
  border-top: var(--formlayout-buttongroup-border);
  padding-top: var(--formlayout-buttongroup-border-margin);
}
.wh-form__buttongroup .wh-form__button--next, .wh-form__buttongroup .wh-form__button--submit {
  margin-left: auto;
}

.wh-form__navbuttons {
  align-items: center;
}

.wh-rtd__editor {
  width: 100%;
  min-height: 200px;
}
.wh-rtd__editor .wh-rtd__html {
  margin: 0;
  padding: 5px;
}
.wh-rtd__editor .wh-rtd__body {
  padding: 5px 8px;
}
.wh-rtd__editor .wh-rtd-toolbar select {
  height: 23px;
  border: 1px solid #AAAAAA;
  border-radius: 1px;
  font-size: 15px;
  padding: 0 10px;
  min-height: 22px;
}