/*
Include stylesheets here which need access to the
settings for the current webdesign.
*/
/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
/* TUE BOOST
$pagegrid-columns:       12;
$pagegrid-columnwidth:   80px;
$pagegrid-gutter:        24px;
*/
/* 4TU
$pagegrid-columns:       12;
$pagegrid-columnwidth:   84px; // was 65px; // max width of a column
$pagegrid-gutter:        26px; // was 30px
*/
/* ACOI */
/*
$pagegrid-columns:       12;
$pagegrid-columnwidth:   80px;
$pagegrid-gutter:        24px;
*/
/* ZVI */
/*
$pagegrid-columns:       12;
$pagegrid-columnwidth:   65px;
$pagegrid-gutter:        30px;
*/
/*

Grid rows:
1 - .header-top
2 - .header-menubar
3 - .header-crumblepath
4 - .pageheader
5 - .page__body
6 - .footer

z-index:

10 - --zindex-overlap-deeplinks: 10;
11 - --zindex-overlap-contentarea: 11;

11 - .header-menubar-background
12 - .header-menubar
13 - .header-searchpanel-top/bottom/content/closebutton
14 - .header-menubar:hover ~ .header-menubar-background
15 - .header-menubar:hover

Usage:

- This is an 12 column design setup
- Inbetween each column is a gutter (which is a column in our grid-template-columns)
- Around these is a extra column meant for visually extending certain page elements outside of the normal 12 column contentarea
- Around this all are two columns to keep whitespace around all content.
  Allowing even the extended content or backgrounds to be kept from the viewport's edge.
- For visually pleasing wide panels (such as pageheader/menu/footer)
  we have a --pagegrid-col-verywide-outsidegutter-bg.
  When the viewport isn't wide enough to generate extra whitespace around the columns (excluding the outer left and right column)
  this CSS variable will be changed to fully extend to the viewports outer edges.
  (because it's ugly if these very wide opaque background contents have only a small whitespace around them..
  The need to either be free or fully fill up the full width for a clean uncluttered visual look)

- To use the grid on a container:

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);


It handles:
- footer pushed to bottom of viewport (or page)
- column layout
- ability for deeplinks hovering in contentarea
- topmenu bar scrolling away
- menubar getting sticky
- background for menubar sticky, but scrolling farther to give appearance of menubar shrinking
- menubar disappears when content doesn't fit (flex wrapping into the overflow of the menubar)

*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
html, body {
  padding: 0;
  margin: 0;
}

html {
  /* amount of space to keep empty */
  --pagegrid-sidepadding: 15px;
  /* The gutter in which backgrounds and special content can protrude */
  --pagegrid-gutteroutside-normal-min: 30px;
  --pagegrid-gutteroutside-normal-max: 135px;
  --pagegrid-columns: 12;
  --pagegrid-gutter: 30px;
  --pagegrid-columnwidth: 65px;
  --pagegrid-maxwidth: calc( var(--pagegrid-columnwidth) * var(--pagegrid-columns)
                           + var(--pagegrid-gutter) * (var(--pagegrid-columns) - 1)
                           + var(--pagegrid-gutteroutside-normal-max) * 2
                           );
  --pagegrid-maxwidth-nogutter:
                 calc( var(--pagegrid-columnwidth) * var(--pagegrid-columns)
                     + var(--pagegrid-gutter) * (var(--pagegrid-columns) - 1)
                     );
  --pagegrid-width-contentarea-nogutter:
                 min( calc(100% - var(--pagegrid-sidepadding) * 2 - var(--pagegrid-gutteroutside-normal-min) * 2 )
                    , var(--pagegrid-maxwidth-nogutter)
                    );
  /* The whitespace around all content which isn't supported to
     touch the viewport. Usually anything that isn't a background color/image.
  */
  --pagegrid-coldef-sidemargin:
          max( var(--pagegrid-sidepadding) /* At least the minimum requireded whitespace */
             , calc((100% - 1400px) / 2) /* Distribute left over space 1400 */
             );
  --pagegrid-template-columns:
            var(--pagegrid-coldef-sidemargin)
            var(--pagegrid-coldef-gutteroutside)
            repeat( calc(var(--pagegrid-columns) - 1)
                  , minmax(0, 100fr) var(--pagegrid-gutter)
                  )
            minmax(0, 100fr)
            var(--pagegrid-coldef-gutteroutside)
            var(--pagegrid-coldef-sidemargin);
  /* FIXME: may phase out vars below or replace them by CSS calculations */
  --pagegrid-gutteroutside-mobile: 15px;
  --pagegrid-gutteroutside: 30px;
  --pagegrid-maxwidth: 1380px;
  --pagegrid-verywide-maxwidth: 1224px;
  --pagegrid-coldef-sidemargin: max(var(--pagegrid-sidepadding), calc((100% - 1380px) / 2));
  --header-to-content-margin: var(--header-plain-margin-to-content);
  /* Allow gutters to be smaller so we can fit small screen */
}
@media (max-width: 550px) {
  html {
    --pagegrid-gutter: 5px;
    --pagegrid-sidepadding: 5px;
  }
}
@media (max-width: 680px) {
  html {
    --pagegrid-gutteroutside-normal-min: 10px;
  }
}
@media (max-width: 767px) {
  html {
    --pagegrid-coldef-gutteroutside: var(--pagegrid-gutteroutside-normal-min);
  }
}
@media (min-width: 768px) {
  html {
    --pagegrid-coldef-gutteroutside: clamp( var(--pagegrid-gutteroutside-normal-min)
                                          , calc((100% - var(--pagegrid-gutteroutside-normal-min) * 2 - var(--pagegrid-width-contentarea-nogutter)) / 2)
                                          , var(--pagegrid-gutteroutside-normal-max)
                                          );
  }
}
@media print {
  html {
    --pagegrid-coldef-gutteroutside: 0px; /* FIXME */
  }
}

body {
  --pagegrid-col-default-max8col: var(--pagegrid-col-default);
}
@media print {
  body {
    --pagegrid-col-narrow: var(--pagegrid-col-verywide);
    --pagegrid-col-default: var(--pagegrid-col-verywide);
  }
}

.site-dialog {
  --pagegrid-coldef-sidemargin: 10px;
  --pagegrid-coldef-gutteroutside: 10px;
  --pagegrid-gutter: 10px;
  --pagegrid-template-columns: var(--pagegrid-coldef-sidemargin) var(--pagegrid-coldef-gutteroutside) repeat( calc(var(--pagegrid-columns) - 1) , minmax(0, 100fr) var(--pagegrid-gutter) ) minmax(0, 100fr) var(--pagegrid-coldef-gutteroutside) var(--pagegrid-coldef-sidemargin);
}

.deeplinks-wrapper {
  left: var(--pagegrid-gutteroutside-normal-min);
}

html {
  --pagegrid-gutter-left-full: 3 / 8; /* no spacing to verywide content */
  --pagegrid-gutter-left: 2 / 6;
  --pagegrid-gutter-right: 23 / 26; /* next to verynarrow */
  --pagegrid-col-verynarrow: 9 / span 11;
  /* ZVI also takes the gutter around the 8 columns for it's contentarea */
  --pagegrid-col-narrow: 6 / span 17;
  --pagegrid-col-narrow-left: 6 / span 8; /* 2 column / left */
  --pagegrid-col-narrow-right: 15 / span 8;
  --pagegrid-col-wide: 5 / span 19;
  --pagegrid-col-wide-include-gutter: 4 / span 21;
  --pagegrid-col-verywide: 3 / 26;
  --pagegrid-col-verywide-left: 3 / 14;
  --pagegrid-col-verywide-right: 15 / 26;
  --pagegrid-col-verywide-outsidegutter: 2 / 27;
  --pagegrid-col-verywide-outsidegutter-text: var(--pagegrid-col-verywide-outsidegutter);
  --pagegrid-col-verywide-outsidegutter-bg: var(--pagegrid-col-verywide-outsidegutter);
  --pagegrid-col-text-edge-left: 2;
  --pagegrid-col-text-edge-right: 27;
  --pagegrid-col-narrow-gutter-right: 22 / span 5; /* contentarea at the right of narrow layout */
  --pagegrid-col-withsidebar-sidebar: 3 / 9;
  --pagegrid-col-withsidebar-content: 10 / 26;
  --pagegrid-col-withsidebar-tabs: 10 / 26; /* tabs are always allowed to use the full horizontal space



/*
Focus with these traits
- gutter in the middle is clear in both left/right focus mode
-
*/
  --pagegrid-col-verywide-focusleft: 3 / 14;
  --pagegrid-col-verywide-focusleftfull: 2 / 14;
  --pagegrid-col-verywide-right-extraspacing: 15 / 26;
  --pagegrid-col-verywide-focusright: 15 / 26;
  --pagegrid-col-verywide-focusrightfull: 15 / 27;
  --pagegrid-col-verywide-left-extraspacing: 3 / 14; /* keep an extra column spacing */
  --widget-cta-focusleft-text-col: 13 / 26;
  --widget-cta-focusleft-image-col: 2 / 12;
  --widget-cta-focusright-text-col: 2 / 16;
  --widget-cta-focusright-image-col: 17 / 27;
  --pagegrid-col-fullwidth: 1 / 28;
  --zindex-overlap-deeplinks: 10;
  --zindex-overlap-contentarea: 11;
}

@media (max-width: 1023px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-narrow);
    --pagegrid-col-default-left: var(--pagegrid-col-narrow-left);
    --pagegrid-col-default-right: var(--pagegrid-col-narrow-right);
  }
}
/* When the gutter becomes very small it's nicer to align these
   wide texts with the normal page content.
   (because a shift of 15px between this and normal content looks more like an error than a design choice)
*/
@media (max-width: 600px) {
  html {
    --pagegrid-col-verywide-outsidegutter-text: var(--pagegrid-col-verywide);
  }
}
/* If the viewport is smaller than our maximum contentarea size,
   we allow the header and footer to take the full width of the viewport and disable border radius on them.
   (This looks better than a small whitespace around them).
*/
@media (max-width: 1380px) {
  html {
    --contentpanels-radius-outsidegutter: 0px !important;
    --contentpanels-radius-large-viewport-edge: 0px;
    --contentpanels-radius-medium-viewport-edge: 0px;
    --contentpanels-radius-small-viewport-edge: 0px;
    --pagegrid-col-text-edge-left: 3;
    --pagegrid-col-text-edge-right: 26;
  }
  .pageheader__background,
  .footer__panel__background,
  .header-searchpanel-top,
  .header-searchpanel-bottom {
    --pagegrid-col-verywide-outsidegutter: 1 / -1;
  }
  .acoifilterpage.acoifilterpage .pageheader--type-search.pageheader .pageheader__background {
    grid-column: 1/-1;
  }
  .footer--zvi.footer--zvi {
    margin-bottom: 0;
  }
}
body {
  display: grid;
  min-height: 100vh;
  grid-template-rows: var(--pagegrid-rowsize-header-top) minmax(0, min-content) minmax(0, min-content) auto 1fr minmax(0, min-content);
  grid-template-columns: var(--pagegrid-template-columns);
}

.header-top-background,
.header-top {
  z-index: 1;
}

.deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
  z-index: 9; /* keep over page content */
}

.site-scrollprogressbar {
  z-index: 10;
}

.header-menubar-background {
  z-index: 11;
}

.header-menubar,
.header-filterbar__querycontainer .wh-autocomplete-container {
  z-index: 12;
}

.wh-autocomplete-values {
  z-index: 11;
}

.header-menubar:hover {
  z-index: 15;
}

.header-menubar:hover ~ .header-menubar-background {
  z-index: 14;
}

.page__contentarea__inset {
  --pagegrid-inset-zindex: 1;
}

.deeplinks-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
}

.deeplinks-wrapper.hide_deeplinks {
  opacity: 0;
  pointer-events: none;
}

.page-header__content {
  position: relative;
  z-index: 1;
}

.header-top {
  grid-row: 1;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.header-top-background {
  grid-row: 1;
}

.header-menubar-background {
  grid-row: 2;
}

.header-menubar {
  grid-row: 2;
  grid-column: 1;
  grid-column: var(--pagegrid-col-fullwidth);
}

.page-header__background {
  grid-row: 1/span 3;
  grid-column: var(--pagegrid-col-fullwidth);
  background-color: #F2EFED;
}

.header-crumblepath {
  grid-row: 3;
}

.pageheader {
  grid-row: 4;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.page-header__content {
  grid-row: 4;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.page__body {
  grid-row: 5;
  grid-column: var(--pagegrid-col-fullwidth);
}

.footer {
  grid-row: 6;
  grid-column: var(--pagegrid-col-fullwidth);
}

/* input and compact searchbutton (icon only) connected to eachother */
.header-searchpanel-top,
.header-searchpanel-bottom,
.header-searchpanel-content,
.header-searchpanel-closebutton {
  z-index: 13;
  opacity: 0;
  pointer-events: none;
  visibility: hidden; /* to take it out of the tabnavigation */
  transition: opacity 0.3s, visibility 0s linear 0.35s; /* at the end of the transition (to hidden) switch visibility to hidden to take the button out of the tabnavigation */
}

html.searchpanel-active .header-searchpanel-top,
html.searchpanel-active .header-searchpanel-bottom,
html.searchpanel-active .header-searchpanel-content,
html.searchpanel-active .header-searchpanel-closebutton {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  transition: opacity 0.3s, visibility 0s;
}

.header-searchpanel-closebutton {
  width: 40px;
  height: 40px;
  border: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  border-radius: var(--transparenticonbuttons-radius);
}

.header-searchpanel-closebutton::before {
  content: var(--fa-times);
  font: var(--fontawesome-light);
  font-size: 24px;
  transition: color 0.2s, background-color 0.2s;
}

.header-searchpanel-closebutton:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: #000000;
}

/*
Activating trait methods:
- .settings:has(.searchpanel-over-menubar:checked)
- style container
*/
.site--zvi {
  --searchpanel-activemenubar-visibility: visible;
  --searchpanel-gridrow-content: 3;
  --searchpanel-gridrow-background: 3;
  --searchpanel-content-zindex: 15;
}

@media (min-width: 950px) {
  .header-searchpanel-top, .header-searchpanel-bottom {
    grid-row: var(--searchpanel-gridrow-background);
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
  }
  .header-searchpanel-top {
    background: #5F0EAE; /* FIXME */
  }
  .header-searchpanel-bottom {
    background: var(--site-searchpanel-background);
    border-bottom: 1px solid #FFFFFF; /* zodat het niet wegvalt in een paarse header bij scrollen (als het over de pageheader float */
  }
  html:not(.searchpanel-active) .header-searchpanel-top, html:not(.searchpanel-active) .header-searchpanel-bottom,
  html:not(.searchpanel-active) .header-searchpanel-content,
  html:not(.searchpanel-active) .header-searchpanel-closebutton {
    display: none;
  }
  /* When the searchbar overlaps the menu we must make sure the now invisible
     menubar cannot be reached using tabnavigation.
     We can do this by setting visibility to hidden on the menubar content container. */
  html.searchpanel-active .header-top__content,
  html.searchpanel-active .header-menubar__content {
    visibility: var(--searchpanel-activemenubar-visibility);
  }
  .header-searchpanel-top {
    position: sticky;
    top: 15px;
    height: 30px;
    border-radius: var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter) 0 0;
  }
  .header-searchpanel-bottom {
    position: sticky;
    top: 22px;
    margin-top: 25px;
    height: 95px;
    align-self: end;
    border-radius: 0 0 var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter);
    margin-bottom: var(--contentpanels-radius);
  }
  .header-searchpanel-content {
    position: sticky;
    top: 26px;
    margin-top: 22px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 100%;
  }
  .header-searchpanel-closebutton {
    justify-self: end; /* at the right */
    align-self: center; /* vertical aligned */
    grid-row: 3;
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    color: #FFFFFF;
    position: sticky;
    z-index: 110;
    top: 47px;
  }
  /*
    .header-searchpanel-content
    {
      grid-row: var(--searchpanel-gridrow-content);
      // grid-column: var(--pagegrid-col-verywide);

      grid-column: var(--pagegrid-col-default);
    }

    .header-searchpanel-content
    {
      display: grid;
      align-content: center;
    }
  */
  /* Label "zoeken" naast de input */
}
@media (min-width: 950px) and (max-width: 1023px) {
  .header-searchpanel-content {
    grid-row: var(--searchpanel-gridrow-content);
    grid-column: var(--pagegrid-col-default);
  }
  .spc-search__heading {
    display: block;
    color: #FFFFFF;
    font: 21px var(--rtd-heading-fontfamily);
    padding-right: 10px;
    align-self: center;
  }
  .header-searchpanel-closebutton {
    margin-right: -6px;
  }
}
@media (min-width: 950px) and (min-width: 1024px) {
  /* Input aligned to page content */
  .header-searchpanel-content {
    grid-row: var(--searchpanel-gridrow-content);
    grid-column: var(--pagegrid-col-fullwidth);
  }
  .header-searchpanel-content .spc-searchbar.spc-searchbar {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .header-searchpanel-content .spc-search__heading.spc-search__heading {
    color: #FFFFFF;
    font: 32px var(--rtd-heading-fontfamily);
    grid-column: var(--pagegrid-gutter-left);
    text-align: right;
    padding-right: 30px;
    align-self: center;
  }
  .header-searchpanel-content .spc-searchbar__input {
    grid-column: var(--pagegrid-col-default);
    align-self: center;
  }
  .header-searchpanel-content .spc-searchbar .wh-autocomplete-container {
    grid-row: 2;
    grid-column: var(--pagegrid-col-default);
  }
  .header-searchpanel-closebutton {
    margin-right: 15px;
  }
}
@media (min-width: 950px) and (max-width: 500px) {
  .spc-search__heading {
    display: none;
  }
}
@media (min-width: 950px) {
  .spc-searchbar__searchbutton.spc-searchbar__searchbutton.spc-searchbar__searchbutton {
    width: 64px;
    margin-left: -64px;
    position: static;
    left: auto;
    right: auto;
  }
  .header-searchpanel-content {
    --header-searchbar-radius: var(--contentpanels-radius-medium);
    /*
      .spc-search__heading
      {
        display: block;
        width: 100%;
        margin-bottom: 12px;

        font: var(--rtd-h3-font);
        color: var(--rtd-heading-color);
      }
    */
  }
  .header-searchpanel-content .spc-searchbar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }
  .header-searchpanel-content .spc-searchbar__input.spc-searchbar__input {
    flex: 1 1 0;
    /* have input and button side-by-side */
    border-radius: var(--header-searchbar-radius);
    margin: 0; /* Safari has some default margins on <input> */
    padding-right: 55px;
  }
  .header-searchpanel-content .spc-searchbar__searchbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    flex: none;
    border-radius: 0;
    font-size: 0;
    height: var(--formcontrols-height);
    position: absolute;
    right: 0;
    /*
        margin-left: calc(-1 * 55px);

        width: 30px;
        padding-right: 25px;
    */
    color: var(--color-purple1);
    /*
        // @include wh-form-hidenativecontrol;
        position: absolute;
        height: 0;
        width: 0;
        min-height: 0;
        padding: 0;
        margin: 0;
        border: 0;
        overflow: hidden;
    */
  }
  .header-searchpanel-content .spc-searchbar__searchbutton::after {
    font: var(--fontawesome-regular);
    font-size: 20px;
    content: var(--fa-search);
  }
}
/* We we let the page content go the view content area
   we'll position the closebutton within that pagearea
   (instead of in the area sticking out of the page area.
*/
@media (max-width: 1023px) {
  /* The closebutton is placed outside of the .header-searchpanel-content,
     so we need to create whitespace to place that button in.
  */
  .header-searchpanel-content {
    padding-right: 40px;
  }
  .header-searchpanel-closebutton {
    grid-column: var(--pagegrid-col-verywide);
    margin-right: 0;
  }
}
@media (max-width: 949px) {
  .header-searchpanel-top, .header-searchpanel-bottom {
    grid-row: var(--searchpanel-gridrow-background);
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
  }
  .header-searchpanel-top {
    background: #5F0EAE; /* FIXME */
  }
  .header-searchpanel-bottom {
    background: var(--site-searchpanel-background);
    border-bottom: 1px solid #FFFFFF; /* zodat het niet wegvalt in een paarse header bij scrollen (als het over de pageheader float */
  }
  html:not(.searchpanel-active) .header-searchpanel-top, html:not(.searchpanel-active) .header-searchpanel-bottom,
  html:not(.searchpanel-active) .header-searchpanel-content,
  html:not(.searchpanel-active) .header-searchpanel-closebutton {
    display: none;
  }
  /* When the searchbar overlaps the menu we must make sure the now invisible
     menubar cannot be reached using tabnavigation.
     We can do this by setting visibility to hidden on the menubar content container. */
  html.searchpanel-active .header-top__content,
  html.searchpanel-active .header-menubar__content {
    visibility: var(--searchpanel-activemenubar-visibility);
  }
  .header-searchpanel-top {
    position: sticky;
    top: 15px;
    height: 30px;
    border-radius: var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter) 0 0;
  }
  .header-searchpanel-bottom {
    position: sticky;
    top: 22px;
    margin-top: 25px;
    height: 95px;
    align-self: end;
    border-radius: 0 0 var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter);
    margin-bottom: var(--contentpanels-radius);
  }
  .header-searchpanel-content {
    position: sticky;
    top: 26px;
    margin-top: 22px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 100%;
  }
  .header-searchpanel-closebutton {
    justify-self: end; /* at the right */
    align-self: center; /* vertical aligned */
    grid-row: 3;
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    color: #FFFFFF;
    position: sticky;
    z-index: 110;
    top: 47px;
  }
  /*
    .header-searchpanel-content
    {
      grid-row: var(--searchpanel-gridrow-content);
      // grid-column: var(--pagegrid-col-verywide);

      grid-column: var(--pagegrid-col-default);
    }

    .header-searchpanel-content
    {
      display: grid;
      align-content: center;
    }
  */
  /* Label "zoeken" naast de input */
}
@media (max-width: 949px) and (max-width: 1023px) {
  .header-searchpanel-content {
    grid-row: var(--searchpanel-gridrow-content);
    grid-column: var(--pagegrid-col-default);
  }
  .spc-search__heading {
    display: block;
    color: #FFFFFF;
    font: 21px var(--rtd-heading-fontfamily);
    padding-right: 10px;
    align-self: center;
  }
  .header-searchpanel-closebutton {
    margin-right: -6px;
  }
}
@media (max-width: 949px) and (min-width: 1024px) {
  /* Input aligned to page content */
  .header-searchpanel-content {
    grid-row: var(--searchpanel-gridrow-content);
    grid-column: var(--pagegrid-col-fullwidth);
  }
  .header-searchpanel-content .spc-searchbar.spc-searchbar {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .header-searchpanel-content .spc-search__heading.spc-search__heading {
    color: #FFFFFF;
    font: 32px var(--rtd-heading-fontfamily);
    grid-column: var(--pagegrid-gutter-left);
    text-align: right;
    padding-right: 30px;
    align-self: center;
  }
  .header-searchpanel-content .spc-searchbar__input {
    grid-column: var(--pagegrid-col-default);
    align-self: center;
  }
  .header-searchpanel-content .spc-searchbar .wh-autocomplete-container {
    grid-row: 2;
    grid-column: var(--pagegrid-col-default);
  }
  .header-searchpanel-closebutton {
    margin-right: 15px;
  }
}
@media (max-width: 949px) and (max-width: 500px) {
  .spc-search__heading {
    display: none;
  }
}
@media (max-width: 949px) {
  .spc-searchbar__searchbutton.spc-searchbar__searchbutton.spc-searchbar__searchbutton {
    width: 64px;
    margin-left: -64px;
    position: static;
    left: auto;
    right: auto;
  }
  .header-searchpanel-content {
    --header-searchbar-radius: 5px;
    /*
      .spc-search__heading
      {
        display: block;
        width: 100%;
        margin-bottom: 12px;

        font: var(--rtd-h3-font);
        color: var(--rtd-heading-color);
      }
    */
    padding-right: 45px;
  }
  .header-searchpanel-content .spc-searchbar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }
  .header-searchpanel-content .spc-searchbar__input.spc-searchbar__input {
    flex: 1 1 0;
    /* have input and button side-by-side */
    border-radius: var(--header-searchbar-radius);
    margin: 0; /* Safari has some default margins on <input> */
    padding-right: 55px;
  }
  .header-searchpanel-content .spc-searchbar__searchbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    flex: none;
    border-radius: 0;
    font-size: 0;
    height: var(--formcontrols-height);
    position: absolute;
    right: 0;
    /*
        margin-left: calc(-1 * 55px);

        width: 30px;
        padding-right: 25px;
    */
    color: var(--color-purple1);
    /*
        // @include wh-form-hidenativecontrol;
        position: absolute;
        height: 0;
        width: 0;
        min-height: 0;
        padding: 0;
        margin: 0;
        border: 0;
        overflow: hidden;
    */
  }
  .header-searchpanel-content .spc-searchbar__searchbutton::after {
    font: var(--fontawesome-regular);
    font-size: 20px;
    content: var(--fa-search);
  }
  .header-searchpanel-top {
    top: -17px;
    height: 62px;
  }
  .header-searchpanel-bottom {
    top: 12px;
    margin-top: 13px;
    height: 62px;
  }
  .header-searchpanel-content {
    display: flex;
    height: 100%;
    align-items: center;
    margin-top: 1px;
    top: 0px;
  }
  .header-searchpanel-closebutton {
    top: 18px;
  }
  .spc-searchbar__searchbutton.spc-searchbar__searchbutton.spc-searchbar__searchbutton {
    border-radius: 0 3px 3px 0; /* var(--formcontrols-border-width) var(--formcontrols-border-width) 0; */
  }
  html.searchpanel-active .header-searchpanel-closebutton,
  html.searchpanel-active .header-searchpanel-content {
    display: block;
  }
}
html {
  --pagegrid-rowsize-header-top: 0;
}

.page__metadata {
  grid-column: var(--pagegrid-col-default);
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: baseline;
}

/* !! be more specific than RTD spacing selectors such as ".widget + *" */
.page__metadata.page__metadata {
  margin-top: 80px;
}

.page__metadata > * {
  display: contents;
}

.page__metadata__label,
.sharecomponent__title {
  grid-column: 1;
  color: var(--component-sharepage-heading-color);
  font: var(--component-sharepage-heading-font);
  padding-right: 17px;
}

.page__metadata > hr {
  display: block;
  width: 100%;
  grid-column: 1/3;
  margin-top: 20px;
  border-top: 1px solid var(--color-theme);
  margin-bottom: 20px;
}

.page__metadata > hr:first-child {
  margin-top: 0;
}

.page__metadata > .sharecomponent {
  display: contents;
}

.page__metadata + .widget-pagehighlights {
  margin-top: 65px;
}

.page__metadata + .widget-pagehighlights + .page__balloon {
  --whitespace-footer-content: 50px; /* instead of the 80px */
}

.widget-button .wh-form__button,
.widget-cta .wh-form__button--cta {
  padding-right: 35px;
}

.widget-cta + .widget-ctabar {
  margin-top: 22px !important;
}

/* FIXME: how to nicely do this? remove the extra whitespace between header and crumble path */
.header-menubar.header-menubar.header-menubar {
  padding-bottom: 0;
}

.header-menubar__logo {
  /* width: 230px; */
  height: 65px;
}

.header-menubar-background {
  background-color: #FFFFFF;
  grid-column: var(--pagegrid-col-fullwidth);
}

.header-crumblepath {
  grid-column: var(--pagegrid-col-fullwidth);
  min-height: 55px;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-template-rows: 1fr min-content; /* crumblepath row and divider row */
  align-items: center;
}
@media print {
  .header-crumblepath {
    height: auto;
  }
}

.header-crumblepath__content {
  grid-column: var(--pagegrid-col-default);
  padding: 10px 0;
}

/* FIXME: Chrome, Edge, Safari 15.4 (mar 2022)... Firefox is working on it
   https://caniuse.com/css-has
*/
body:has(> header) .header-crumblepath__content {
  grid-column: var(--pagegrid-col-verywide-outsidegutter-text);
}

.header-crumblepath {
  border-bottom: 1px solid var(--color-purple3);
}

@media (max-width: 500px) {
  .header-crumblepath {
    --component-navpath-font: 10px/16px var(--rtd-heading-fontfamily);
    --component-navpath-seperator-font: 12px/16px var(--rtd-heading-fontfamily);
    min-height: 42px;
  }
}
.acoifilterpage-filters__filters__title {
  font: 500 18px/24px var(--rtd-heading-fontfamily);
}

.visually-hidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}