.widget-ctacarrousel__playbacktogglebutton
{
  width:  55px;
  height: 55px;
  color: #FFFFFF;

  margin-right: -10px;

  background: none;
  border: 0;

/*  background-color: rgba(255, 255, 255, 0.08);*/
  border-radius: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.1s;
}


.widget-ctacarrousel__playbacktogglebutton::before
{
  font: var(--fontawesome-light);
  font-size: 22px;
  content: var(--fa-play);
}

.spc-slideshow--playing .widget-ctacarrousel__playbacktogglebutton::before
{/*
  font: var(--fontawesome-light);
  font-size: 28px;
  content: var(--fa-pause);
  */

  font: 35px/55px Arial;
  content: "⏸";
  /* content: "\f28b"; --fa-pause-circle */
}



.widget-ctacarrousel__playbacktogglebutton:hover
{
  background-color: rgba(255, 255, 255, 0.15);
}


.widget-ctacarrousel__tabs
{
  display: flex;
  flex-wrap: wrap;
  column-gap: 28px;
}

.widget-ctacarrousel__tab
{
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;

  cursor: pointer;

  padding: 10px 2px; /* increase hitarea */
  margin: -10px -2px;

  flex: 1 0 0;
  max-width: 204px;

  border-radius: 5px;
}

  .widget-ctacarrousel__tab:hover .widget-ctacarrousel__tab__progress
, .widget-ctacarrousel__tab:focus .widget-ctacarrousel__tab__progress
{
/*  outline: 4px solid rgba(255,255,255,0.4);*/
/*  outline-offset: -5px;*/
  box-shadow: 0 0 8px #FFFFFF;
}

  .widget-ctacarrousel__tab:hover .widget-ctacarrousel__tab__progress::after
, .widget-ctacarrousel__tab:focus .widget-ctacarrousel__tab__progress::after
{
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;

  border-bottom: 12px solid #FFFFFF;
}



.widget-ctacarrousel__tab__progress
{
  position: relative;
/*  width: 100%;*/

  background: var(--component-jumpbutton-with-progress-background);
  border-radius: 3.5px;
  height: 7px;
}

.widget-ctacarrousel__tab__progress__bar
{
  background: #21BF84;
  border-radius: 3.5px 0 0 3.5px;

  width: 0%;
  height: 100%;
}

/* When not playing have the bar be fully green */
.widget-ctacarrousel__tab.active .widget-ctacarrousel__tab__progress__bar
{
  width: 100%;
}

/* When the carrouse is in play state animate the progress indicator using the
   time we expect the timeout to trigger a new slide becoming visible. */
.spc-slideshow--playing .widget-ctacarrousel__tab.active .widget-ctacarrousel__tab__progress__bar
{
  animation: progress linear var(--slideshow-slide-visibilityduration);
  height: height
  width: 0;
}



@keyframes progress
{
  from  { width:   0%; }
  to    { width: 100%; }
}
