html
{
  /* shared settings for accordion/FAQ and "Downloads & Links" */
  --largelisting-header-minheight:        60px;
  --largelisting-header-padding-h:        12px;
  --largelisting-header-padding-v:        10px;


  /* ADDME */
  --acoifilterpage-tabs-marginbottom: 0px;
  --acoifilterpage-feedbackbar-height: 65px;






  --tagpanel-heading-color:     var(--color-theme);
  --tagpanel-heading-font:      500 14px/22px var(--rtd-heading-fontfamily); /* semibold */
  --tagpanel-heading-margin:    5px;
  --tagpanel-option-pad-top:    4px;
  --tagpanel-option-pad-bottom: 4px;
  --tagpanel-option-spacing:    4px;
  --tagpanel-option-font:       14px/22px var(--rtd-heading-fontfamily);


  --filterpage-filtergroup-padleft:  18px;
  --filterpage-filtergroup-padright: 20px;
  --filterpage-filtergroup-padding:  18px 20px 30px 18px;
  --filterpage-filtergroup-spacing:  40px;


  /************************************************************
   *
   *  Page components / Accordion (FAQ) widget
   */

  --accordion-header-color:               #000000;
  --accordion-header-font:                var(--rtd-h3-font);
  --accordion-header-padding:             var(--largelisting-header-padding-v) 0;
  --accordion-header-padding-v:           var(--largelisting-header-padding-v);
  --accordion-header-minheight:           60px;

  /* 200 = lite, 400 = normal 700 = bold */
  --accordion-header-symbol-font:         300 22px 'Font Awesome 5 Pro';
  --accordion-header-symbol-size:         22px;
  --accordion-header-symbol-color:        var(--color-theme);
  --accordion-header-symbol-content:      var(--fa-plus);
  --accordion-header-symbol-open-color:   var(--color-black);
  --accordion-header-symbol-open-content: var(--fa-minus);
  --accordion-header-symbol-shift-y:      7px;
  --accordion-header-hover-background:    #F5F5F5;
  --accordion-item-line-color:            var(--common-item-divider-line-color);

  --accordion-item-indent:                 0px;
  --accordion-item-symbolgutterwidth:     33px;


  /************************************************************
   *
   *  Page components / Consent layer
   */

  --contentblocker-background:          var(--color-purple-overlay);

  --contentblocker-heading-color:         #FFFFFF;
  --contentblocker-heading-font:          var(--rtd-h2-font);

  --contentblocker-text-color:            #FFFFFF;
  --contentblocker-text-font:             var(--rtd-text-font);

  /************************************************************
   *
   *  Transparent button
   *  - previous/next buttons for carrousels
   */

  --common-transparentbutton-hover: #F5F5F5;




  /************************************************************
   *
   *  Page components / Summaries (such as Downlads & Links widget)
   */

  /*
  4TU
  --component-summary-padding: 20px 20px;
  --component-summary-title-font: 16px/24px var(--rtd-text-fontfamily);
  --component-summary-iconsize: 18px;
  --component-summary-icon-color: var(--color-theme);
  --component-linkslist-icon-area-width:  20px;
  */

  --component-linkslist-header-padding:    var(--largelisting-header-padding-v) var(--largelisting-header-padding-h);

/*  --component-linkslist-item-padding:      20px 20px;*/
  --component-linkslist-icon-size:         18px;
  --component-linkslist-icon-color:        var(--color-theme);
  --component-linkslist-icon-area-width:   32px;
  --component-linkslist-title-color:       var(--color-theme);
  --component-linkslist-title-font:        16px/28px var(--rtd-heading-fontfamily);
  --component-linkslist-filesize-color:    #767676;
  --component-linkslist-filesize-font:     14px/28px var(--rtd-heading-fontfamily);
/*  --component-linkslist-description-font:  var(--rtd-text-small-font);*/
  --component-linkslist-description-font:  17px/28px var(--rtd-text-fontfamily);
  --component-linkslist-description-color: #121212;
  --component-linkslist-source-color:      #595959;
  --component-linkslist-source-font:       var(--rtd-text-small-font);
  --component-linkslist-line-color:        var(--common-item-divider-line-color);





  /************************************************************
   *
   *  Page components / Autosuggest
   */

  --component-autosuggest-radius:     var(--contentpanels-radius);
  --component-autosuggest-padding:    13px 0 19px 0;
  --component-autosuggest-background: var(--contentpanels-background);
  --component-autosuggest-box-shadow: var(--contentpanels-box-shadow);

  --component-autosuggest-item-pad-h: var(--textcontrols_padleft); /* align options with the text input */
  --component-autosuggest-item-pad-v: 4px;
  --component-autosuggest-item-font:  inherit; /* 19px */
  --component-autosuggest-item-color: #000000;



  /************************************************************
   *
   *  Page components / Tooltip
   */

  --component-tooltip-bgcolor:      #FFFFFF;
  --component-tooltip-border:       1px solid #CCCCCC;
  --component-tooltip-borderradius: 8px;
  --component-tooltip-filter:       drop-shadow(0 2px 17px rgba(0,0,0,0.35));

  --component-tooltip-textcolor:    #000000;
  --component-tooltip-font:         var(--rtd-text-small-font);



  /************************************************************
   *
   *  Page components / Play button
   */

  --component-video-playbutton-background: linear-gradient(270deg, #5F0EAE 0%, #9255E2 100%);
  --component-video-playbutton-icon-color: #FFFFFF;
  --component-video-playbutton-size:       94px;
  --component-video-playbutton-icon-size:  30px;

  --widget-rovid-controlbar-text-font: 11px var(--rtd-text-fontfamily);


  /************************************************************
   *
   *  Page components / Pagination
   */

  --pagination-border: 1px solid var(--common-item-divider-line-color);

  --pagination-iconfont:                  var(--fontawesome-solid);

  --pagination-prevnext-border:           2px solid transparent;
  --pagination-prevnext-spacing:          10px;
  --pagination-prevnext-background-color: var(--color-theme); /* #ECE4EC; */
  --pagination-prevnext-icon-color:       #FFFFFF;

  --pagination-previous-icon-content:     var(--fa-arrow-left);
  --pagination-previous-icon-size:        16px;
  --pagination-next-icon-content:         var(--fa-arrow-right);
  --pagination-next-icon-size:            16px;
  --pagination-ellipsis-icon-content:     var(--fa-ellipsis-h); /* "…" */
  --pagination-ellipsis-icon-size:        26px;
  --pagination-ellipsis-icon-font:        var(--fontawesome-light);

  --pagination-prevnext-hover-background: #FFFFFF;
  --pagination-prevnext-hover-border:     2px solid var(--color-theme);
  --pagination-prevnext-hover-icon-color: var(--color-theme);

  --pagination-prevnext-disabled-background: #EEEEEE;
  --pagination-prevnext-disabled-border:  2px solid transparent;
/*  --pagination-prevnext-hover-icon-color: var(--color-theme);*/

  --pagination-font:                 20px/32px var(--rtd-text-fontfamily);
  --pagination-page-link-color:      var(--color-purple-dark);
  --pagination-page-current-color:   var(--color-purple-dark);
  --pagination-page-size:            54px;
  --pagination-page-radius:           7px;
  --pagination-page-spacing:          5px;

  --pagination-page-hover-background: var(--color-purple3);


  /************************************************************
   *
   *  Page components / Tabstrip
   */

  --component-tabstrip-background:   transparent;
  --component-tabstrip-height:       63px;
  --component-tabstrip-spacing:      25px;
  --component-tabstrip-font:         18px var(--rtd-heading-fontfamily);
  --component-tabstrip-item-selected-font: 500 18px var(--rtd-heading-fontfamily);

  --component-tabstrip-item-padleft:     0px;
  --component-tabstrip-item-padright:   17px;

  --component-tabstrip-item-color:      #767676;
  --component-tabstrip-item-background: transparent;
  --component-tabstrip-item-border:     none;

  --component-tabstrip-item-hover-color:          var(--color-theme);
  --component-tabstrip-item-hover-background:     transparent;
  --component-tabstrip-item-hover-border:         none;

  --component-tabstrip-item-selected-bordercolor: var(--color-theme);
  --component-tabstrip-item-selected-color:       var(--color-theme);

  --component-tabstrip-item-selected-underlining-color: var(--color-theme);

/*
Tabstrip styled as list of tags/boxes next to eachother (from TUE BOOST!):
  --component-tabstrip-background:      transparent;
  --component-tabstrip-height:          28px;
  --component-tabstrip-spacing:         20px;
  --component-tabstrip-font:            12px var(--rtd-heading-fontfamily);

  --component-tabstrip-item-padleft:    15px;
  --component-tabstrip-item-padright:   22px;

  --component-tabstrip-item-color:      #666666;
  --component-tabstrip-item-background: #FFFFFF;
  --component-tabstrip-item-border:     1px solid #C8C8C8;

  --component-tabstrip-item-hover-color:          #000000;
  --component-tabstrip-item-hover-background:     #FFF7F7;
  --component-tabstrip-item-hover-border:         1px solid var(--color-theme);

  --component-tabstrip-item-selected-color:       #FFFFFF;
  --component-tabstrip-item-selected-background:  var(--color-theme-darker);
  --component-tabstrip-item-selected-border:      none;
  --component-tabstrip-item-selected-underlining-color: var(--color-theme);
*/



  /************************************************************
   *
   *  Filteredoverview / Teaser bar
   */

  --filteredoverview-feedbackbar-color:     var(--color-theme);
  --filteredoverview-feedbackbar-font:      var(--rtd-text-font);


  /* Teaserbar in page */
  --filteredoverview-teaserbar-background:   linear-gradient(270deg, #5F0EAE 0%, #9255E2 100%);
  --filteredoverview-teaserbar-border:       0;
  --filteredoverview-teaserbar-borderradius: 0;
  --filteredoverview-teaserbar-color:        #FFFFFF;
  --filteredoverview-teaserbar-height:       64px;

  /************************************************************
   *
   *  Page components / Share bar
   */

  --component-sharepage-button-background: #FFFFFF;
  --component-sharepage-button-color:      var(--color-theme);
  --component-sharepage-button-border:     1px solid var(--color-theme);
  --component-sharepage-button-size:       38px;
  --component-sharepage-button-symbolsize: 16px;
  --component-sharepage-button-radius:     50%;
  --component-sharepage-button-spacing:    8px;


  --component-sharepage-button-hover-background: var(--color-theme);
  --component-sharepage-button-hover-color:      #FFFFFF;
  --component-sharepage-button-hover-border:     1px solid var(--color-theme);

  --component-sharepage-heading-color:      var(--color-theme);
  --component-sharepage-heading-font:       18px/24px var(--rtd-heading-fontfamily); /* like --rtd-text-font, but bold */
}

/*
.acoifilterpage-filters__filters
.financielewegwijzer-topbar__query
*/
.acoifilterpage-filters
{
  --component-autosuggest-radius:     var(--contentpanels-radius-small);
  --component-autosuggest-padding:    calc(var(--contentpanels-radius-small) + 11px) 0 4px 0;

  --component-autosuggest-item-pad-h: var(--textcontrols_padleft); /* align options with the text input */
  --component-autosuggest-item-pad-v: 3px;
  --component-autosuggest-item-font:  inherit; /* 19px */
  --component-autosuggest-item-color: #000000;
}

.acoifilterpage-filters .searchbar .wh-autocomplete-container
{
  top: calc(-1 * var(--contentpanels-radius-small) - 8px);
}
/*
.acoifilterpage-filters .searchbar__input
{
  position: relative;
  z-index: 12;
}
*/

.widget-accordion__item__header-icon
{
  order: 5;
}

.searchbar
{
  font-size: 16px;
}
.site-dialog
{
   /* Teaser bar in mobile popup */
   --filteredoverview-teaserbar-background:   linear-gradient(270deg, #5F0EAE 0%, #9255E2 100%);
   --filteredoverview-teaserbar-border:       1px solid var(--color-purple);
   --filteredoverview-teaserbar-height:       46px;
   --filteredoverview-teaserbar-borderradius: 23px;
   --filteredoverview-teaserbar-color:        #FFFFFF;
}


@media (max-width: 767px)
{
  html
  {
    --component-tabstrip-height:     48px;

    --filteredoverview-feedbackbar-font: 16px var(--rtd-text-fontfamily);
  }
}


@media (max-width: 550px)
{
  html
  {
    --component-sharepage-button-size: 38px;
    --component-sharepage-button-symbolsize: 17px;
  }
}


html
{
  /************************************************************
   *
   *  Page components / Consent bar (cookiebar)
   */

  --consentbar-background:   #F1EFED;
  --consentbar-padding:      40px 0 40px 0;

  --consentbar-heading-color: var(--color-theme);
  --consentbar-heading-font: bold 36px/28px Officina, Arial, Sans-Serif;

  --consentbar-text-color:   #666666;
  --consentbar-text-font:         17px/24px Helvetica;

  --consentbar-padding:      43px 0 46px 0;
  --consentbar-preferenceslink-font: 18px/24px Officina, Arial, Sans-Serif;



  /************************************************************
   *
   *  Page components / Consent settings widget
   */

  --consentsettings-option-background: transparent;
  --consentsettings-option-border:     2px solid var(--color-purple);

  --consentsettings-option-color:      var(--color-purple);
  --consentsettings-option-font:       var(--rtd-h2-font);



  /************************************************************
   *
   *  Page components / Navigation path (crumpath)
   */

  --component-navpath-link-color:        var(--color-purple2); /*var(--rtd-link-color);*/
  --component-navpath-color:             var(--color-purple2); /* var(--color-grey-text-small); */
  --component-navpath-font:              12px/16px var(--rtd-heading-fontfamily);
  --component-navpath-seperator-font:    14px/16px var(--rtd-heading-fontfamily);
/*  --component-navpath-seperator-content: "›";*/
  --component-navpath-seperator-content: "•";
}



html
{
  --component-focus-animation: componentfocus 0s forwards;
}



/************************************************************
 *
 *  Tags
 */


/*
Light & small(36):
- News page blocks
- News widget blocks
- Events widget blocks

Outlined & large(44):
- Newsoverview type selection
*/

html
{
  --component-tag-medium-height: 36px;
  --component-tag-large-height:  44px;


  --component-taglist-gap-h:    9px;
  --component-taglist-gap-v:    7px;


  /* Tags in tile on eventsoverview */
  --component-tag-background:   #FFFFFF;
  --component-tag-text-color:   #333333;
  --component-tag-border-color: var(--color-theme);
  --component-tag-font:         15px var(--rtd-text-fontfamily);
  --component-tag-height:       30px; /* var(--formcontrols-height); */
  --component-tag-radius:       20px;
  --component-tag-padding:      4px 16px 3px 16px;
}

/* FIXME */
@media (max-width: 800px)
{
  html
  {
    --component-tag-height:     20px;
    --component-tag-radius:     20px;
  }
}

.page__tagslist
{
  margin-top: 65px;

  --component-tag-background:   #EEEEEE;
  --component-tag-text-color:   #333333;
  --component-tag-border-color: transparent;
  --component-tag-font:         16px var(--rtd-text-fontfamily);
  --component-tag-height:       38px;
  --component-tag-radius:       19px;
  --component-tag-padding:      4px 19px;
}

.fwitem__tagslist
{
  --component-tag-background:   #E6E6E6;
  --component-tag-text-color:   #333333;
  --component-tag-border-color: transparent;
  --component-tag-font:         15px var(--rtd-text-fontfamily);
  --component-tag-height:       24px;
  --component-tag-radius:       20px;
  --component-tag-padding:      2px 15px;
}

/*  .fwinstrument__tagslist*/
  .fwinstrument__specsheet
, .page-backlink
{
  --component-tag-background:   #E6E6E6;
  --component-tag-text-color:   #333333;
  --component-tag-border-color: transparent;

  --component-tag-font:         17px var(--rtd-text-fontfamily);
  --component-tag-height:       30px;

  --component-tag-radius:       20px;
  --component-tag-padding:      2px 15px;
}

.page--financielewegwijzer-instrument .page-contentstart + *
{
  --component-tag-background:   var(--color-theme);
  --component-tag-text-color:   #FFFFFF;
  --component-tag-padding:      2px 20px;
}

/*
.page__contentarea--rtddoc .sharecomponent
{
  margin-top: 20px;
  border-top: 1px solid var(--color-purple2);
  padding-top: 20px;
}
*/


.spc-tag-category--eventtype
{
  --component-tag-background:   var(--color-theme);
  --component-tag-text-color:   #FFFFFF;
  --component-tag-border-color: transparent;
}

.spc-tile--isexternallink .spc-tag-category--eventtype::after
{
  /* NOTE: the same styling as ".widget-links li.isexternal::before" */
  font: var(--fontawesome-regular);
  content: var(--fa-arrow-right);
  transform: rotate(-45deg);
  font-size: 14px; /* 17px in links widget */

  margin-left: 10px;
  align-self: center;
}

