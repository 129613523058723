.widget-persons--3col
{
  --widget-persons-item-maxwidth: 288px;
}

.widget-persons--4col
{
  --widget-persons-item-maxwidth: 350px;
  grid-column: var(--pagegrid-col-verywide);
}

.widget-persons
{
  --persons-itemwidth-2col: calc((100% - 1 * var(--widget-persons-item-spacing)) / 2);
  --persons-itemwidth-3col: calc((100% - 2 * var(--widget-persons-item-spacing)) / 3);
  --persons-itemwidth-4col: calc((100% - 3 * var(--widget-persons-item-spacing)) / 4);
}




.widget-persons
{
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

  .widget-persons__title
, .widget-persons__items
{
  margin-left: 30px;
  margin-right: 30px;
}

.widget-persons > *:first-child
{
  margin-top: 30px;
}

.widget-persons__title
{
  grid-row: 1;
}

.widget-persons__items
{
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 2;

  margin-bottom: 30px;
}

.widget-persons::before
{
  content: "";
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 1 / span 2;
  background: var(--widget-persons-background);
/*  border-radius: var(--contentpanels-radius-small-viewport-edge);*/
  border-radius: var(--contentpanels-radius-small);
}

.widget-persons__popuppreviews
{
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 3;
}



/* Single column layout  */
@media (max-width: 599px)
{
  .widget-persons__items
  {
    --persons-itemwidth:  100%;
/*    --widget-persons-photo-width: 236px;*/
  }

  .widget-persons:not(.widget-persons--carrouselonmobile) .widget-persons__items
  {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
}




@media (min-width: 600px)
{
  .widget-persons__items
  {
    /* We need to be able to have rows with different
       amount of columns center on the page.
       We cannot do that using grid.
    */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    column-gap: var(--widget-persons-item-spacing);
    row-gap: 60px;
  }

  .widget-persons--3col
  {
    --persons-itemwidth: var(--persons-itemwidth-3col);
  }

  .widget-persons--4col
  {
    --persons-itemwidth: var(--persons-itemwidth-4col);
  }
}

@media (min-width: 600px) and (max-width: 700px)
{
    .widget-persons--3col
  , .widget-persons--4col
  {
    --persons-itemwidth:  var(--persons-itemwidth-2col);
/*    --widget-persons-photo-width: 236px;*/
  }
}

@media (min-width: 701px) and (max-width: 1100px)
{
  .widget-persons--4col { --persons-itemwidth: var(--persons-itemwidth-3col); }

  .widget-persons--4col[data-itemcount="4"]
  {
    /* directly fall back to a 2x2 grid... nicer than the first with with 3 and the second with only 1 item */
    --persons-itemwidth: var(--persons-itemwidth-2col);
/*    --widget-persons-photo-width: 236px;*/
  }
}



/* The items need to be the same width on each row, regardless of how
   many items there will be on that row.
   So we cannot use flex: 1 1 auto; min-width: 0;
   We need to calculate/specify the exact size which is needed to fill
   the width if the max amount of items per row is reached.
*/


.widget-persons__item
{
  border: 0; /* disabled <button> border */

  display: flex;
  flex-direction: column;
  align-items: stretch;

  text-decoration: none; /* because we might be an <a> element */

  cursor:  pointer;

  padding: var(--widget-persons-item-padding);
  width: var(--persons-itemwidth);

  background: var(--widget-persons-item-background);
  box-shadow: var(--widget-persons-item-box-shadow);
  border-radius: var(--widget-persons-item-border-radius);
}

.person__meta
{
  padding: var(--widget-persons-item-meta-padding);
}

  .person__photo
, .person__photoplaceholder
{
  width: 100%;
  max-width: calc(var(--widget-persons-photo-width-max) + var(--widget-persons-photo-border-width) * 2);

  aspect-ratio: 1 / 1;

  /* NOTE:
  - earlyer we used box-sizing: content-box; to work around Safari calculating the height wrong
    when the image had a border.
    However now (tested in iOS 16.3.1) it actually goes wrong when using content-box.. ??
  */

  align-self: center;

  border-radius: var(--widget-persons-photo-border-radius);
  border: var(--widget-persons-photo-border-width) solid #EEEEEE;
}

  .widget-persons__item:hover
, .widget-persons__item:focus
{
  background-color: #F5F5F5;
  border-radius: var(--contentpanels-radius);
}

  .widget-persons__item:hover .person__photo
, .widget-persons__item:hover .person__photoplaceholder
, .widget-persons__item:focus .person__photo
, .widget-persons__item:focus .person__photoplaceholder
{
  border-color: #BBBBBB;
}

  .widget-persons__item:hover .person__profilelink
{
  text-decoration: underline;
}



.person__photoplaceholder
{
  background-color: #AAAAAA;
}
