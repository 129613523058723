@charset "UTF-8";
/*

SPC native checkboxes 2022-07 (V3)

V3 is used by ACOI, SU
V2 was used in WS2021, AV-Toolsite, JZOJP

Notes on styling checkboxes directly:

- Set the appearance to none to make it a "normal" element which supported ::before and ::after
- Webkit browsers (en Opera) already allowed styling of checkboxes/radiobuttons in 2012
- Since Firefox 54 (juni 2017) also supports this
- Internet Explorer doesn't support this but isn't relevant anymore

Use of focus-visible:
- Chrome     (oct 2020)
- Firefox 85 (jan 2021)
- iOS 15.4   (mar 2022 ?)

Also see:
- https://gitlab.webhare.com/webharebv/accessibility/-/issues/7
  ("Afstappen van gebruik <label> als weergave voor checkbox/radiobuttons?"")

*/
.wh-styledinput,
.effecttool__root {
  /*
    @supports not (*:focus-visible)
    {
        input[type='radio']:focus
      , input[type='checkbox']:focus
      {
        outline: 0;
        box-shadow: var(--formcontrol-checkradio-focus-boxshadow);
        border-color: var(--formcontrol-checkradio-focus-bordercolor);
      }
    }
  */
  /* We directly add the symbol so we can do things like
     - also fainly show the checkmark on hover
     - have a fade in/out effect
     - we have content, making sure we have a baseline so we can align with context next to us
       (if we don't use a symbol, use content: '\200B'; (zero-width space)
  */
}
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox],
.effecttool__root input[type=radio],
.effecttool__root input[type=checkbox] {
  /*
  Make this not be an replaced element, so instead of a system component we'll
  be able to use an normal element with ::before/::after pseudo elements.
  */
  -webkit-appearance: none; /* Safari */
  -moz-appearance: none; /* Firefox <80 */
  appearance: none; /* Chrome 84 (jul 2020), FF 80 (aug 2020) */
  /*
  Usually the label is at the right side so we don't need margins
  around the checkbox.
  */
  /*
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
  */
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--formcontrol-checkradio-size);
  flex: none;
  height: var(--formcontrol-checkradio-size);
  vertical-align: baseline;
  cursor: pointer;
  border: var(--formcontrol-checkradio-borderwidth) solid var(--formcontrol-checkradio-bordercolor);
  background-color: #FFFFFF;
}
.wh-styledinput input[type=checkbox],
.effecttool__root input[type=checkbox] {
  /* optional tweaking the vertical alignment of the checkbox */
  position: relative;
  top: var(--formcontrol-check-shift);
  border-radius: var(--formcontrol-checkradio-borderradius);
}
.wh-styledinput input[type=checkbox]::after,
.effecttool__root input[type=checkbox]::after {
  color: transparent;
  font: var(--formcontrol-check-font);
  content: var(--formcontrol-check-content);
}
.wh-styledinput input[type=checkbox]:hover,
.effecttool__root input[type=checkbox]:hover {
  border-color: var(--formcontrol-checkradio-hover-bordercolor);
  box-shadow: var(--formcontrol-checkradio-hover-boxshadow);
}
.wh-styledinput input[type=checkbox]:hover::after,
.effecttool__root input[type=checkbox]:hover::after {
  color: var(--formcontrol-checkradio-hover-color);
}
.wh-styledinput input[type=checkbox]:checked,
.effecttool__root input[type=checkbox]:checked {
  background-color: var(--formcontrol-checkradio-checked-bgcolor);
  border-color: var(--formcontrol-checkradio-checked-bordercolor);
  color: var(--formcontrol-checkradio-checked-color);
}
.wh-styledinput input[type=checkbox]:checked::after,
.effecttool__root input[type=checkbox]:checked::after {
  color: var(--formcontrol-checkradio-checked-color);
}
.wh-styledinput input[type=checkbox][disabled],
.effecttool__root input[type=checkbox][disabled] {
  background-color: var(--formcontrol-check-disabled-bgcolor);
  border-color: var(--formcontrol-check-disabled-checked-bordercolor);
  border-width: var(--formcontrol-check-disabled-borderwidth);
  box-shadow: none;
  cursor: default;
}
.wh-styledinput input[type=checkbox][disabled]::after,
.effecttool__root input[type=checkbox][disabled]::after {
  color: var(--formcontrol-check-disabled-iconcolor);
}
.wh-styledinput input[type=checkbox][disabled]:checked,
.effecttool__root input[type=checkbox][disabled]:checked {
  background-color: var(--formcontrol-check-disabled-bgcolor);
}
.wh-styledinput input[type=checkbox][disabled]:checked::after,
.effecttool__root input[type=checkbox][disabled]:checked::after {
  color: var(--formcontrol-check-disabled-checked-iconcolor);
}
.wh-styledinput input[type=radio],
.effecttool__root input[type=radio] {
  /* optional tweaking the vertical alignment of the radiobutton */
  position: relative;
  top: var(--formcontrol-radio-shift);
  border-radius: 50%;
}
.wh-styledinput input[type=radio]::after,
.effecttool__root input[type=radio]::after {
  position: relative;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  /* The ::after is used to show a bullet when the radiobutton is selected. */
  content: "​"; /* use zero-width space, so we have a baseline to align to (this prevents vertical alignment wiggling when checking/unchecking) */
  width: var(--formcontrol-radio-innersize);
  height: var(--formcontrol-radio-innersize);
}
.wh-styledinput input[type=radio]:hover,
.effecttool__root input[type=radio]:hover {
  border-color: var(--formcontrol-checkradio-hover-bordercolor);
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.14);
}
.wh-styledinput input[type=radio]:hover::after,
.effecttool__root input[type=radio]:hover::after {
  background-color: var(--formcontrol-checkradio-hover-color);
}
.wh-styledinput input[type=radio]:checked,
.effecttool__root input[type=radio]:checked {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--formcontrol-checkradio-checked-bordercolor);
}
.wh-styledinput input[type=radio]:checked::after,
.effecttool__root input[type=radio]:checked::after {
  color: var(--formcontrol-checkradio-checked-color);
  border-radius: 50%;
  background-color: var(--formcontrol-radio-checked-color);
}
.wh-styledinput input[type=radio][disabled],
.effecttool__root input[type=radio][disabled] {
  background-color: var(--formcontrol-radio-disabled-bgcolor);
  border-color: var(--formcontrol-radio-disabled-bordercolor);
  border-width: var(--formcontrol-radio-disabled-borderwidth);
  box-shadow: none;
  cursor: default;
}
.wh-styledinput input[type=radio][disabled]::after,
.effecttool__root input[type=radio][disabled]::after {
  background-color: var(--formcontrol-radio-disabled-iconcolor);
}
.wh-styledinput input[type=radio][disabled]:checked::after,
.effecttool__root input[type=radio][disabled]:checked::after {
  background-color: var(--formcontrol-radio-disabled-checked-iconcolor);
}
.wh-styledinput input[type=radio]:focus,
.wh-styledinput input[type=checkbox]:focus,
.effecttool__root input[type=radio]:focus,
.effecttool__root input[type=checkbox]:focus {
  outline: 0;
}
.wh-styledinput input[type=radio]:focus-visible,
.wh-styledinput input[type=checkbox]:focus-visible,
.effecttool__root input[type=radio]:focus-visible,
.effecttool__root input[type=checkbox]:focus-visible {
  outline: 0;
  box-shadow: var(--formcontrol-checkradio-focus-boxshadow);
  border-color: var(--formcontrol-checkradio-focus-bordercolor);
}