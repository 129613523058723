html {
  --sidemainmenu-padding-bottom: ;
}

#slidemenu {
  padding-bottom: var(--sidemainmenu-padding-bottom);
  --sidebar-groups-spacing: 25px;
}

.sidebar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
  padding-top: var(--sidemainmenu-padding-top);
}

.sidebar__header__logo {
  height: var(--sidebar-logo-height);
}

.sidebar__header__close {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  color: #757575;
  margin-left: var(--sidemainmenu-padding-left);
  width: 44px;
  height: 44px;
  margin-right: -215x;
  border-radius: var(--transparenticonbuttons-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebar__header__close.sidebar__header__close:hover {
  background-color: var(--transparenticonbuttons-hover-background);
}

.sidebar__header__close::before {
  width: auto; /* override WIG <button> styling */
  height: auto; /* override WIG <button> styling */
  /*
    position: absolute;
    left: 0;
  */
  font: var(--fontawesome-light);
  content: var(--fa-times);
  font-size: var(--sidebar-closebutton-size);
  color: var(--sidebar-closebutton-color);
}

/*
.sidebar hr
{
  margin-top:    var(--sidebar-groups-spacing);
  margin-bottom: var(--sidebar-groups-spacing);

  margin-left:   calc( var(--sidemainmenu-padding-left) + var(--sidebar-content-indent) );

  border: 0;
  border-top: 1px solid #D7D7D7;
}

.sidebar .sidebar__header + hr
{
  margin-top: 0;
}
*/
.sidebar__relatedwebsites {
  margin-top: 35px;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__relatedwebsites a {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--sidebar-menu-level1-textcolor);
  font: var(--sidebar-menu-level1-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level1-padding);
}

.sidebar__relatedwebsites a::before {
  position: absolute;
  left: 0;
  font: var(--fontawesome-solid);
  content: var(--fa-external-link-alt);
  font-size: 13px;
}

.sidebar__relatedwebsites a.iscurrent::before {
  content: var(--fa-chevron-right);
}

.sidebar__relatedwebsites a:hover {
  text-decoration: underline;
}

.sidebar__secondarylinks {
  margin-left: calc(var(--sidemainmenu-padding-left) + var(--sidebar-content-indent));
  margin-right: var(--sidemainmenu-padding-right);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__copyright,
.sidebar__secondarylinks a {
  color: var(--sidebar-secondarylinks-color);
  font: var(--sidebar-secondarylinks-font);
  text-decoration: none;
  padding-top: 3px;
  padding-bottom: 3px;
}

.sidebar__secondarylinks a:hover {
  text-decoration: underline;
}

.sidebar__secondarylinks a + a {
  margin-top: 5px;
}

.sidebar__languages {
  margin-top: var(--sidebar-groups-spacing);
  margin-left: calc(var(--sidemainmenu-padding-left) + var(--sidebar-content-indent));
  margin-right: var(--sidemainmenu-padding-right);
  display: flex;
  align-items: center;
  color: var(--rtd-text-color);
  font: 16px var(--rtd-heading-fontfamily);
}

.sidebar__languages a,
.sidebar__languages span {
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.sidebar__languages a {
  color: var(--sidebar-menu-level1-textcolor);
}

.sidebar__languages span.selected {
  text-decoration: none;
}