/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
/* TUE BOOST
$pagegrid-columns:       12;
$pagegrid-columnwidth:   80px;
$pagegrid-gutter:        24px;
*/
/* 4TU
$pagegrid-columns:       12;
$pagegrid-columnwidth:   84px; // was 65px; // max width of a column
$pagegrid-gutter:        26px; // was 30px
*/
/* ACOI */
/*
$pagegrid-columns:       12;
$pagegrid-columnwidth:   80px;
$pagegrid-gutter:        24px;
*/
/* ZVI */
/*
$pagegrid-columns:       12;
$pagegrid-columnwidth:   65px;
$pagegrid-gutter:        30px;
*/
.page--knowledgebase-themes {
  --whitespace-footer-content: 0;
}
.page--knowledgebase-themes .page__metadata {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 !important;
  grid-template-columns: min-content min-content;
  justify-content: center;
}
.page--knowledgebase-themes .page__metadata hr {
  display: none;
}

.page--knowledgebase-themes h2.knowledgebase-heading-with-icon {
  grid-column: var(--pagegrid-col-verywide);
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}

.page--knowledgebase-themes h2.knowledgebase-heading-with-icon::after {
  font: var(--fontawesome-regular);
  font-size: 20px;
  content: var(--fa-chevron-down);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.page--knowledgebase-themes .sharecomponent {
  grid-column: var(--pagegrid-col-verywide);
  justify-content: center;
}

.knowledgebase-grid {
  display: grid;
  grid-column: var(--pagegrid-col-fullwidth);
  grid-template-columns: var(--pagegrid-template-columns);
}
.knowledgebase-grid .spc-tiles--tile {
  --component-spctile-hover-background: #FFFFFF;
  --gridimage-aspectratio: auto;
}
.knowledgebase-grid .spc-tile__meta {
  padding-right: 70px !important;
}
.knowledgebase-grid .spc-tile:hover {
  box-shadow: var(--formcontrols-focus-boxshadow);
}
.knowledgebase-grid .spc-tiles--tile .spc-tile__top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.knowledgebase-grid .spc-tiles--tile .spc-tile__image {
  position: static;
  object-fit: contain;
}
@media (min-width: 800px) {
  .knowledgebase-grid .spc-tile__top, .knowledgebase-grid .spc-tile__image {
    max-height: 196px;
  }
}
.knowledgebase-grid .knowledgebase-grid__epilog {
  /* style h2's within the text below the themes as h3 */
  --rtd-h2-font: var(--rtd-h3-font);
}

.knowledgebase-grid .folderwithfilters__items--gridview {
  --component-spctile-text-color: #333333;
  --component-spctile-text-font: 15px/20px var(--rtd-text-fontfamily);
}

.knowledgebase-grid__background {
  grid-row: 1/span 2;
  /* IMPORTANT: grid-layout.scss wil cancel the margin and use
                --pagegrid-col-fullwidth when viewport <= $pagegrid-maxwidth */
  grid-column: var(--pagegrid-col-verywide);
  margin-left: -30px;
  margin-right: -30px;
  background-color: var(--color-purple4);
  border-radius: var(--contentpanels-radius-small-viewport-edge);
}

@media (max-width: 1380px) {
  /* Don't use the negative margin tricks at the sides of the page
     when we are already at the edge. */
  .knowledgebase-grid__background.knowledgebase-grid__background {
    margin-left: 0;
    margin-right: 0;
    grid-column: var(--pagegrid-col-fullwidth);
  }
}
.knowledgebase-grid > .acoifilterpage__results {
  grid-row: 1;
  padding-top: 30px;
  padding-bottom: 30px;
}

.knowledgebase-grid__epilog {
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 2;
  padding-bottom: 50px;
  text-align: center;
}

.knowledgebase-grid .spc-tile::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: var(--fa-chevron-circle-right);
  font: var(--fontawesome-light);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 52px;
  border-radius: var(--component-spctile-radius) 0 var(--component-spctile-radius) 0;
  border: 1px solid transparent;
  background: linear-gradient(84.29deg, #5F0EAE 0%, #9255E2 100%);
}

.knowledgebase-grid .spc-tile:hover::after {
  background: var(--color-purple3);
  border: 1px solid var(--color-theme);
  color: var(--color-theme);
}

/*
.knowledgebase-grid .spc-tile
{
  --rtd-text-font: 15px/20px var(--rtd-text-fontfamily);
}
*/
.knowledgebase-grid .spc-tile:hover .spc-tile__title {
  text-decoration: underline;
}