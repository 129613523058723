.wh-video__innerframe__preview {
  display: grid;
  border-radius: var(--widget-imagewithcaption-caption-radius);
}

.wh-video__innerframe__preview picture,
.wh-video__playbutton {
  grid-column: 1;
  grid-row: 1;
}

.wh-video__playbutton {
  appearance: none;
  border: none;
  padding: 0;
  cursor: pointer;
  /* Center this button within the preview area */
  position: absolute; /* align-self only works in Safari/iOS when using absolute */
  align-self: center;
  justify-self: center;
  /*
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  */
  /* Center play icon within */
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--component-video-playbutton-size);
  height: var(--component-video-playbutton-size);
  background: var(--component-video-playbutton-background);
  color: var(--component-video-playbutton-icon-color);
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0);
  transform: scale(1);
  transition: background-color 0.2s, box-shadow 0.5s;
}

.wh-video__playbutton::before {
  content: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m20.702381 14.49167-20.702381-14.49167v28.98334z' fill='%23fff' fill-rule='evenodd' transform='translate(.172619 .50833)'/%3E%3C/svg%3E");
  height: 25px;
  width: auto;
  aspect-ratio: 2/3;
  margin-left: 3px;
  transition: transform 0.3s cubic-bezier(0.425, 1.525, 0.58, 1.355); /* a kind of easeOutBack */
}

.wh-video__playbutton:hover {
  background-color: var(--color-salmon-dark);
  box-shadow: 0 0 8px rgb(0, 0, 0);
}

.wh-video__playbutton:hover::before {
  opacity: 0.95;
  transform: scale(1.2);
}