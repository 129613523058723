.pageheader--wideimage ~ .page__body > .page__contentarea {
  --header-to-content-margin: var(--header-wideimage-margin-to-content);
}

.pageheader--wideimage {
  --pagegrid-col-header-content: var(--pagegrid-col-default);
}

/*
.pageheader--sidebyside
{
  --pagegrid-col-header-content: var(--pagegrid-col-verywide);
}
*/
.pageheader--wideimage {
  margin-top: var(--header-wideimage-margin-above);
  /* In this header type the image is the panel which spans the whole area.
     So we need to focus outline to be around this panel. */
}
.pageheader--wideimage:focus .pageheader__image {
  outline: var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);
}
.pageheader--wideimage .pageheader__text {
  color: var(--rtd-text-color);
}
.pageheader--wideimage .pageheader__when {
  font: var(--header-sidebyside-pagedate-font);
  margin-bottom: var(--header-sidebyside-pagedate-spacing);
}
.pageheader--wideimage .pageheader__when::before {
  font: var(--header-when-icon-font);
  content: var(--header-when-icon-content);
  margin-right: 10px;
}
.pageheader--wideimage .pageheader__title {
  font: var(--header-sidebyside-title-font);
}
.pageheader--wideimage .pageheader__source {
  font: var(--header-sidebyside-source-font);
}
@media screen {
  .pageheader--wideimage.pageheader {
    --rtd-heading-color: #FFFFFF;
    --rtd-text-color: var(--currenttheme-text-on-bg-color);
    --rtd-link-color: #FFFFFF;
  }
  .pageheader--wideimage.pageheader {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: 1fr min-content;
  }
  .pageheader--wideimage .pageheader__imagewrapper {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 1/span 2;
  }
  .pageheader--wideimage .pageheader__image {
    z-index: 0;
    border-radius: var(--contentpanels-radius-outsidegutter);
    display: block;
    width: 100%;
    height: var(--header-wideimage-height);
    object-fit: cover;
  }
  .pageheader--wideimage .pageheader__imagecredits {
    grid-column: var(--pagegrid-col-verywide-right);
    grid-row: 3;
    margin-top: 5px;
    justify-self: end; /* at the right */
    color: var(--common-caption-color);
    font: var(--pageheader-credits-font);
  }
  .pageheader--wideimage .pageheader__background {
    z-index: 1;
    border-radius: 0 0 var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter);
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 2;
    background-color: rgba(84, 10, 81, 0.8);
    mix-blend-mode: multiply;
  }
  .pageheader--wideimage .pageheader__text {
    z-index: 2;
    grid-column: var(--pagegrid-col-header-content);
    grid-row: 2;
    color: #FFFFFF;
    padding-top: var(--header-wideimage-label-padding-v);
    padding-bottom: var(--header-wideimage-label-padding-v);
  }
  .page--newsoverview .pageheader--wideimage .pageheader__text {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 500px) {
  .pageheader--wideimage + .page__body .page-contentstart + .effecttool__root {
    --header-wideimage-margin-to-content: calc( var(--header-content-overlap) * -1 );
    z-index: 4;
  }
}
@media print {
  .pageheader--wideimage.pageheader {
    background: transparent;
    --rtd-heading-color: #000000;
    --rtd-text-color: #000000;
    --rtd-link-color: #000000;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .pageheader--wideimage .pageheader__background {
    display: none;
  }
  .pageheader--wideimage .pageheader__text {
    grid-row: 1;
    grid-column: var(--pagegrid-col-verywide);
    margin-bottom: 0.1cm;
  }
  .pageheader--wideimage .pageheader__imagewrapper {
    grid-row: 2;
    grid-column: 1/-1;
  }
  .pageheader--wideimage .pageheader__image {
    width: 100%; /* abide to the given width */
    height: 3.5cm; /* the wide image header is less high */
    object-fit: cover;
  }
  .pageheader--wideimage .pageheader__imagecredits {
    grid-row: 3;
    text-align: right;
    color: var(--common-caption-color);
    font: var(--pageheader-credits-font);
    grid-column: var(--pagegrid-col-verywide);
  }
}