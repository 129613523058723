.widget-button--center
{
  text-align: center;
}

.widget-button--right
{
  text-align: right;
}

.testcolumns
{
  display: flex;
  flex-wrap: wrap;
}

.testcolumns__column
{
  flex: 0 1 auto;
  column-gap: 30px;
}
