.spc-menubar__pulldown > ul {
  position: relative;
  width: max-content;
  min-width: var(--menubar-pulldown-minwidth);
  max-width: var(--menubar-pulldown-maxwidth);
  padding: var(--menubar-pulldown-padding);
  background-color: var(--menubar-pulldown-bgcolor);
  border: var(--menubar-pulldown-border);
  border-radius: var(--menubar-pulldown-border-radius);
  box-shadow: var(--menubar-pulldown-box-shadow);
}

.spc-menubar__pulldown > ul::before {
  border-left-color: var(--menubar-pulldown-bgcolor);
}

.spc-menubar__pulldown .spc-menubar__item {
  /* have toggle button and title side-by-side */
  display: flex;
  align-items: center;
}

.spc-menubar__pulldown .spc-menubar__item__title {
  flex: 1 1 0px;
}

.spc-menubar__pulldown .spc-menubar__item__togglebutton {
  z-index: 100; /* FIXME: why do we need 99 or higher? */
  box-sizing: content-box;
}

.spc-menubar__item__togglebutton {
  color: var(--menubar-bar-toggler-color);
}

.spc-menubar__item__togglebutton:hover {
  color: var(--menubar-bar-toggler-hover-color);
  text-shadow: 0 0 2px var(--menubar-bar-toggler-hover-color);
}

.spc-menubar__level3,
.spc-menubar__level4 {
  height: 0;
  overflow: hidden;
  visibility: hidden;
  contain: paint;
  position: relative;
}

/*
Methods of keeping the width the same when expanding and collapsing submenu's:
- Ensure [hidden] is redefined to be
  - display: block; so it'll still be part of the reflow
  - have height: 0; and overflow: hidden; so it's both visually hidden and cannot be interacted with by mouse
  - visibility: hidden; - to prevent interaction by tab navigation
- we could also make submenu's a grid container (or have the item + it's subitems in a grid container)
  We can change the height of the subcontent in grid-template-rows between 1fr and 0px
  and set transition: grid-template-rows 1s;
*/
.spc-menubar__item--expand > .spc-menubar__level3,
.spc-menubar__item--expand > .spc-menubar__level4 {
  height: auto;
  overflow: visible; /* needs to be visible so :focus-visible outline is visible */
  visibility: visible;
  contain: none;
}

.spc-menubar__level3[hidden],
.spc-menubar__level4[hidden] {
  display: block;
}

a.spc-menubar__item__title:focus-visible {
  z-index: 0;
}

ul.spc-menubar__level2 > li > .spc-menubar__item .spc-menubar__item__title {
  color: var(--menubar-level2-color);
  font: var(--menubar-level2-font);
  text-decoration: none;
  padding-left: var(--menubar-level2-padding-left);
  padding-right: var(--menubar-level2-padding-right);
  padding-top: var(--menubar-level2-padding-top);
  padding-bottom: var(--menubar-level2-padding-bottom);
}

/*
ul.spc-menubar__level2 > li > .spc-menubar__item .spc-menubar__item__togglebutton
{
  color: var(--menubar-level2-color);
}
*/
ul.spc-menubar__level3 > li > .spc-menubar__item .spc-menubar__item__title {
  color: var(--menubar-level3-color);
  font: var(--menubar-level3-font);
  text-decoration: none;
  padding-left: var(--menubar-level3-padding-left);
  padding-right: var(--menubar-level3-padding-right);
  padding-top: var(--menubar-level3-padding-top);
  padding-bottom: var(--menubar-level3-padding-bottom);
}

/*
ul.spc-menubar__level3 > li > .spc-menubar__item .spc-menubar__item__togglebutton
{
  color: var(--menubar-level3-color);
}
*/
ul.spc-menubar__level4 > li > .spc-menubar__item .spc-menubar__item__title {
  color: var(--menubar-level4-color);
  font: var(--menubar-level4-font);
  text-decoration: none;
  padding-left: var(--menubar-level4-padding-left);
  padding-right: var(--menubar-level4-padding-right);
  padding-top: var(--menubar-level4-padding-top);
  padding-bottom: var(--menubar-level4-padding-bottom);
}

/*
ul.spc-menubar__level4 > li > .spc-menubar__item .spc-menubar__item__togglebutton
{
  color: var(--menubar-level4-color);
}
*/
ul.spc-menubar__level2 > li > .spc-menubar__item:hover,
ul.spc-menubar__level3 > li > .spc-menubar__item:hover,
ul.spc-menubar__level4 > li > .spc-menubar__item:hover {
  text-decoration: none;
  background-color: var(--menubar-pulldown-itemhover-bgcolor);
  color: var(--menubar-pulldown-itemhover-textcolor);
  text-decoration: var(--menubar-pulldown-itemhover-textdecoration);
}

ul.spc-menubar__level2 > li .spc-menubar__item__togglebutton::before {
  flex: none;
  font: var(--menubar-level2-toggle-font);
  content: var(--menubar-level2-expandicon);
}
ul.spc-menubar__level2 > li.spc-menubar__item--expand .spc-menubar__item__togglebutton::before {
  content: var(--menubar-level2-collapseicon);
  padding-left: 0;
}

ul.spc-menubar__level3 > li .spc-menubar__item__togglebutton::before {
  flex: none;
  font: var(--menubar-level3-toggle-font);
  content: var(--menubar-level3-expandicon);
}
ul.spc-menubar__level3 > li.spc-menubar__item--expand .spc-menubar__item__togglebutton::before {
  content: var(--menubar-level3-collapseicon);
  padding-left: 0;
}