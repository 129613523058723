/* JZOJP
html
{
  --feedbackbar-tag-background:    #FFFFFF;
  --feedbackbar-tag-font:          300 14px var(--rtd-text-fontfamily);
  --feedbackbar-tag-height:        29px;
  --feedbackbar-tag-border:        1px solid $feedbackbar-tag-outline-color;
  --feedbackbar-tag-borderradius:  16px;
  --feedbackbar-tag-text-color:    #8E8E8E;
}
*/
html {
  --feedbackbar-tag-background: #F3F3F3;
  --feedbackbar-tag-font: 15px var(--rtd-text-fontfamily);
  --feedbackbar-tag-height: 34px;
  --feedbackbar-tag-border: none;
  --feedbackbar-tag-borderradius: 3px;
  --feedbackbar-tag-text-color: #000000;
  --feedbackbar-tag-padding-h: 12px;
  --feedbackbar-tag-hover-background: #EBEBEB;
}

/* --filteredoverview-feedbackbar */
/*
.filteredoverview__feedbackandviewbar
{
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-default);

  color: var(--common-caption-color);
  padding-top: 4px;

  margin-bottom: 20px;
  font: 18px/22px var(--rtd-heading-fontfamily);
}

@media (max-width: 767px)
{
  .filteredoverview__feedbackandviewbar
  {
    font: 16px/19px var(--rtd-heading-fontfamily);
  }
}

.filteredoverview__feedback
{
  //font: bold 16px/24px var(--rtd-heading-fontfamily);
  color: #3F3F3F;

  margin-right: 15px;
}


.filteredoverview__feedbackandviewbar__content
{
  display: flex;
  flex-wrap: wrap; // if the tags don't fix on a single line, show the below the label
  align-items: baseline;
}
*/
.filteredoverview__filtertags {
  display: none;
}

.filteredoverview--filtersactive .filteredoverview__filtertags {
  display: block;
}

.filtertags__title {
  flex: none;
}

.filtertags__items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -7.5px;
  margin-bottom: -7.5px;
  margin-left: -15px;
}

.filtertags__item {
  display: inline-flex;
  align-items: center;
  min-height: var(--feedbackbar-tag-height);
  padding-top: 4px;
  padding-bottom: 4px;
  font: var(--feedbackbar-tag-font);
  background-color: var(--feedbackbar-tag-background);
  border: var(--feedbackbar-tag-border);
  border-radius: var(--feedbackbar-tag-borderradius);
  color: var(--feedbackbar-tag-text-color);
  padding-left: var(--feedbackbar-tag-padding-h);
  padding-right: var(--feedbackbar-tag-padding-h);
  margin-left: 15px;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  cursor: pointer;
  text-decoration: none;
  max-width: 100%;
}

.filtertags__item:hover {
  color: var(--color-theme);
  border-color: var(--color-theme);
  background: var(--feedbackbar-tag-hover-background);
}

.filtertags__item::after {
  margin-left: 16px;
  font: var(--fontawesome-light);
  content: var(--fa-times);
  font-size: 14px;
  color: #000000;
  margin-top: 4px;
}

.filtertags__item:hover::after {
  color: var(--color-theme);
}

.filtertags__item__remove {
  align-self: center;
  padding: 0 8px 0 8px;
  font-size: 9px;
}