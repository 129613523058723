/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* deeplinks and backlink are shown above the content
   in the single-column non-centered layout.
*/
@media (max-width: 1299px) {
  .page-backlink {
    grid-column: var(--pagegrid-col-verywide);
    width: auto;
    margin-left: auto;
    /*
        border: none !important;
        border-radius: 0 !important;
        padding: 5px !important;
        min-height: 0 !important;
    */
  }
}
@media (min-width: 1024px) {
  .page-backlink {
    grid-column: var(--pagegrid-gutter-left-full);
  }
}