/************************************************************
 *
 *  Widgets / Rovid
 */

html
{
  --widget-cta-subtitle-color: var(--rtd-heading-color);
  --widget-cta-subtitle-font:  var(--rtd-text-subtitle-font);

  /* 34px */
  --widget-cta-sidebyside-padding-top:           34px; /*var(--widget-generic-panel-padding-top);*/
  --widget-cta-sidebyside-padding-bottom:        34px; /*var(--widget-generic-panel-padding-bottom);*/
  --widget-cta-sidebyside-inside-padding-top:    0;
  --widget-cta-sidebyside-inside-padding-bottom: 0;

  --widget-cta-stacked-inside-padding-top:    11px;
  --widget-cta-stacked-inside-padding-bottom: 25px;

  --widget-cta-sidebyside-radius: var(--contentpanels-radius-medium);
  --widget-cta-image-radius:      var(--contentpanels-radius-medium);


  /* Persons widget */
  --widget-persons-background:          #F8F4F9;
  --widget-persons-photo-width-max:      100%; /* 300px; */
  --widget-persons-photo-border-width:    0px;
  --widget-persons-photo-border-radius:   8px;
  --widget-persons-item-spacing:         30px; /* 58px - the 20*2 padding */
  --widget-persons-item-background:     #FFFFFF;
  --widget-persons-item-padding:          0px;
  --widget-persons-item-meta-padding:    15px 15px 18px 15px;
  --widget-persons-item-box-shadow:      2px 2px 6px 0 rgba(0,0,0,0.16);
  --widget-persons-item-border-radius:   8px;



  --widget-rovid-video-border: 0px;

  --widget-rovid-controlbar-height:        36px;
  --widget-rovid-controlbar-slider-height:  5px;
  --widget-rovid-controlbar-slider-radius:  4px;
  --widget-rovid-controlbar-text-font:     15px/var(--widget-rovid-controlbar-height) var(--rtd-text-fontfamily);
  --widget-rovid-playprogress-knob-size:   15px;

  --widget-rovid-background:         #EEEEEE;
  --widget-rovid-radius:             9px;
}

  .widget-persons__item .person__quote
, .widget-persons__item .person__profilelink
{
  display: none;
}


.widget--rovid
{
  --accordion-header-minheight: 50px;
  --accordion-header-padding:   5px 0;
  --accordion-item-line-color: var(--color-theme);

  --accordion-header-color: var(--color-theme);
  --accordion-header-font:  16px/24px var(--rtd-text-font);

  --accordion-header-symbol-color:        var(--color-theme);
  --accordion-header-symbol-font:         var(--fontawesome-light);

  --accordion-header-symbol-size:         14px;
  --accordion-header-symbol-content:      var(--fa-chevron-down);
  --accordion-header-symbol-open-content: var(--fa-chevron-up);
  --accordion-header-symbol-shift-y:      6px;

  /*
  --accordion-header-symbol-size:         18px;
  --accordion-header-symbol-content:      var(--fa-angle-down);
  --accordion-header-symbol-open-content: var(--fa-angle-up);
  */

  --accordion-item-indent: 18px;
  --accordion-header-hover-background: #E1E1E1;
}

.widget-accordion__item__header-icon
{
  padding-right: 22px;
}

.w-details__item__header::before
{
  order: 5;
}

