.widget-downloadsandlinks h3
{
  margin-bottom: 10px;
}

  .widget-downloadsandlinks h3
, .widget-downloadsandlinks h3
{
  margin-top:   var(--rtd-spacing-paragraph);
}


ul.widgetdl__itemslist
{
  list-style: none;
  margin: 0;
  padding: 0;
}


.widget-dl-listicons .widgetdl__item
{
  display: flex;
  align-items: baseline;

  border-top: 1px solid var(--component-linkslist-line-color);
  padding: var(--component-linkslist-header-padding);

  position: relative;
}

.widget-dl-listicons .widgetdl__item.widgetdl__item:hover
{
  background-color: var(--accordion-header-hover-background);
}

  .widget-dl-listicons .widgetdl__item__icon
, .widget-dl-listicons .widgetdl__item__meta
{
  pointer-events: none;
}

.widget-dl-listicons .widgetdl__item__anchor
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: auto;
}

.widget-dl-listicons .widgetdl__item__anchor:focus
{
  animation: var(--component-focus-animation);
}


.widget-dl-listicons .widgetdl__item:last-child
{
  border-bottom: 1px solid var(--component-linkslist-line-color);
}

.widget-dl-listicons .widgetdl__item__icon
{
  flex: none;

  color: var(--component-linkslist-icon-color);
  font-size: var(--component-linkslist-icon-size);

  text-align: center;
  width: var(--component-linkslist-icon-area-width);
  margin-right: 10px;
}

.widgetdl__item__icon.fa-arrow-right.fa-arrow-right.fa-arrow-right
{
  transform: rotate(-45deg);
  font-size: 17px;
}

.widget-dl-listicons .widgetdl__item__icon::before
{
  vertical-align: middle;
}

.widget-dl-listicons .widgetdl__item__icon.fa-arrow-right
{
  font-size: 13px;
}

.widget-dl-listicons .widgetdl__item__icon.fa-external-link-alt
{
  font-size: 16px;
}

.widget-dl-listicons .widgetdl__item__meta
{
}

.widget-dl-listicons .widgetdl__item__title
{
  color: var(--component-linkslist-title-color);
  font:  var(--component-linkslist-title-font);
}

.widget-dl-listicons .widgetdl__item__filesize
{
  color: var(--component-linkslist-filesize-color);
  font:  var(--component-linkslist-filesize-font);
  margin-left: 10px;
  white-space: nowrap;
}

.widget-dl-listicons .widgetdl__item__description
{
  display: block; /* counteract <span> for oldskool HTML checkers */
  margin-top: 0px;

  /* in case of plaintext (using description in Publisher file) */
  color: var(--component-linkslist-description-color);
  font:  var(--component-linkslist-description-font);

  /* in case of richtext (using description setting within the downloads & links widget */
  --rtd-text-color: var(--component-linkslist-description-color);
  --rtd-text-font:  var(--component-linkslist-description-font);
}

.widgetdl__item__source
{
  display: block; /* counteract <span> for oldskool HTML checkers */
  margin-top: 3px;
  color: var(--component-linkslist-source-color);
  font:  var(--component-linkslist-source-font);
}
