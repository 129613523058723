.widget-ctablocks
{
  /*
  FIXME: we can use a smarter grid columns setup causing
  it to automatically change amount of columns based on available width.
  (so wide won't be needed)
  */

  --content-layout:             verywide;
  --content-squeeze-preference: needwide;

  grid-column: var(--pagegrid-col-fullwidth);
  z-index: var(--zindex-overlap-deeplinks);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-ctablocks__title
{
  grid-column: var(--pagegrid-col-verywide);
  text-align: center;
  margin-bottom: 15px;
}

/*
.widget-ctablocks > *:first-child
{
  margin-top:      var(--widget-generic-panel-padding-top);
}

.widget-ctablocks > *:last-child
{
  margin-bottom:   var(--widget-generic-panel-padding-bottom);
}

.widget-ctablocks--bg-grey
{
  --theme-name: "lightgrey";
}

.widget-ctablocks--bg-grey::before
{
  content: "";
  display: block;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);

  grid-row: 1 / 2;

  background-color: var(--color-lightgrey);
}

html.pagewidth--width-fullwidth .widget-ctablocks--bg-grey
{
  background-color: var(--color-lightgrey);
}
*/

.widget-ctablocks__items
{
  grid-column: var(--pagegrid-col-verywide);

  /* create space for the box-shadow of children */
  margin-left: -4px;
  margin-right: -4px;
  padding: 4px;
}

.widget-ctablocks__items__content
{
  display: grid;
  /* grid-template-columns: repeat( auto-fit, minmax(50px, 1fr) ); */
  grid-template-columns: var(--itemsgrid-template-columns);

  column-gap: 24px;
  row-gap: 33px;
}

@media (max-width: 518px)
{
  .widget-ctablocks__items__content
  {
    --itemsgrid-template-columns: 1fr;
  }
}




.widget-ctablocks__item
{
  text-decoration: none;

  background-color: var(--color-purple);
  box-shadow: var(--contentpanels-box-shadow);
  border-radius: var(--contentpanels-radius);

  padding: 17px 20px 35px 20px;

  color: #FFFFFF;

  transition: background-color 0.1s;
}

.widget-ctablocks__item:focus
{
  outline:        var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);
}

/* override ".rtdcontent a:hover" */
a.widget-ctablocks__item.widget-ctablocks__item
{
  text-decoration: none;
}

.widget-ctablocks__item:hover
{
  background-color: var(--color-purple-dark);
  box-shadow: var(--common-itemblocks-box-shadow-hover);
}

.widget-ctablocks__item-title
{
  font: var(--rtd-h2-scaling-font);
}

.widget-ctablocks__item-title::before
{
  float: left;
  content: "\200b\A\200b";
  white-space: pre;
  vertical-align: top;
}

.widget-ctablocks__item-description
{
  margin-top: 12px;
  font: var(--rtd-text-small-font);
}
