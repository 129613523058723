.widget-persons__navigation
{
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 var(--pagegrid-gutteroutside);
}

  .widget-persons__previous
, .widget-persons__next
{
  flex: none;

  /* reset <button> */
  border: 0;
  padding: 0;
  background: none;

  color: var(--color-theme);

  padding: 5px;

  cursor: pointer;
}

  .widget-persons__previous::before
, .widget-persons__next::before
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  border: 1px solid transparent;
  border-radius: 50%;
}

  .widget-persons__previous:disabled
, .widget-persons__next:disabled
{
  color: #CCCCCC;
}

  .widget-persons__previous:not(:disabled):hover::before
, .widget-persons__next:not(:disabled):hover::before
{
  background-color: var(--common-transparentbutton-hover);
  border: 1px solid #DDDDDD;
}

  .widget-persons__previous:not(:disabled):focus::before
, .widget-persons__next:not(:disabled):focus::before
{
  outline:        var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);
}

.widget-persons__previous::before
{
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-left);
  font-size: 14px;
}

.widget-persons__next::before
{
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  font-size: 14px;
}

.widget-persons__navigation
{
  margin-top: 20px;
}

.widget-persons__jumpbuttons
{
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.spc-jumpbutton
{
  border: 0;
  background: none;

  padding: 5px; /* enlarge hitarea of button while keeping the visual small and elegant */
  cursor: pointer;
}

.spc-jumpbutton::before
{
  display: block;
  content: "";
  width: 16px;
  height: 16px;
  background-color: #CCCCCC;
  border-radius: 50%;
}

.spc-jumpbutton:focus
{
  outline: 0;
}

.spc-jumpbutton:hover::before
{
  background-color: #888888;
}

.spc-jumpbutton:focus-within::before
{
  outline:        var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);
}



.spc-jumpbutton.spc-jumpbutton--selected::before
{
  background-color: var(--color-theme);
}

.widget-persons__jumpbuttons > * + *
{
  margin-left: 5px;
}


/* Tablet / desktop - no carrousel */
@media (min-width: 701px)
{
  .widget-persons__items
  {
/*
FIXE: disabled for ZVI
(ZVIFIX)
    margin-top: calc( var(--widget-persons-item-padding) * -1 );
    margin-bottom: calc( var(--widget-persons-item-padding) * -1 );
*/
  }

  .widget-persons__navigation
  {
    display: none;
  }
}


/* Mobile */
@media (max-width: 700px)
{
  .widget-persons--carrouselonmobile
  {
    grid-column: var(--pagegrid-col-fullwidth);
  }

  .widget-persons--carrouselonmobile .widget-persons__items
  {
    background-color: var(--contentpanels-color-grey);
    --common-caption-color: var(--contentpanels-color-grey-caption-color);
  }

  .widget-persons--carrouselonmobile .widget-persons__items
  {
    /*@include horizontalcarrousel;*/
    overflow-x: scroll;
    scroll-snap-type: x mandatory; /* proximity */
    -webkit-overflow-scrolling: touch; /* without this snappoints don't seem to work correctly on iOS */
    scroll-behavior: smooth;

    /*@include hide-scrollbars;*/
    scrollbar-width: none; /* Firefox 64+ */

    display: flex;
    flex-wrap: nowrap;
  }

  .widget-persons--carrouselonmobile .widget-persons__items::-webkit-scrollbar
  {
    height: 0 !important; /* Chrome, Safari, newer versions of Opera */
  }

  .widget-persons--carrouselonmobile .widget-persons__item
  {
    flex: 0 0 100%;
    scroll-snap-align: start;

    padding: 39px var(--pagegrid-gutteroutside) 25px;

    outline-offset: -2px;
  }

    .widget-persons--carrouselonmobile .person__photo
  , .widget-persons--carrouselonmobile .person__photoplaceholder
  {
    border-color: #FFFFFF;
  }
}
