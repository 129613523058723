/* Used to indicate the panel is a :target
   (we used a link directly targetting it with #panelid in the URL)
*/
@keyframes contentpanel_singlepulse {
  0% {
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.35);
  }
  50% {
    box-shadow: var(--formcontrol-checkradio-focus-boxshadow);
  }
  100% {
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.35);
  }
}
/* keyboard navigation focus */
@keyframes componentfocus {
  /*
    // outline: 1px solid var(--color-theme);
    // outline: -webkit-focus-ring-color auto 1px;
  */
  100% {
    outline: 0;
    box-shadow: var(--formcontrol-checkradio-focus-boxshadow);
    border-color: var(--formcontrol-checkradio-focus-bordercolor);
  }
}
@keyframes componentfocus-animated {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
/*
@keyframes componentfocus
{
  0% {
    // transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    // transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    // transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
*/