/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
* {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}

/* FIXME: quickhack to have the tabstrip in view when a tab hash is used */
html {
  scroll-padding-top: 200px;
}

.spc-pagination {
  margin-top: 60px;
}

.consentbar__poscontainer {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.consentbar__content {
  grid-column: var(--pagegrid-col-default);
}

.consentbar__acceptcookiesbutton {
  padding-top: 4px;
  font: bold 18px Officina, Arial, Sans-Serif;
  text-transform: uppercase;
}

.wh-authormode__dialog__modalbg {
  z-index: 1000;
}

.page__source {
  color: var(--common-caption-color);
  font: var(--rtd-text-font);
}

.page__source + .page__tagslist {
  margin-top: 15px;
}

/*
@media (max-width: $minwidth-centercontent - 1)
{
}
*/
#page-sharebuttons-heading {
  margin-bottom: 10px;
}

.page__headerfilters {
  grid-column: var(--pagegrid-col-fullwidth);
  background-color: var(--color-white);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.page__headerfilters__filterbar {
  grid-column: var(--pagegrid-col-verywide);
}

.pageheader-carrousel + .page__body > .page__headerfilters {
  padding-top: 60px; /* change to 80px when __filterbar starts to use row-gap instead of the padding hack */
}

.page__footer {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.page__footer__content {
  grid-column: var(--pagegrid-col-verywide);
  padding-bottom: 15px;
}

@media (max-width: 949px) {
  .page__footer {
    display: none;
  }
}
.widgetdl__item__icon.widgetdl__item__icon,
.widget-accordion__item__header::after {
  color: var(--color-theme);
}

.dompack--debug-import #import_original_url {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background: #ffff00;
  padding: 2px 6px 2px 2px;
  font-size: 10px;
  z-index: 999;
  border-radius: 0 0 15px 0;
  opacity: 0.5;
}
.dompack--debug-import #import_original_url:hover {
  opacity: 1;
}