@page {
  size: A4;
  size: 210mm 297mm;
  /* Chrome sets own margins, we change these printer settings */
  margin: 15mm 2mm 15mm 2mm;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  body {
    --header-sidebyside-margin-above: 0;
    --header-sidebyside-margin-to-content: 1cm;
    --header-wideimage-margin-above: 0;
    --header-wideimage-margin-to-content: 1cm;
    --header-imagestickingout-margin-above: 0;
    --header-imagestickingout-margin-to-content: 1cm;
    --header-home-margin-above: 0;
    --header-home-margin-to-content: 1cm;
    --pagegrid-col-narrow: var(--pagegrid-col-verywide);
    --pagegrid-col-default: var(--pagegrid-col-verywide);
    --whitespace-footer-content: 1cm;
  }
  .consentbar,
  #slidemenu-container,
  .skiplinksbutton__wrapper,
  .header-top,
  .header-top-background,
  .header-menubar,
  .header-menubar-background,
  .header-searchpanel-top,
  .header-searchpanel-bottom,
  .header-searchpanel-content,
  .deeplinks-wrapper,
  .sharecomponent,
  .footer,
  wh-outputtools {
    display: none !important;
  }
}