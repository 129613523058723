.filtersdialog {
  display: flex;
  flex-direction: column;
}
.filtersdialog .dialog__content {
  flex: 1 0 auto;
}

.site-dialog__footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
  margin-top: auto;
  padding-top: 30px;
  margin-bottom: 20px;
  grid-column: var(--pagegrid-col-verywide);
  pointer-events: none;
}

.filtersdialog__applybutton {
  pointer-events: auto;
}

.filtersdialog .tagcategory {
  grid-column: var(--pagegrid-col-verywide);
}

.site-dialog {
  --formcontrol-checkradio-size: 20px;
  --tagpanel-heading-font: 16px/30px var(--rtd-heading-fontfamily);
  --filterpage-filtergroup-padding: 0;
  /*
  --pagegrid-coldef-sidemargin:    10px;
  --pagegrid-coldef-gutteroutside: 10px;
  --pagegrid-gutter:               10px;
  --pagegrid-template-columns:     var(--pagegrid-coldef-sidemargin) var(--pagegrid-coldef-gutteroutside) repeat( calc(var(--pagegrid-columns) - 1) , minmax(0, 100fr) var(--pagegrid-gutter) ) minmax(0, 100fr) var(--pagegrid-coldef-gutteroutside) var(--pagegrid-coldef-sidemargin);
  */
}

.dialog__header {
  grid-column: var(--site-menubar-grid-column);
}

.dialog__header__logo {
  width: auto;
  height: var(--site-menubar-logo-height);
}

.site-dialog .filterpanel {
  margin-top: 30px;
  grid-column: var(--pagegrid-col-verywide);
}

.site-dialog .filterpanel--radiolist .tagcategory__title {
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.site-dialog .filterpanel--radiolist .tagcategory__title::after {
  padding-top: 5px;
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #CBCBCB;
}

/* When we move the full form.acoifilterpage-filters to the mobile filters popup
   we don't need to see the feedbackbar and tags. We already use the teaser bar.
*/
.site-dialog .filteredoverview__feedbackandviewbar {
  grid-row: 3;
}

/* we already add a reset button in the header */
.site-dialog .filteredoverview-resetbutton {
  display: none;
}

.site-dialog .acoifilterpage-filters__header .filteredoverview-resetbutton {
  display: block;
}