/*
NOTE:

Flex solution:
https://googlechrome.github.io/samples/css-flexbox-abspos/
- In modern browser we could also make the __preview flex
- Absolute positioned flex children will align/justify as if they are the only content within the flex container.
  (this way we could center the button)
*/
.widget-spcvideo {
  display: grid;
  background-size: cover;
}

.spcvideo__previewimage,
.spcvideo__container,
.wh-video__playbutton,
.spcvideo__requireconsent__coloroverlay,
.spcvideo__requireconsent__text,
.wh-video__innerframe {
  grid-column: 1;
  grid-row: 1;
}

.spcvideo__previewimage {
  width: 100%;
  height: auto;
  border-radius: var(--widget-imagewithcaption-caption-radius);
}

.spcvideo__caption {
  grid-column: 1;
  grid-row: 2;
  align-self: end;
  justify-self: start;
  /*
  display: flex; / * icon and text side-by-side * /
  align-items: baseline;
  */
  margin-top: 10px;
  color: var(--widget-imagewithcaption-caption-color);
  font: var(--widget-imagewithcaption-caption-font);
}

/*
.spcvideo__caption::before
{
  font: var(--fontawesome-brands);
  font-size: 16px;
  content: var(--fa-youtube);
  margin-right: 11px;
}
*/
.widget-video--active .wh-video__playbutton {
  display: none;
}

/*
.spcvideo__requireconsent__coloroverlay
{
  background-color: #428619;
  mix-blend-mode: darken;
  // background-color: rgba(66, 134, 25, 0.75);
}


.spcvideo__requireconsent__text
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #FFFFFF;

  font: 18px var(--rtd-text-fontfamily);
}

.spcvideo__requireconsent__text__heading
{
  color: #FFFFFF;
  font: var(--rtd-h2-font);
  margin-bottom: 8px;
}
.spcvideo__requireconsent__accept
{
  text-decoration: underline;
  cursor: pointer;
}

  .spcvideo__requireconsent__coloroverlay
, .spcvideo__requireconsent__text
{
  opacity: 1;
  transition: opacity 0.5s;
}

.spcvideo__requireconsent__text a
{
  color: #FFFFFF;
}
*/
.spcvideo__playbutton {
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  pointer-events: none;
}

html:not([data-wh-consent*=all]) .wh-video__playbutton {
  display: none;
}

[data-wh-consent*=all] .spcvideo__requireconsent__coloroverlay, [data-wh-consent*=all] .spcvideo__requireconsent__text {
  opacity: 0;
  pointer-events: none;
}
[data-wh-consent*=all] .spcvideo__playbutton {
  opacity: 1;
  pointer-events: auto;
}
[data-wh-consent*=all] .spcvideo__playbutton:hover {
  transform: scale(1.1);
}