/* ZVI RTD styling

   NOTE on using @import in CSS used by an Tollium RTD type:
   - When using url() the Webhare CSS will rewrite the relative path (to an /.publisher/sd/zvi/site/fonts/... path)
   - When not using url() Webhare will leave the URL alone (last verified using Webhare V5.7.0) causing the browser to look it up on /.tollium/ui URL
   - Webhare will embed CSS files linked using @import like the assetpack compiler does.
     This also means the CSS won't be rewritten to scope it so it only influences the RTD.
     So this means CSS importants can override the Tollium backend styling.
   - @mod-modulename/ paths are currently not supported by the RTD CSS rewriter
     (they only work in the assetpacks for the website - here they are seen as moduleresources which will be included in the assetpack instead of being kept as import statement)
*/


/* @mod-zvi/webdesigns/shared/web/ */
@import url("../../../shared/web/fontawesome.css"); /* CSS variables for used icons */
@import url("../../../shared/web/fonts/fontawesome/css/all.css");
@import url("../../../shared/web/fonts/poppins-v20-latin/poppins.css");
@import url("../../../shared/web/fonts/rosans/rosans.css");


/* Site specific theme colors */
html
{
  --answer-indent: 38px;

  --pagegrid-gutteroutside-min:   30px;
  --pagegrid-gutteroutside-max:  108px;
}


html
{
  --itemsgrid-template-columns: minmax(0,1fr) minmax(0, 1fr) minmax(0, 1fr);
}

/*@media (max-width: 768px)*/
@media (max-width: 1100px)
{
  html
  {
    --itemsgrid-template-columns: minmax(0,1fr) minmax(0, 1fr);
  }
}


html
{
  /* Reuseable common use colors ───────────────────────────────────────────────── */

  --common-item-divider-line-color:     #C5C5C5;
  --common-caption-color:               #767676;
  --common-caption-font:                var(--rtd-text-small-font);


  --listitem-border-color:              #CCCCCC; /* FIXME: shouldn't this be the same as --common-item-divider-line-color ? */
  --listitem-border:                    1px solid var(--listitem-border-color);


  --transparenticonbuttons-radius:           var(--contentpanels-radius);
  --transparenticonbuttons-hover-background: var(--common-linkblock-hover);


  /* Content blockers (consent)
  */
  --contentblocker-background:          #540A51D0;
  --contentblocker-text-color:          #FFFFFF;



  /* item panels
     These would be items of which there may be multiple next to each other.
     They need a more subtle shadow.

  --common-itemblocks-box-shadow:       2px 2px 4px 0 rgba(0,0,0,0.1);
  --common-itemblocks-box-shadow-hover: 2px 2px 4px 0 rgba(0,0,0,0.2);

  */
  --common-itemblocks-box-shadow:       0 2px 17px 0 rgba(0,0,0,0.35);
  --common-itemblocks-box-shadow-hover: 0 2px 17px 0 rgba(0,0,0,0.50);
  --common-itemblocks-padding:          30px 23px 22px 30px;


  /* Hover color for any link
     - Main menu dropdown item
     - Downloads & Links
     - FAQ accordion header

  */
  --common-linkblock-hover:             #F5F5F5;
}


html
{
  --common-itemblocks-padding:          25px 24px 24px 24px;

  --effecttool-question-error-font: 17px/22px var(--rtd-text-fontfamily);

  --widget-imagewithcaption-caption-radius:        var(--contentpanels-radius);
  --widget-imagewithcaption-caption-color:         #767676;
  --widget-imagewithcaption-caption-font:          var(--rtd-text-small-font);
  --widget-imagewithcaption-caption-margintoimage: 10px;

  --widget-infobutton-icon-font:    var(--fontawesome-light);
  --widget-infobutton-icon-content: var(--fa-info-circle);
  --widget-infobutton-icon-size:    32px;


  /* Widget headings */
  --widget-cta-heading-font: bold 44px/50px var(--rtd-heading-fontfamily);


  /*
  Item heading font is used by: ;
  - Downloads & Links thumb mode ("Zie ook") widget item title
  */
  /*--widget-item-heading-font:           24px/32px var(--rtd-heading-fontfamily);*/
  /*--widget-item-description-font:       var(--rtd-text-font); /*17px/26px var(--rtd-text-fontfamily);*/





  /* Quote widget */
  /*--rtd-quote-color:        var(--rtd-heading-color);*/
  /*--rtd-quote-photosize:    187px;*/
  --rtd-quote-photosize:    clamp(125px, 22vw, 187px);
  --rtd-quote-margin:       clamp(45px,   6.5vw, 75px);

  --widget-quote-quote-color:           var(--rtd-heading-color);
  --widget-quote-name-color:            var(--color-theme);
  --widget-quote-function-color:        var(--common-caption-color);
  --widget-quote-withimage-font:        var(--rtd-h2-font); /*bold 28px/32px var(--rtd-text-fontfamily);*/
  --widget-quote-withoutimage-font:     var(--rtd-h2-font); /*bold 32px/40px var(--rtd-text-fontfamily);*/


  --widget-quote-name-font:      var(--rtd-text-small-font);
  --widget-quote-function-font:  var(--rtd-text-small-font);


  /************************************************************
   *
   *  Page components / Tooltip
   */

  --component-tooltip-bgcolor:      #FFFFFF;
  --component-tooltip-border:       1px solid #CCCCCC;
  --component-tooltip-borderradius: 8px;
  --component-tooltip-filter:       drop-shadow(0 2px 17px rgba(0,0,0,0.35));

  --component-tooltip-textcolor:    #000000;
  --component-tooltip-font:         var(--rtd-text-small-font);


  /************************************************************
   *
   *  Page components / Play button
   */

  --component-video-playbutton-background: linear-gradient(270deg, #5F0EAE 0%, #9255E2 100%);;
  --component-video-playbutton-icon-color: #FFFFFF;
}


/* page layout */
html
{
  /* top level layout */
  --whitespace-footer-content:           100px;

  /* other common layouts */
  --grid-spacing:                         30px;
  --successive-summaries-spacing:         50px; /* summaries such as news per year */
}

@media (max-width: 550px) /* $minwidth-desktop-fontsizes - 1px)*/
{
  html
  {
    --contentpanels-radius:               5px;
    --contentpanels-radius-outsidegutter: 5px; /* this will be reset to 0 when outsidegutter touches the edge of the screen */

    --effecttool-question-error-font: 16px/22px var(--rtd-text-fontfamily);

    --grid-spacing: 20px;
  }

  .widget-news__morelink
  {
    --button-textbutton-font: bold 16px/22px var(--rtd-text-fontfamily); /* like --rtd-text-small, but bold */
  }
}


/* FIXME:  not all colors in this block are still in use? */
html
{
  --color-divider:         #EAEAEA; /* 979797 20% on #FFFFFF */
  --color-divider-on-grey: #E2DFDF; /* 979797 20% on #FAF6F4 */


  --color-error:           #EE0000; /* lightest accessible red, 4.53:1 contrast on white */

  --color-lightgrey:          #F2EFED; /* background color */

  --color-bggrey:  #FAF6F4; /* CTA / DU menubar / DU connect widget */

  --color-black-grey:  #3F3F3F;

  --color-red:                #D82F37;
  --color-red-hover:          #EE5050; /* not designed */
  --color-black:              #000000;
  --color-white:              #FFFFFF;

  /* NOTE: text colors may be overridden based on the background.
           (a grey may get darker so it has enough contrast on a grey background)
  */
  --text-grey-large:    #ACACAC;
  --text-grey-small:    #767676; /* 4.5:1 contrast on white */


  --color-error-control-border:     #cd202c;
  --color-error-control-background: #FFEAEA;

  --color-grey-border:     #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */
  --color-grey-text-small: #767677; /* WCAG 4.53:1 contrastratio */

  --pagetitle-inflow-color: var(--color-theme);


  /************************************************************
   *
   *  Very site specific page component settings
   */

  --itempanel-box-shadow: 1px 1px 9px 2px rgba(0,0,0,0.22);

  --pagetile-heading-color:       var(--rtd-text-color);
  --pagetile-heading-color-hover: var(--color-theme);
}


  p.normal     > a.isexternal::before
, ul.unordered > li > a.isexternal::before
, ol.ordered   > li > a.isexternal::before
, .widget-button .wh-form__button.isexternal .spc-button__icon::before
, .spc-textbutton.isexternal .spc-button__icon::before
{
  font: var(--fontawesome-regular);
  content: var(--fa-arrow-right);
  transform: rotate(-45deg);
  font-size: 15px;
}

  p.normal     > a.isexternal::before
, ul.unordered > li > a.isexternal::before
, ol.ordered   > li > a.isexternal::before
/*, .widget-button .wh-form__button.isexternal .spc-button__icon::before*/
, .spc-textbutton.isexternal .spc-button__icon::before
{
  display: inline-block;
  margin-right: 5px;
}

/*
.wh-form__button.isexternal
{
  position: relative;
}
.wh-form__button.isexternal::before
{
  content: "";
}
.wh-form__button.isexternal::after
{
  position: absolute;
  left: 15px;
  font: var(--fontawesome-regular);
  content: var(--fa-arrow-right);
  transform: rotate(-45deg);
}
*/



blockquote
{
  --rtd-spacing-paragraph: 40px;


  display: inline-block; /* block in rtd-editor (when nog in grid) the margin is also applied */
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

  text-align: center;

  position: relative;
  font: var(--rtd-h2-font);
  font-weight: 500;
  color: var(--rtd-text-color);
  text-wrap: balance;


  background-color: #F0F0F0;
  padding: 10px 20px;
}

blockquote::before
{
  content: "“";
  font-size: 43px;
/*  font-family: "Times New Roman";*/

  position: absolute;
  left: -6px;
  top: -6px;
}

blockquote::after
{
  content: "”";
  font-size: 43px;
/*  font-family: "Times New Roman";*/
  line-height: 0;

  position: absolute;
  right: -8px;
  bottom: -8px;
}

blockquote + *
{
  --rtd-spacing-paragraph: 40px;
}

/*
The normal/high tags are used in: ;

- .deeplinks-wrapper
- html.page--newsoverview .newsoverview-filterbar
*/

.deeplinks-wrapper /* on page--newsitem */
{
  --component-tag-padding: 4px 15px; /* zodat "ACOI in de media{ net past in de gutter zonder wrapping */
}



/* For these we want buttons to be larger */
.widget-cta__button /* CTA widget */
{
  --formcontrols-height:  50px;
}


html
{
  /*
  --component-summary-when-color:        var(--common-caption-color);
  --component-summary-when-font:         15px/24px var(--rtd-heading-fontfamily);
  */
  --component-summary-when-color:        var(--color-theme);
  --component-summary-when-font:         17px var(--rtd-heading-fontfamily);
  --component-summary-title-color:       var(--rtd-heading-color);
  --component-summary-title-font:        bold 24px/32px var(--rtd-heading-fontfamily);
  --component-summary-description-color: var(--color-text-grey);
  /*--component-summary-description-font:  var(--widget-item-description-font);*/
  --component-summary-description-font:  var(--rtd-text-subtle-font);
  --component-summary-padding-v:         22px;

  /*, .page--newsoverview .searchresults*/
  /*, .page--eventsoverview .searchresults*/
  --component-summary-columns:          var(--pagegrid-col-verywide);

  --component-summary-url-font:         16px var(--rtd-text-fontfamily);

}

  /*html.page--search*/
html.page--eventsoverview
{
  --component-summary-title-color:      var(--color-theme);
  --component-summary-title-font:       bold 22px/25px var(--rtd-heading-fontfamily);
  /*--component-summary-description-font:      15px/22px var(--rtd-text-fontfamily);*/
  --component-summary-padding-v:        15px;
}

html.page--eventsoverview
{
  /*--common-caption-color:               #767676;*/
  /*--common-caption-font:                var(--rtd-text-small-font);*/
  --component-summary-padding-v:         30px;
  --component-summary-description-color: var(--common-caption-color);
  --component-summary-description-font:  var(--common-caption-font);
}

@media (max-width: 550px) /* $minwidth-desktop-fontsizes - 1px)*/
{
    html.page--search
  , html.page--eventsoverview
  {
    --component-summary-when-font:        16px var(--rtd-heading-fontfamily);
    --component-summary-title-font:       bold 19px/22px var(--rtd-heading-fontfamily);
    --component-summary-description-font: 15px/19px var(--rtd-text-fontfamily);
  }
  .searchresult__description { color: #5F5F5F; }
}



/* Widget settings */


/*/////////////////////////////////////////////////////////////////////////////////////////////
//
// Whitespace
*/


html
{
  /** Default whitespace between header and content
      In some cases (heading image followed by content with fullwidth color background)
      this margin will be removed.
  */
  --whitespace-header-content:  40px;
  --whitespace-header-backlink: 30px;
  --whitespace-footer-content: 80px;
  --grid_spacing:              30px;
  /*--rtdcontent_vspace: 15px;*/
  /*--rtdcontent_vspace_heading: 30px;*/


  --widget-panel-divider-color:  #DDDDDD; /* 979797 33% */


  --widget-generic-largetitle-margin: 50px; /* whitespace below the H1-styled title in widgets */

  /* White at the top and bottom of panels with an background color */
  --widget-generic-panel-padding-top:        50px;
  --widget-generic-panel-padding-bottom:     50px;
  --widget-generic-bigpanel-padding-top:     50px;
  --widget-generic-bigpanel-padding-bottom:  70px;

  --rtd-margin-greywithpadding-connected:   -20px; /* FIXME: needs to depend on whether the small or large padding size was used */
}


@media (max-width: 550px) /* $minwidth-desktop-fontsizes - 1px)*/
{
  html
  {
    --widget-generic-largetitle-margin:        20px;

    --widget-generic-panel-padding-top:        40px;
    --widget-generic-panel-padding-bottom:     40px;
    --widget-generic-bigpanel-padding-top:     75px;
    --widget-generic-bigpanel-padding-bottom:  75px;

    --rtd-margin-greywithpadding-connected:   -35px; /* FIXME: needs to depend on whether the small or large padding size was used */


    --whitespace-header-content: 40px;
    --whitespace-footer-content: 40px;


    --widget-item-heading-font:          18px/24px var(--rtd-heading-fontfamily);
    --widget-item-description-font:      16px/24px var(--rtd-text-fontfamily);

    --widget-quote-withimage-font:        bold 24px/30px var(--rtd-text-fontfamily);
    --widget-quote-withoutimage-font:     bold 28px/35px var(--rtd-text-fontfamily);


    --widget-generic-largetitle-margin:        20px;

    --widget-generic-panel-padding-top:        40px;
    --widget-generic-panel-padding-bottom:     40px;
    --widget-generic-bigpanel-padding-top:     50px;
    --widget-generic-bigpanel-padding-bottom:  50px;

    --rtd-margin-greywithpadding-connected:   -50px; /* FIXME: needs to depend on whether the small or large padding size was used */
  }
}

body
{
  --icon-link-font:    400 14px 'Font Awesome 5 Pro';
  --icon-link-content: var(--fa-arrow-right);
  --icon-backlink-content:  var(--fa-arrow-left);

  --icon-look-down: var(--fa-arrow-down);

}

.colortheme--grey
{
  --theme-name: "lightgrey";
  --theme-ispale: no;

  --theme-background: var(--color-bggrey);

  --color-divider: var(--color-divider-on-grey);
  /*--theme-textcolor:  var(--rtd-text-color);*/
}

.colortheme--grey
{
  --text-grey-small:    #3F3F3F;
}


/* Prevent long texts without a nice break point (usually an URL)
   from overflowing the container by forcing them to wrap.
*/
  .wh-rtd
, .page__contentarea--rtddoc
{
  overflow-wrap: break-word;
}



a
{
  outline-offset: 2px; /* nicer, especially when an image is part of a link */
}

p.normal a > img
{
  /* Make an image wrapped in an anchor not have whitespace below it.
     vertical-align values which can be used for this are: bottom, middle, top, text-bottom, text-top
  */
  vertical-align: middle;
}

/*
Notes on apple-data-detectors:
- Must use !important to override properties
- Can also create a anchor <a> around phone numbers: <a href="tel:" />
- The links are automatically added in the Safari app, but a webview within another app may not show them

a[x-apple-data-detectors]
{
  color: #E1670B !important;
}
*/

html
{
  -webkit-text-size-adjust: 100%;

  overflow-y: scroll;

  color: var(--rtd-text-color);
  font-family: var(--rtd-text-fontfamily);
}

  html
, .wh-rtd-embeddedobject /* otherwise Webhare will override our html font-size */
{
  font: var(--rtd-text-font);
}

body
{
  background: #FFFFFF; /* required for WCAG compliance */
}

h1
{
  color: var(--rtd-heading-color);
  font: var(--rtd-h1-font);
  margin: 0;
  text-wrap: balance; /* Chrome114+ (may 2023) */
  text-wrap: pretty;
}

  h2
, .widget__title
{
  color: var(--rtd-heading-color);
  font: var(--rtd-h2-font);
  margin: 0;
  text-wrap: balance; /* Chrome114+ (may 2023) */
  text-wrap: pretty;
}

/* special option for Design United to have H2's with H1 styling */
h2.heading2large
{
  font-size: 48px;
  line-height: 50px;
}

  h3
, .wh-form__grouptitle
{
  color: var(--rtd-heading-color);
  font: var(--rtd-h3-font);
  margin: 0;
  text-wrap: balance; /* Chrome114+ (may 2023) */
  text-wrap: pretty;
}

h4
{
  color: var(--rtd-heading-color);
  font: var(--rtd-h4-font);
  margin: 0;
  text-wrap: balance; /* Chrome114+ (may 2023) */
  text-wrap: pretty;
}

  p.normal
, ol.ordered
, ul.unordered
{
  font:  var(--rtd-text-font);
  color: var(--rtd-text-color);
}

p.intro
{
  font:  var(--rtd-intro-font);
  color: var(--rtd-intro-color);
}

p.small
{
  font:  var(--rtd-text-small-font);
  color: var(--rtd-text-color);
}

p.smallfaded
{
  font:  var(--rtd-text-small-font);
  color: var(--rtd-text-faded-color);
}

/* Placeholder text for .wh-rtd */
.wh-form__rtd--empty .wh-rtd__body::before
{
  /*color: $textcontrols_placeholdercolor;*/
  color: #999999;
}





/* Widgets with their own color background get a larger widget__title */
.widget__subtitle
{
  font: 15px/24px var(--rtd-heading-fontfamily);
}

  .widget-accordion__title
, .widget-downloadsandlinks .widget__title
{
  margin-bottom: 15px;
}




  /*.rtdcontent a   <-- this is too generic and interferes with styling of widgets */
  p.intro a
, p.description a
, p.normal a
, p.small a
, p.smallfaded a
, ul.unordered a
, ol.ordered a
, .wh-form__richtext a
{
  color: var(--rtd-link-color);
  font-weight: var(--rtd-link-font-weight);

  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
}

  p.intro a:hover
, p.description a:hover
, p.normal a:hover
, p.smallfaded a:hover
, p.small a:hover
, ul.unordered a:hover
, ol.ordered a:hover
{
  color: var(--rtd-link-color-hover);
}



sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}



.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}



/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}

ul.unordered
{
  padding-left: 20px;
}

ol.ordered
{
  padding-left: 19px;
}

ul.unordered li
{
  padding-left: 5px;
}

ol.ordered li
{
  padding-left: 5px;
}

ul li::marker
{
  color: var(--rtd-list-ul-marker-color);
  font-size: 18px;
}

ol li::marker
{
  color: var(--rtd-list-ol-marker-color);
  font-size:   15px;
  font-weight: bold;
}



/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}

.wh-rtd__table
{
  /* we MUST set the variable here because the RTD backend doesn't use the .wh-rtd__tablewrap */
  --rtd-text-font:  var(--rtd-table-font);

  /* NOTE: don't use table-layout: auto;
           although this would make max-width: 100%; work, this
           causes the widths of columns to change while typing in a column.
  */
}

.wh-rtd--structured .wh-rtd__table
{
}

.wh-rtd__table > caption
{
  text-align: left;
  color: var(--common-caption-color);
  font: var(--common-caption-font);
  margin-bottom: 7px;
}

.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 18px;

  font: var(--rtd-table-font);

  border: 1px solid #989898;
}
.wh-rtd__table th
{
  background-color: var(--color-purple);
  font: var(--rtd-table-heading-font);
  --rtd-text-color: #FFFFFF;
  min-height: 44px;
}

.wh-rtd__tablecell > *:first-child
{
  margin-top: 0;
}

/*
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
*/

  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}





  .wh-video
, p.intro
, p.description
, p.normal
, p.small
, p.smallfaded
, ul.unordered
, ol.ordered
, .wh-rtd .wh-rtd__table
, .wh-rtd__tablewrap /* wrapper used in HTML output */
, .widget
, blockquote
{
  margin-top:    var(--rtd-spacing-paragraph);
  margin-bottom: 0;
}

p + .widget-richcontent
{
  margin-top: 35px; /* slightly more than between two normal paragraphs */
}

p.intro + *
{
  margin-top: var(--rtd-spacing-intro-after);
}
p.intro + p.intro
{
  margin-top: var(--rtd-spacing-paragraph);
}


/* inflow widgets
   (FIXME: bedenk hoe vanuit CSS dit bij een widget te specificeren (zodat deze status afhaneklijk van de layout is)
*/
.widget-links
{
  --rtd-spacing-paragraph: 15px;
}

.widget + *
{
  margin-top: var(--rtd-spacing-widget-after);
}

.widget.colortheme--shadowpanel + *
{
  margin-top: calc( var(--rtd-spacing-widget-after) * 2 );
}

  h2.heading2
/*, .widget--withtitle*/
{
  margin-top: 38px;
}

.widget--withtitle
{
  margin-top: var(--rtd-spacing-widget-with-title-before);
}

.widget--includespadding
{

}


.widget-quote--withoutimage
{
  margin-top:    45px;
  margin-bottom: 45px;
}



h3.heading3
{
  margin-top: 30px;
}

h4.heading4
{
  margin-top: 30px;
}




  h2.heading2 + .wh-video
, h2.heading2 + p.intro
, h2.heading2 + p.description
, h2.heading2 + p.normal
, h2.heading2 + ul.unordered
, h2.heading2 + ol.ordered
{
  margin-top: var(--rtd-spacing-h2-after);
}

h2.heading2 + .widget--podcast
{
  margin-top: 10px; /* 15px if we had leading-trim */
}


/*
h3.heading3 + *
{
  --rtd-spacing-paragraph: 5px;
}
*/

  h3.heading3 + .wh-video
, h3.heading3 + p.intro
, h3.heading3 + p.description
, h3.heading3 + p.normal
, h3.heading3 + ul.unordered
, h3.heading3 + ol.ordered
{
  margin-top: var(--rtd-spacing-h3-after);
}

  h4.heading4 + .wh-video
, h4.heading4 + p.intro
, h4.heading4 + p.description
, h4.heading4 + p.normal
, h4.heading4 + ul.unordered
, h4.heading4 + ol.ordered
{
  margin-top: var(--rtd-spacing-h4-after);
}



/*
- Accordion
- Downloads & Links

*/
.widget--opaque.widget--opaque
{
  margin-top: var(--rtd-spacing-widget-opaque-before);
}

/* FIXME */
.widget--opaque.widget--opaque + *
{
  margin-top: var(--rtd-spacing-widget-opaque-after);
}

.widget--opaque.widget--opaque + .widget--withtitle
{
  margin-top: max( var(--rtd-spacing-widget-opaque-after)
                 , var(--rtd-spacing-widget-with-title-before)
                 );
}


/* If you place a header direct above something you probably want to stick to it...
   (although a little more room if the widget also has a background color would be nice)
*/
h2 + .widget--opaque.widget--opaque
{
  margin-top: var(--rtd-spacing-h2-after);
}




/* A widget which has distinct edges
   (either lines or it has it's own box/full background)
   requires some more margin so content below it doesn't
   feel cramped.
*/
/*
  .widget--opaque.widget--opaque + *:not(.widget--divider)
, .widget--opaque.widget--opaque + .divider--inactive + *:not(.widget--includespadding) / * with inactive divider inbetween * /
{
  margin-top: var(--widget-generic-panel-padding-bottom);
}

  .widget--includespadding + .widget--includespadding
, .widget--includespadding + .divider--inactive + .widget--includespadding
{
  margin-top: 0;
}
*/



  .divider--white + *
, .divider--grey + *
{
  margin-top: 0;
}



/*
FIXME: for this to work best we either need:

1. the first widget to already know it's next sibling
   so it can also remove it's border-radius at the bottom.

2. Merge the CTA into one

.widget--includespadding.colortheme--purple + .widget--includespadding.colortheme--purple
{
  margin-top: 0;
  --rtd-spacing-widget-opaque-before: 0;

  --widget-cta-panel-radius: 0 0 var(--widget-cta-panel-radius) var(--widget-cta-panel-radius);
  --widget-cta-panel-padding-top: 0;
}
*/



/* Connect grey backgrounds together
   (and deduplicate the grey padding)
*/
.widget--includespadding.colortheme--grey + .widget--includespadding.colortheme--grey
{
  margin-top: var(--rtd-margin-greywithpadding-connected);
  --widget-generic-panel-padding-top: 0px;
  --widget-generic-bigpanel-padding-top: 0px;
}



/*
widget-colorblock + widget-colorblock -> single white line
white + white -> single grey line
*/
.widget--includespadding + .widget--divider
{
  margin-top: 0;
}




.page-contentstart
{
  display: none;
}

.page__contentarea--iscustom
{
  margin-top: var(--whitespace-header-content);
}

.page-readtime
{
  margin-top: var(--whitespace-header-content);
}



/* any RTD content (descriptions, column content) */
  .wh-rtd > *:first-child
, .rtdcontent > *:first-child
, .wh-form__richtext > *:first-child
{
  margin-top: 0;
}

  .wh-rtd > *:last-child
, .rtdcontent > *:last-child
, .wh-form__richtext > *:last-child
{
  margin-bottom: 0;
}


/* However... cancel the margin (connect the element to the header)
   if the first item has it's own (background-color) panel with padding

   NOTE: uitgeschakeld voor ZVI omdat we afgeronde hoeken gebruiken en niet contentvlakken aan elkaar laten klikken.

  .page__contentarea--rtddoc > .widget--includespadding:first-child
, .page__contentarea--rtddoc > .page-contentstart + .widget--includespadding
{
  margin-top: 0;
  border-top: 1px solid rgba(0,0,0,0.2);
}
*/


/* Make the last contentarea item stick to the bottom
   if it has it's own (grey/color) panel with padding.
*/
.page__contentarea > .widget--includespadding:last-child
{
  margin-bottom: 0;
}



/* Make the fillter element and page footer (with breadcrums navigation)
   take on the same color as the last widget. */
  .widget--includespadding.colortheme--grey + .page__balloon
, .widget--includespadding.colortheme--grey + .page__balloon + .page__footer
{
  background-color: var(--color-bggrey);
}


/* stick to either the crumble path, newsletter subscribe bar (DU) or footerlinks panel */
.widget--sticktofooter + .page__balloon
{
  --whitespace-footer-content: 0;
}
.widget--sticktofooter + .page__balloon + .page__footer .page__footer__content.navpath
{
  padding-top: 12px;
}


/* These widgets (in specified conditions) use a bigger padding,
   which means the footer should use a small margin to get the same distance
   to the last content.

  .widget-cta.colortheme--grey + .page__balloon
, .widget--featuredpages.widget--includespadding + .page__balloon
, .widget-richcontent-with-links.widget--includespadding + .page__balloon
, .projectsviewer.widget--includespadding.colortheme--grey + .page__balloon
*/
  .widget--includespadding.colortheme--grey + .page__balloon
{
  --whitespace-footer-content: 85px;
}

/* .widget--background-grey
FIXME: widget should use a class indicating whether they use the small or large whitespace */
  .widget-ctablocks.widget--includespadding.colortheme--grey + .page__balloon
, .widget-neo.widget--includespadding.colortheme--grey + .page__balloon
, .widget-persons.widget--includespadding.colortheme--grey + .page__balloon
, .widget-richcontent-with-links.widget--includespadding.colortheme--grey + .page__balloon
{
  --whitespace-footer-content: 150px;
}


.colortheme--salmon
{
  --currenttheme-background: var(--color-salmon-panel-background);
  --currenttheme-color:      var(--color-salmon-dark);
  --currenttheme-text-on-bg-color:    #121212;
  --currenttheme-heading-on-bg-color: #121212;
}

.colortheme--blue
{
  --currenttheme-background: var(--color-blue-panel-background);
  --currenttheme-color:      var(--color-blue-dark);
  --currenttheme-text-on-bg-color:    #121212;
  --currenttheme-heading-on-bg-color: #121212;
}

.colortheme--yellow
{
  --currenttheme-background: var(--color-yellow-panel-background);
  --currenttheme-color:      var(--color-yellow-dark);
  --currenttheme-text-on-bg-color:    #121212;
  --currenttheme-heading-on-bg-color: #121212;
}

.colortheme--purple
{
  --currenttheme-background: var(--color-purple-panel-background);
  --currenttheme-color:      var(--color-purple-dark);
  --currenttheme-text-on-bg-color:    #121212;
  --currenttheme-heading-on-bg-color: #121212;
}

.colortheme--black
{
  --currenttheme-background: var(--color-black-panel-background);
  --currenttheme-color:      var(--color-black-dark);
  --currenttheme-text-on-bg-color:    #121212;
  --currenttheme-heading-on-bg-color: #121212;
}

.colortheme--grey
{
  --currenttheme-background: var(--color-grey-panel-background);
  --currenttheme-color:      var(--color-grey-dark);
  --currenttheme-text-on-bg-color:    #121212;
  --currenttheme-heading-on-bg-color: #121212;
}

.colortheme--shadowpanel
{
  --currenttheme-background: var(--contentpanels-background);
  --currenttheme-boxshadow:  var(--contentpanels-box-shadow);
  --currenttheme-text-on-bg-color: var(--rtd-text-color);
  --currenttheme-heading-on-bg-color: var(--rtd-heading-color);
}
