.navpath {
  display: flex;
  align-items: center;
  margin-left: -7px;
  color: var(--component-navpath-color);
  font: var(--component-navpath-font);
}

.navpath__item {
  padding-left: 7px;
  padding-right: 7px;
  /*
    flex: 1 1 0;
    max-width: max-content;
  */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Navpath with wrapping */
.navpath {
  flex-wrap: wrap;
}

.navpath__item {
  flex: 0 1 auto;
  width: min-content;
  max-width: 150px;
}

a.navpath__item {
  color: inherit;
  text-decoration: none;
  font-weight: normal;
  color: var(--component-navpath-link-color);
}

a.navpath__item:hover {
  text-decoration: underline;
}

.navpath__seperator {
  flex: none;
}
.navpath__seperator::before {
  font: var(--component-navpath-seperator-font);
  content: var(--component-navpath-seperator-content);
}