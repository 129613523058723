.wh-autocomplete-container {
  position: relative;
  width: 100%;
  top: 5px;
  text-align: left; /* prevent inheriting another alignment */
}

.wh-autocomplete-values {
  position: absolute;
  width: 100%;
  border-top: 0;
  box-shadow: var(--component-autosuggest-box-shadow);
  background-color: var(--component-autosuggest-background);
  margin: 0;
  padding: var(--component-autosuggest-padding);
  border-radius: var(--component-autosuggest-radius);
}

.wh-autocomplete-values:empty {
  display: none;
}

/*
.wh-autocomplete-values:empty::before
{
  content: "No matches";
  padding-left: $textcontrols_padleft;
}
*/
.wh-autocomplete-values li {
  list-style: none;
  color: var(--component-autosuggest-item-color);
  font: var(--component-autosuggest-item-font);
}

.wh-autocomplete-values li.suggestion {
  margin: 0;
  padding: var(--component-autosuggest-item-pad-v) var(--component-autosuggest-item-pad-h);
  cursor: pointer;
}

.wh-autocomplete-values li.suggestion:hover {
  background-color: #EEEEEE;
}

.wh-autocomplete-values li.suggestion:focus {
  outline: 0;
  background-color: #EEEEEE;
}

.wh-autocomplete-values li.suggestion .match {
  font-weight: bold;
}