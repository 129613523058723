.widget-pagehighlights {
  --carrousel-item-spacing: 0px;
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-pagehighlights__topbar {
  grid-row: 1;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.widget-pagehighlights.relateditems .widget-pagehighlights__background {
  background: #DBE0FD;
}

.widget-pagehighlights {
  /* experimental - tile items in pagehighlights widget only */
  --component-spctile-padding-outside-h: 0;
  --component-spctile-padding-outside-v: 0;
  --component-spctile-padding-h: 0px;
  --component-spctile-padding-v: 14px;
}

@media (max-width: 639px) {
  .widget-pagehighlights {
    /* outside padding - content within this padding stays in line */
    --component-spctile-padding-outside-h: 0px;
    --component-spctile-padding-outside-v: 0px;
    /* we need just enough spacing to have the next image not visible at the right of the viewport
       (other method will be to have extra spacing every nth child.)
    */
    --carrousel-item-spacing: var(--pagegrid-gutteroutside);
    --component-spctile-background: #F8F4F9;
    --component-spctile-hover-background: #F8F4F9;
    --component-spctile-padding-h: 15px;
    --component-spctile-padding-v: 10px;
    --gridimage-aspectratio: 16 / 9 !important;
  }
  .widget-pagehighlights .widget-pagehighlights__background {
    display: none;
  }
  .widget-pagehighlights .spc-tile__meta {
    padding-bottom: 30px !important;
  }
}
@media (min-width: 640px) {
  .widget-pagehighlights__background {
    content: "";
    grid-row: 1/4;
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    border-radius: var(--contentpanels-radius-medium);
    background: #F8F4F9;
  }
}
@media (min-width: 640px) and (max-width: 800px) {
  .widget-pagehighlights__background {
    grid-column: var(--pagegrid-col-fullwidth);
    border-radius: var(--contentpanels-radius-medium-edge);
  }
}
@media (min-width: 640px) {
  .widget-pagehighlights.relateditems .widget-pagehighlights__topbar, .widget-pagehighlights.relateditems .widget-pagehighlights__itemswrapper {
    grid-column: var(--pagegrid-col-verywide);
    padding-left: var(--component-spctile-padding-outside-h);
    margin-left: calc(var(--component-spctile-padding-outside-h) * -1);
    margin-right: calc(var(--component-spctile-padding-outside-h) * -2);
  }
  .widget-pagehighlights__title {
    padding-top: 30px;
  }
  .widget-pagehighlights {
    /* outside padding - content within this padding stays in line */
    --component-spctile-padding-outside-h: 15px;
    --component-spctile-padding-outside-v: 15px;
    --component-spctile-background: transparent;
    --component-spctile-hover-background: #FFFFFF;
  }
  .widget-pagehighlights .spc-tile:first-child {
    margin-left: calc(-1 * var(--component-spctile-padding-outside-h));
  }
  .widget-pagehighlights .spc-tile {
    border-radius: 0;
    box-shadow: none;
    padding: var(--component-spctile-padding-outside-v) var(--component-spctile-padding-outside-h);
    border-radius: 15px !important;
  }
  .widget-pagehighlights .spc-tile__top {
    border-radius: var(--component-spctile-radius) !important;
  }
  .widget-pagehighlights .spc-tile__meta {
    position: relative;
    padding-bottom: 0;
  }
  .widget-pagehighlights__bottombar {
    padding-bottom: 25px;
  }
}
.widget-pagehighlights__title {
  color: var(--rtd-heading-color);
}

.widget-pagehighlights__itemswrapper {
  grid-row: 2;
}

.widget-pagehighlights__bottombar {
  margin-top: 0px;
  grid-row: 3;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  display: none;
}

.carrouselcontent--multiplepages .widget-pagehighlights__bottombar {
  display: flex;
  padding-left: var(--pagegrid-gutteroutside);
  padding-right: var(--pagegrid-gutteroutside);
}

.widget-pagehighlights__previousbutton,
.widget-pagehighlights__nextbutton {
  border: 0;
  padding: 0;
  appearance: none;
  flex: none;
  width: 28px;
  height: 28px;
  background-color: var(--color-purple1);
  border-radius: 50%;
  color: #FFFFFF;
  cursor: pointer;
}

.widget-pagehighlights__previousbutton::before {
  content: var(--fa-chevron-left);
  font: var(--fontawesome-regular);
  font-size: 14px;
}

.widget-pagehighlights__nextbutton::after {
  content: var(--fa-chevron-right);
  font: var(--fontawesome-regular);
  font-size: 14px;
}

.widget-pagehighlights__previousbutton[disabled],
.widget-pagehighlights__nextbutton[disabled] {
  opacity: 0.2;
  cursor: default;
}

.widget-pagehighlights__bullets {
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-pagehighlights__bullets > * {
  border: 0;
  width: 18px;
  height: 18px;
  background-color: var(--color-purple1);
  border-radius: 50%;
  opacity: 40%;
  transform: scale(0.54);
  transition: opacity 0.2s, transform 0.2s;
  cursor: pointer;
}

.widget-pagehighlights__bullets > .carrouselbullet--selected {
  opacity: 100%;
  transform: scale(1);
}

.widget-pagehighlights__bullets > * + * {
  margin-left: 2px;
}

/*
// Mobile
@media (max-width: 639px)
{
  // Only show the first three items on mobile
  .widget-pagehighlights__items > *:nth-child(1n+4)
  {
    display: none;
  }

  .widget-pagehighlights .praktijkvoorbeeld__title
  {
    font: 500 20px/24px var(--rtd-heading-fontfamily);
  }
  .widget-pagehighlights .praktijkvoorbeeld__description
  {
    font: 17px/24px var(--rtd-text-fontfamily);
  }
  // 296 x 195
    .widget-pagehighlights .praktijkvoorbeeld__top
  , .widget-pagehighlights .praktijkvoorbeeld__top__image
  {
    padding-top: 65.87%;
  }

  // Previous/next is superflous, swiping is easyer.
  // We can use the space for more jumpbuttons and a cleaner look.
    .widget-pagehighlights__previousbutton
  , .widget-pagehighlights__nextbutton
  {
    display: none;
  }
}

@media (min-width: 640px)
{
  .widget-pagehighlights .praktijkvoorbeeld__title
  {
    font: 500 23px/28px var(--rtd-heading-fontfamily);
  }
  .widget-pagehighlights .praktijkvoorbeeld__description
  {
    font: 17px/24px var(--rtd-text-fontfamily);
  }
  // 350 x 232
    .widget-pagehighlights .praktijkvoorbeeld__top
  , .widget-pagehighlights .praktijkvoorbeeld__top__image
  {
    padding-top: 66.28%;
  }
}
*/
.widget-pagehighlights__itemswrapper {
  grid-column: var(--pagegrid-col-fullwidth);
  /** @include horizontalcarrousel; ********************************/
  overflow-x: scroll;
  /*Carrousel style scrolling for iOS11, SF11+, Chr69+, Android Browser 67, Firefox 68 (jul 2019)*/
  scroll-snap-type: x mandatory; /* proximity */
  -webkit-overflow-scrolling: touch; /* without this snappoints don't seem to work correctly on iOS */
  scroll-behavior: smooth;
  /** @include hide-scrollbars; ************************************/
  /*https://stackoverflow.com/questions/3296644/hiding-the-scroll-bar-on-an-html-page*/
  scrollbar-width: none; /* Firefox 64+ */
  /*****************************************************************/
  scroll-behavior: smooth;
  padding-top: 5px;
  padding-bottom: 8px;
}
.widget-pagehighlights__itemswrapper::-webkit-scrollbar {
  width: 0 !important;
}

/*
Method 1

*/
@media (max-width: 1169px) {
  .widget-pagehighlights__topbar,
  .widget-pagehighlights__itemswrapper {
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
  .widget-pagehighlights__items > * {
    scroll-snap-margin-left: var(--pagegrid-gutteroutside);
    scroll-margin-left: var(--pagegrid-gutteroutside);
  }
}
/*
.widget-pagehighlights__itemswrapper
{
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
.widget-pagehighlights__items
{
  grid-column: var(--pagegrid-col-verywide);
}
*/
/*

Disabled.. CarrouselContent doesn't properly support this yet, Safari may have trouble with it, IE ??

Most pretty way though.
It scrolls over the full width of the viewport, but keeps whitespace at the sides so
we always only have the X items of the pages visible.



@media(min-width: 1170px) // 1170 is contentarea including padding
{
  .widget-pagehighlights__items
  {
    max-width: 1140px;
  }
  .widget-pagehighlights__items > *:first-child
  {
    margin-left: calc((100vw - 1110px) / 2);
  }
  .widget-pagehighlights__items > *:nth-child(3n)
  {
    margin-right: calc((100vw - 1140px) / 2);
  }
  .widget-pagehighlights__items > *
  {
    scroll-snap-margin-left: calc((100vw - 1110px) / 2);
    scroll-margin-left: calc((100vw - 1110px) / 2);
  }
}
*/
@media (min-width: 1170px) {
  .widget-pagehighlights__topbar,
  .widget-pagehighlights__itemswrapper {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
  .widget-pagehighlights__items > * {
    scroll-snap-margin-left: var(--pagegrid-gutteroutside);
    scroll-margin-left: var(--pagegrid-gutteroutside);
  }
  .widget-pagehighlights__items > *:nth-child(3n+1) {
    margin-left: calc(var(--carrousel-item-spacing) + 8px);
  }
}
.widget-pagehighlights__items {
  display: flex;
}

.widget-pagehighlights__items::after {
  content: "";
  flex: 0 0 100%;
}

.widget-pagehighlights__items > * {
  scroll-snap-align: start;
}
@media (max-width: 640px) {
  .widget-pagehighlights__items > * {
    flex: 0 0 calc(100% + var(--component-spctile-padding-outside-h));
  }
}
@media (min-width: 640px) and (max-width: 1000px) {
  .widget-pagehighlights__items > * {
    flex: 0 0 auto;
    width: calc((100% + var(--component-spctile-padding-outside-h) * 2 - var(--carrousel-item-spacing)) / 2);
  }
}
@media (min-width: 1000px) {
  .widget-pagehighlights__items > * {
    flex: 0 0 auto;
    width: calc((100% + var(--component-spctile-padding-outside-h) * 2 - var(--carrousel-item-spacing) * 2) / 3);
  }
}

.widget-pagehighlights__items > * + * {
  margin-left: var(--carrousel-item-spacing);
}