/*

"Side by Side" (sidebyside) and "Search" (search) header


- .pageheader__background
  Is used to display the background a little wider than the normal page content.
  We do need to give the textpanel the same background color, just so accessibility checker which aren't smart enough
  to see this is the background don't think the white text is on a white background.


- We give the .pageheader__background and .pageheader__text
  - a margin-top and margin-bottom to give the impression the image sticks out

*/
/* input and compact searchbutton (icon only) connected to eachother */
/*
- We cannot set margin-top on .pageheader
  When the search header is within an .acoifilterpage it has display: contents;
  (so we can place it's parts in the filter grid as if we used subgrid).

- We also shouldn't use .page-contentstart because it's only meant for targetting in CSS.
  (it gets display: none; so it won't interfere in layout)
*/
.page__contentarea:has(.pageheader--type-search) {
  --header-to-content-margin: var(--header-sidebyside-margin-to-content);
}

/* NOTE: use ~, we can have a crumblepath inbetween */
.pageheader--search ~ .page__body > .page__contentarea {
  --header-to-content-margin: var(--header-sidebyside-margin-to-content);
}

.page--knowledgebase-themes .pageheader--type-search input {
  border-radius: 100px !important;
  padding-left: 25px !important;
}

.pageheader--type-search {
  --formcontrols-focus-boxshadow: 0 0 0 3px #FFFFFF70;
  --formcontrols-focus-outline: 2px solid #FFFFFF70;
  --header-background: var(--currenttheme-background);
  --header-togglefilter-textcolor: #FFFFFF;
  --header-searchbar-radius: var(--contentpanels-radius-medium);
}
.pageheader--type-search .pageheader__title {
  color: var(--currenttheme-heading-color);
  font: var(--header-sidebyside-title-font);
  text-wrap: balance;
}
@media screen {
  .pageheader--type-search.pageheader {
    --rtd-heading-color: var(--currenttheme-heading-color);
    --rtd-text-color: var(--currenttheme-text-color);
    --rtd-link-color: var(--currenttheme-text-color);
  }
  .pageheader--type-search:focus .pageheader__background {
    outline: var(--formcontrols-focus-outline);
    outline-offset: var(--formcontrols-focus-outline-offset);
  }
}

@media screen and (min-width: 768px) {
  .acoifilterpage .pageheader__togglefilterbutton {
    display: none;
  }
  /*
  NOTE: was meant if header was outside of the content!

    html.page--no-crumbpath.pageheadertype--search .page__contentarea
    {
      padding-top: 25px;
    }
  */
  .header-crumblepath + .pageheader--search > .pageheader__background {
    border-radius: 0 0 var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter);
  }
  .pageheader--type-search {
    --formcontrols-height: 50px;
    /*
      .spc-search__heading
      {
        display: block;
        width: 100%;
        margin-bottom: 12px;

        font: var(--rtd-h3-font);
        color: var(--rtd-heading-color);
      }
    */
  }
  .pageheader--type-search .spc-searchbar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }
  .pageheader--type-search .spc-searchbar__input.spc-searchbar__input {
    flex: 1 1 0;
    /* have input and button side-by-side */
    border-radius: var(--header-searchbar-radius);
    margin: 0; /* Safari has some default margins on <input> */
    padding-right: 55px;
  }
  .pageheader--type-search .spc-searchbar__searchbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    flex: none;
    border-radius: 0;
    font-size: 0;
    height: var(--formcontrols-height);
    position: absolute;
    right: 0;
    /*
        margin-left: calc(-1 * 55px);

        width: 30px;
        padding-right: 25px;
    */
    color: var(--color-purple1);
    /*
        // @include wh-form-hidenativecontrol;
        position: absolute;
        height: 0;
        width: 0;
        min-height: 0;
        padding: 0;
        margin: 0;
        border: 0;
        overflow: hidden;
    */
  }
  .pageheader--type-search .spc-searchbar__searchbutton::after {
    font: var(--fontawesome-regular);
    font-size: 20px;
    content: var(--fa-search);
  }
  .pageheader--type-search .pageheader__background {
    background: var(--header-background);
    border-radius: var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter);
  }
  .pageheader--type-search .header-crumblepath + .pageheader__background {
    border-radius: 0 0 var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter);
  }
  body > .pageheader--type-search, .page__body > .pageheader--type-search {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  body > .pageheader--type-search .pageheader__background, .page__body > .pageheader--type-search .pageheader__background {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 1/span 2;
  }
  body > .pageheader--type-search .pageheader__text, .page__body > .pageheader--type-search .pageheader__text {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 1;
    justify-self: center;
    align-items: center;
    text-align: center;
    max-width: 750px;
  }
  body > .pageheader--type-search .pageheader__title, .page__body > .pageheader--type-search .pageheader__title {
    padding-top: 37px;
  }
  body > .pageheader--type-search .pageheader__searchpanel, .page__body > .pageheader--type-search .pageheader__searchpanel {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
  }
  .acoifilterpage .pageheader--type-search.pageheader {
    display: contents; /* we cannot use subgrid yet (due to the Chrome implementation not being ready, but will probably in a few months) */
  }
  .acoifilterpage .pageheader--type-search.pageheader .pageheader__background {
    grid-column: 2/-2;
    grid-row: 2;
    height: 100%;
  }
  .acoifilterpage .pageheader--type-search.pageheader .pageheader__text {
    display: contents;
  }
  .acoifilterpage .pageheader--type-search.pageheader .pageheader__title {
    padding-top: 40px;
    padding-bottom: 40px;
    grid-column: var(--pagegrid-col-withsidebar-sidebar);
    grid-row: 2;
    justify-self: end;
    align-items: center;
  }
  .acoifilterpage .pageheader--type-search.pageheader .pageheader__searchpanel {
    padding-top: 40px;
    padding-bottom: 40px;
    grid-column: var(--pagegrid-col-withsidebar-content);
    grid-row: 2;
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 767px) {
  .pagetabs--tabs input[type=radio] {
    position: static;
    overflow: initial;
  }
  .spc-searchbar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }
  /*
    .spc-search__heading
    {
      display: block;
      width: 100%;
      margin-bottom: 12px;

      font: var(--rtd-h3-font);
      color: var(--rtd-heading-color);
    }
  */
  .spc-searchbar__input.spc-searchbar__input {
    flex: 1 1 0;
    /* have input and button side-by-side */
    border-radius: var(--header-searchbar-radius);
    margin: 0; /* Safari has some default margins on <input> */
    padding-right: 55px;
  }
  .spc-searchbar__searchbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    flex: none;
    border-radius: 0;
    font-size: 0;
    height: var(--formcontrols-height);
    position: absolute;
    right: 0;
    /*
        margin-left: calc(-1 * 55px);

        width: 30px;
        padding-right: 25px;
    */
    color: var(--color-purple1);
    /*
        // @include wh-form-hidenativecontrol;
        position: absolute;
        height: 0;
        width: 0;
        min-height: 0;
        padding: 0;
        margin: 0;
        border: 0;
        overflow: hidden;
    */
  }
  .spc-searchbar__searchbutton::after {
    font: var(--fontawesome-regular);
    font-size: 20px;
    content: var(--fa-search);
  }
  body {
    --header-search-margin-above: var(--header-search-mobile-margin-above);
  }
  .pageheader--type-search {
    --formcontrols-height: 43px;
    --textcontrols-font: 16px/30px var(--rtd-text-fontfamily);
    --header-searchbar-radius: 5px;
    /* Header nested directly in the page grid (of <body>) */
    /* Header nested within the .acoifilterpage
       (so the header content can be aligned to the sidebar/results)
    */
  }
  .pageheader--type-search .pageheader__background {
    height: 100%;
    background: var(--header-background);
    border-radius: 0;
  }
  body > .pageheader--type-search {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  body > .pageheader--type-search > .pageheader__background {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 1/span 2;
  }
  body > .pageheader--type-search > .pageheader__background {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 1/span 2;
  }
  body > .pageheader--type-search .pageheader__text {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 1;
    justify-self: center;
    align-items: center;
    text-align: center;
    max-width: 750px;
  }
  body > .pageheader--type-search .pageheader__title {
    padding-top: 27px;
  }
  body > .pageheader--type-search .pageheader__searchpanel {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
  }
  .acoifilterpage .pageheader--type-search.pageheader {
    display: contents; /* we cannot use subgrid yet (due to the Chrome implementation not being ready, but will probably in a few months) */
  }
  .acoifilterpage .pageheader--type-search.pageheader .pageheader__background {
    grid-column: 1/-1;
    grid-row: 1;
  }
  .acoifilterpage .pageheader--type-search.pageheader .pageheader__text {
    grid-column: 3/-3;
    grid-row: 1;
    padding: 15px 0 10px 0;
    color: var(--currenttheme-text-color);
  }
  .acoifilterpage .pageheader--type-search.pageheader .pageheader__title {
    display: none;
  }
  .pageheader--type-search .pageheader__togglefilterbutton {
    margin-top: 3px;
  }
}