.contentareaheader__when
{
  color: var(--common-caption-color);
  font: var(--header-sidebyside-pagedate-font);
}

.contentareaheader__when::before
{
  font: var(--header-when-icon-font);
  content: var(--header-when-icon-content);

  margin-right: 10px;
}

.contentareaheader__title
{
}

.contentareaheader__source
{
  color: var(--common-caption-color);
  font: var(--header-sidebyside-pagedate-font);
}
