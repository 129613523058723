.sharecomponent__sharebuttons {
  display: flex;
  column-gap: var(--component-sharepage-button-spacing);
}

.sharecomponent__buttonbar .wh-share,
.spc-sociallink {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-decoration: none;
  /* default (baseline) vertical alignment  is messy because
     we mix SVG and font icons (which may have different font-size/line-heights. */
  vertical-align: middle;
  flex: none; /* don't shrink/flatten us when whe're in a flex container */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  width: var(--component-sharepage-button-size, 37px);
  height: var(--component-sharepage-button-size, 37px);
  background-color: #FFFFFF;
  background: var(--component-sharepage-button-background, #FFFFFF);
  color: var(--component-sharepage-button-color);
  border: var(--component-sharepage-button-border);
  border-radius: var(--component-sharepage-button-radius);
  cursor: pointer;
}

a.spc-sociallink svg {
  width: 21px;
  height: 21px;
  --icon-color: var(--component-sharepage-button-color);
  --icon-within-color: var(--component-sharepage-button-color-inside);
  --icon-color-g1: var(--icon-color);
  --icon-color-g2: var(--icon-color);
  --icon-color-g3: var(--icon-color);
  --icon-color-g4: var(--icon-color);
  --icon-color-g5: var(--icon-color);
  --icon-applepodcast-color: #FFFFFF;
}

a.spc-sociallink:hover svg {
  --icon-color: var(--component-sharepage-button-hover-color);
  --icon-applepodcast-color: var(--component-sharepage-button-hover-color);
  --icon-within-color: var(--component-sharepage-button-hover-color-inside);
}

.spc-sociallink-breakline {
  flex: 0 0 100%;
}

/*
Leave commented out for now!
*/
.sharecomponent__buttonbar .wh-share:focus,
a.spc-sociallink:focus {
  /*
    outline: 0;

    box-shadow:     var(--formcontrols-focus-boxshadow);
    //outline:        var(--formcontrols-focus-outline);
  */
  outline-offset: 5px;
}

.sharecomponent__buttonbar > .wh-share + .wh-share {
  margin-left: 13px;
}

.sharecomponent__buttonbar .wh-share:hover,
a.spc-sociallink:hover {
  background: var(--component-sharepage-button-hover-background);
  border: var(--component-sharepage-button-hover-border);
  color: var(--component-sharepage-button-hover-color);
}

.sharecomponent__buttonbar .wh-share[data-network=twitter]::before,
a.spc-sociallink[data-network=twitter]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-twitter);
}

.sharecomponent__buttonbar .wh-share[data-network=facebook]::before,
a.spc-sociallink[data-network=facebook]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-facebook-f);
}

.sharecomponent__buttonbar .wh-share[data-network=linkedin]::before,
a.spc-sociallink[data-network=linkedin]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-linkedin-in);
}

/*
  .sharecomponent__buttonbar .wh-share[data-network="spotify"]::before
, a.spc-sociallink[data-network="spotify"]::before
{
  font: var(--fontawesome-brands);
  content: var(--fa-spotify);
}
*/
.sharecomponent__buttonbar .wh-share[data-network=whatsapp]::before,
a.spc-sociallink[data-network=whatsapp]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-whatsapp);
}

.sharecomponent__buttonbar .wh-share[data-network=email]::before,
.sharecomponent__buttonbar .wh-share.siteaction--emailpagelink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-envelope);
}

.sharecomponent__buttonbar .wh-share[data-network=print]::before {
  font: var(--fontawesome-regular);
  content: var(--fa-print);
  font-size: 19px;
  font-size: var(--component-sharepage-button-symbolsize, 19px);
}

.sharecomponent__buttonbar .wh-share.wh-share::before,
a.spc-sociallink.spc-sociallink::before {
  font-size: 20px;
  font-size: var(--component-sharepage-button-symbolsize, 20px);
}

.sharecomponent__buttonbar .wh-share[data-network=spotify]::before,
a.spc-sociallink[data-network=spotify]::before {
  font-size: calc(var(--component-sharepage-button-symbolsize) * 1.3);
  margin-top: 2px;
}

/*
.sharecomponent
{
  margin-top: 60px;
  padding-top: 35px;
}
*/
.sharecomponent__buttonbar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 18px;
}

/**************************************************************
Trait - Sharebar label and share buttons side-by-side
        if there's no "Download as PDF" button.
*/
.sharecomponent.sharecomponent--with-pdf .sharecomponent__title {
  margin-bottom: 14px;
}

/* If there's no PDF button we got room to have the share text and buttons next to each other */
.sharecomponent:not(.sharecomponent--with-pdf) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 17px;
  row-gap: 18px;
}

/***************************************************************/
.contentwithin_wide {
  /*
      .sharecomponent
      {
        width: max-content; // shrink and center if no "download PDF" button is active
        margin-left: auto;
        margin-right: auto;
      }
  */
}
.contentwithin_wide .sharecomponent--bothactions {
  width: auto;
}
.contentwithin_wide .sharecomponent:not(.sharecomponent--bothactions) .sharecomponent__buttonbar {
  justify-content: center;
}

.content__contentcolumn .sharecomponent {
  padding-left: 0;
}

.sharecomponent__title {
  color: var(--component-sharepage-heading-color);
  font: var(--component-sharepage-heading-font);
  white-space: nowrap;
}

h2.sharecomponent__title {
  margin-top: 0;
}

a.shareactionsbar__downloadpdflink {
  text-decoration: none;
  background: var(--component-sharepage-button-background, #FFFFFF);
  --button-bordercolor: var(--color-theme-faded);
}

a.shareactionsbar__downloadpdflink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-file-pdf);
  font-size: 20px;
  margin-right: 15px;
}

.sharecomponent__buttonbar__filler {
  flex: 1 0 0px;
}

/**************************************************************
Trait - On mobile:
        - center the share buttons
        - make the PDF download button's width align with the above sharebuttons

      (To do this we shrinkwrap the container (of both the sharebuttons and download PDF button)

@media (max-width: 550px)
{
  .sharecomponent__title
  {
    font-weight: normal;
    text-align: center;
  }

  .sharecomponent__buttonbar
  {
    width: min-content;
    margin-left: auto;
    margin-right: auto;
  }

  .shareactionsbar__downloadpdflink
  {
    width: 100%;
  }
}
*/