/* Header & rsults tweaks */
.page--financielewegwijzer .pageheader__text {
  text-align: center;
}
.page--financielewegwijzer .pageheader__title + .pageheader__text__description {
  margin-top: 30px;
}
.page--financielewegwijzer .acoifilterpage__results {
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  --contentpanels-box-shadow: 0 2px 8px 0 rgba(0,0,0,0.25);
}

.financielewegwijzer-topbar__query {
  grid-column: var(--pagegrid-col-withsidebar-sidebar);
  align-self: baseline;
}

.financielewegwijzer-topbar__resultdata {
  grid-column: var(--pagegrid-col-withsidebar-content);
  align-self: baseline;
  display: flex;
  align-items: baseline;
}

.downloadresultsbutton {
  margin-left: auto;
  font-size: 15px;
}

.acoifilterpage__noresults {
  display: none;
  font-size: 22px;
}

html.filteredoverview--noresults .acoifilterpage__noresults {
  display: block;
}

@media print {
  .fwinstrument__buttonbar {
    display: none !important;
  }
}