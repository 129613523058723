button.infobutton
{
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;

  vertical-align: middle;

  position: relative;
  top: -3px;

  /* prevent the toggle button from stretching the line-height */
  margin-top:  -4px;
  margin-bottom:  -6px;

  cursor: pointer;


  border-radius: 50%;
}

button.infobutton:focus
{
  outline: 0;
  box-shadow: var(--color-purple-focus-boxshadow);
  /*filter:  drop-shadow( 0 0 2px 3px #8C108722 );*/

}

button.infobutton::before
{
  font: var(--widget-infobutton-icon-font);
  font-size: var(--widget-infobutton-icon-size);
  content: var(--widget-infobutton-icon-content);

  color: var(--color-purple-dark);
}

button.infobutton:hover::before
{
  font: var(--fontawesome-solid);
  font-size: var(--widget-infobutton-icon-size);
}

.wh-rtd-embeddedobject--inline .wh-rtd-embeddedobject__preview
{
  filter: none; /* disable wh's grayscale filter */
}

/*
[data-theme="infobutton-tooltip"]
*/

.tippy-box.tippy-box
{
  border: var(--component-tooltip-border);
  border-radius: var(--component-tooltip-borderradius);

  background-color: var(--component-tooltip-bgcolor);

  filter: var(--component-tooltip-filter);

  color: var(--component-tooltip-textcolor);
  font: var(--component-tooltip-font);
  --rtd-text-color: var(--component-tooltip-textcolor);
  --rtd-text-font: var(--component-tooltip-font);

  max-width: 320px;

  padding: 10px 13px;

}

.tippy-box[data-placement^='top'] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before
{
  border-top-color: var(--component-tooltip-bgcolor);
  border-left-width: 15px;
  border-right-width: 15px;
  border-top-width: 15px;

  transform: none;
  bottom: -15px;
  margin-left: -7px;
}

.tippy-box[data-placement^='bottom'] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before
{
  border-bottom-color: var(--component-tooltip-bgcolor);
  border-left-width: 15px;
  border-right-width: 15px;
  border-bottom-width: 15px;

  transform: none;
  top: -15px;
  margin-left: -7px;
}
