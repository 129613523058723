.widget-ctacarrousel
{
  --content-layout:             verywide;
  --content-squeeze-preference: preferwide;

  --ctacarrousel-image-aspectratio:  1 / 1;
  --ctacarrousel-image-borderradius: 50%;
  --ctacarrousel-image-width-max:    clamp(250px, 40vw, 389px);

  --ctacarrousel-transition-duration:    2200ms;

  --ctacarrousel-transition-in-delay:       0ms;
  --ctacarrousel-transition-in-duration: 1500ms;

  --slideshow-slide-visibilityduration: 6000ms;

  --rtd-text-color:    #FFFFFF;
  --rtd-link-color:    #FFFFFF;
  --rtd-heading-color: #FFFFFF;
  --rtd-text-font: 17px/28px var(--rtd-heading-fontfamily);


  --widget-generic-bigpanel-padding-bottom: 37px;

  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.wh-rtd .widget-ctacarrousel
{
  --pagegrid-col-verywide: var(--pagegrid-col-fullwidth);
}




/*
.widget-ctacarrousel.widget--includespadding
{
  padding-top:    var(--widget-generic-bigpanel-padding-top);
  padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
}
*/

.widget-ctacarrousel__slides
{
  /* Make this __slides container a grid so we can overlap
     all slides and stretch the container so all slides fit in it. */
  display: grid;

  overflow: hidden; /* enforce border-radius upon slides */
}




.widget-ctacarrousel__slide
{
  grid-column: 1;
  grid-row: 1;
}




/* Only show thr first item in the RTD preview of the widget */
.wh-rtd .widget-ctacarrousel__slide:nth-child(1n + 2)
{
  display: none;
}



.widget-ctacarrousel__slide h2
{
  margin: 0;
}

.widget-ctacarrousel__slide__title-cta
{
/*  font: 19px/30px var(--rtd-heading-fontfamily);*/
  color: var(--rtd-text-color);
  font: var(--rtd-text-font);
  text-wrap: balance;

  margin-bottom: 4px;
}

.widget-ctacarrousel__slide__title
{
  color: var(--rtd-heading-color);
  font: 26px/36px var(--rtd-heading-fontfamily);
  text-wrap: balance;
}

.widget-ctacarrousel__slide__description
{
/* FIXME: like subtle-font but with larger line-height
  --rtd-text-subtle-font:    17px/25px var(--rtd-text-fontfamily);
*/

  /* In case we fall back to the page's overview "description" (which isn't richtext) */
  color: var(--rtd-text-color);
  font:  var(--rtd-text-font);
}

.widget-ctacarrousel__slide__description p
{
  text-wrap: balance;
}

.widget-ctacarrousel__slide__description.widget-ctacarrousel__slide__description a
{
  text-decoration: underline;
}

.widget-ctacarrousel__slide__imagewrapper
{
  aspect-ratio: var(--ctacarrousel-image-aspectratio);

  border-radius: var(--ctacarrousel-image-borderradius);
  max-width: var(--ctacarrousel-image-width-max);
  overflow: hidden;
}

.widget-ctacarrousel__slide__image
{
  width: 100%;
  height: 100%;
  object-fit: cover;
}






/* stacked view (for mobile and tablet */
@media (max-width: 700px)
{
  .widget-ctacarrousel
  {
    --component-jumpbutton-with-progress-background:     var(--color-purple2);
    --component-jumpbutton-with-progress-bar-background: var(--color-green2);

    --widget-generic-bigpanel-padding-top: 20px;
    --widget-generic-bigpanel-padding-bottom: 29px;
    /*20px 15px 29px 15px;*/

    --ctacarrousel-image-width-max:    clamp(135px, 30vw, 250px);

    grid-template-rows: var(--widget-generic-bigpanel-padding-top) /* padding-top */
                        min-content /* header */
                        min-content /* slides */
                        var(--widget-generic-bigpanel-padding-bottom);
                        min-content /* jumpbuttons (progress) */
  }

  .widget-ctacarrousel__background
  {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 1 / span 4;
    background: var(--currenttheme-background);
    border-radius: var(--contentpanels-radius-large);
  }

  .widget-ctacarrousel__slides
  {
    grid-row: 3;
    grid-column: var(--pagegrid-col-verywide);
  }

  .widget-ctacarrousel__slide
  {
    padding-left: 15px;
    padding-right: 15px;
  }

  .widget-ctacarrousel__slide__title-cta
  {
    font: 15px/24px var(--rtd-heading-fontfamily);
  }

  .widget-ctacarrousel__slide__title
  {
    font: 19px/24px var(--rtd-heading-fontfamily);
  }

  .widget-ctacarrousel__slide__description
  {
    --rtd-text-font: 16px/24px var(--rtd-text-fontfamily);
  }

  .widget-cta__button
  {
    margin-top: 15px;
  }



  .widget-ctacarrousel__tabs
  {
    grid-row: 5;
    grid-column: var(--pagegrid-col-verywide);
    justify-content: center;

/*    margin-top: 12px;*/
    margin-top: 5px;
  }

  .widget-ctacarrousel__slide
  {
    display: flex;
    flex-direction: column;

    pointer-events: none;
  }

  .widget-ctacarrousel__slide.activeslide
  {
    pointer-events: auto;
    z-index: 2;
  }

  .widget-ctacarrousel__slide__meta
  {
    flex: 1 0 auto;
  }

  .widget-ctacarrousel__slide__image
  {
    opacity: 0;
    transition: opacity 0.10s;
    transition-delay: 0.75s;
  }

  .widget-ctacarrousel__slide.activeslide .widget-ctacarrousel__slide__image
  {
    z-index: 2;
    opacity: 1;
    transition: opacity 0.75s;
  }

  .widget-ctacarrousel__slide__imagewrapper
  {
    order: -1; /* show at the top of the slide */
    margin: 0 auto;
    margin-bottom: 20px;
  }


  /***************************************************************************
   * Fancy crossfade
   */

  .widget-ctacarrousel__slide__meta
  {
    opacity: 0;
  }

  .widget-ctacarrousel__slide.activeslide .widget-ctacarrousel__slide__meta
  {
    opacity: 1;
  }


  .widget-ctacarrousel--slideshow .widget-ctacarrousel__slide__image
  {
    opacity: 0;
    animation: var(--ctacarrousel-transition-duration) fadeSlideOutWithBlur; /* the animation forces the opacity to stay at 1 for 11 seconds */
  }

  .widget-ctacarrousel__slide.activeslide .widget-ctacarrousel__slide__imagewrapper
  {
    z-index: 2;
  }

  .widget-ctacarrousel__slide.activeslide .widget-ctacarrousel__slide__image
  {
/*    mix-blend-mode: plus-lighter;*/
    animation: var(--ctacarrousel-transition-in-duration) fadeSlideIn forwards;
    animation-delay: var(--ctacarrousel-transition-in-delay);
    z-index: 2;
  }
  /***************************************************************************/

  .widget-ctacarrousel__tabs
  {
    margin-left: 15px;
    margin-right: 15px;
    column-gap: 12px;
  }

  .widget-ctacarrousel__playbacktogglebutton
  {
    width:  40px;
    height: 36px;

    margin-left: -10px;
    color:  var(--color-theme);
/*    background-color: #F1F1F1;*/
  }

  .widget-ctacarrousel__playbacktogglebutton:hover
  {
    background: #EEEEEE;
  }

  .widget-ctacarrousel__tab__progress
  {
  }
}



/* Desktop (the design) */
@media (min-width: 701px)
{
  .widget-ctacarrousel
  {
    --component-jumpbutton-with-progress-background:     #FFFFFF;
    --component-jumpbutton-with-progress-bar-background: var(--color-green2);

    /* larger play/pause togglebutton causes a larger whitespace,
       so decrease the size of the bottom padding. */
    --widget-generic-bigpanel-padding-bottom: 25px; /* was 37px */

    grid-template-rows: var(--widget-generic-bigpanel-padding-top) /* padding-top */
                        min-content /* header */
                        min-content /* slides */
                        min-content /* jumpbuttons (progress) */
                        var(--widget-generic-bigpanel-padding-bottom);
  }

  .widget-ctacarrousel__background
  {
    grid-row: 1 / span 5;
    grid-column: var(--pagegrid-col-verywide);
    background: var(--currenttheme-background);
    border-radius: var(--contentpanels-radius-large);
  }

  .widget-ctacarrousel__slides
  {
    grid-row: 3;
/*    grid-column: var(--pagegrid-col-verywide);*/
    grid-column: 4 / 26;
  }

  .widget-ctacarrousel__tabs
  {
    grid-row: 4;
    grid-column: var(--pagegrid-col-verywide);
    justify-content: center;

/*    margin-top:  48px;*/
    margin-top:  15px;
  }

  .widget-ctacarrousel__slide
  {
    pointer-events: none;
  }

  .widget-ctacarrousel__slide.activeslide
  {
    pointer-events: auto;
    z-index: 2;
  }

  /***************************************************************************
   * Fancy crossfade for desktop
   */

  .widget-ctacarrousel__slide__meta
  {
    opacity: 0;
  }

  .widget-ctacarrousel__slide.activeslide .widget-ctacarrousel__slide__meta
  {
    opacity: 1;
  }


  .widget-ctacarrousel--slideshow .widget-ctacarrousel__slide__image
  {
    opacity: 0;
    animation: var(--ctacarrousel-transition-duration) fadeSlideOutWithBlur; /* the animation forces the opacity to stay at 1 for 11 seconds */
  }

  .widget-ctacarrousel__slide.activeslide .widget-ctacarrousel__slide__imagewrapper
  {
    z-index: 2;
  }

  .widget-ctacarrousel__slide.activeslide .widget-ctacarrousel__slide__image
  {
/*    mix-blend-mode: plus-lighter;*/
    animation: var(--ctacarrousel-transition-in-duration) fadeSlideIn forwards;
    animation-delay: var(--ctacarrousel-transition-in-delay);
    z-index: 2;
  }
  /***************************************************************************/
}


/*
Trait: Side-by-side (2 column)

@media (min-width: 701px)
{
  .widget-ctacarrousel__slide
  {
    display: flex;
    padding-left: 67px;
    padding-right: 57px;
  }

  .widget-ctacarrousel__slide__meta
  {
    flex: 1 0 0px;
    padding-right: 40px;

    margin-top: 28px;
  }

  .widget-cta__button
  {
    margin-top: 40px;
  }

  .widget-ctacarrousel__slide__imagewrapper
  {
    order: 2;
    align-self: start;
  }
}
*/


/*
Trait: Side-by-side image floating
- if space is cramped the text will start to flow around
  instead of staying next to the image)
*/
@media (min-width: 701px) /* and (max-width: 1200px) */
{
  .widget-ctacarrousel__slide__meta
  {
    max-width: 730px;
    margin-top: 32px;
  }

  .widget-ctacarrousel__slide
  {
    display: block;
  }

  .widget-ctacarrousel__slide__imagewrapper
  {
    float: right;
    shape-outside:circle();
    margin-left: 35px;
    margin-bottom: 35px;
  }

  .widget-ctacarrousel__slide
  {
/*    padding-left:  25px;*/
    padding-right: 25px;
  }

  .widget-cta__button
  {
    margin-top: 40px;
  }
}



@keyframes fadeSlideOutWithBlur
{
  from { opacity: 1; filter: blur(0); }
  to   { opacity: 1; filter: blur(10px); }
}


@keyframes fadeSlideIn
{
  from { opacity: 0; }
  to   { opacity: 1; }
}
