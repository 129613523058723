/* needs to be more specific than for example p.intro and .widget--opaque.widget--opaque */
.page-contentstart.page-contentstart.page-contentstart + * {
  margin-top: var(--header-to-content-margin);
}

/* needs to be more specific than ".rtdcontent > *:first-child" */
.page__contentarea > .deeplinks-wrapper.deeplinks-wrapper {
  margin-top: var(--header-to-content-margin);
}

/* Ensure pixel perfect alignment of the text if possible */
.page-contentstart + * {
  leading-trim: both;
  text-edge: cap alphabetic;
}

.page-contentstart + .acoifilterpage {
  /* An filterpage has it's own header which must start without empty space at the top of the contentarea */
  --header-to-content-margin: 0;
}

.pageheader--base .pageheader__title .suffix {
  font-weight: 300; /* light */
}
@media screen and (min-width: 768px) {
  .pageheader--base .pageheader__title--mobile {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .pageheader--base .pageheader__title--mobile + .pageheader__title--desktop {
    display: none;
  }
}

.pageheader__togglefilterbutton {
  width: auto;
  align-self: end;
  flex: none;
  display: block;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-align: left;
  color: var(--header-togglefilter-textcolor);
  text-decoration: underline;
  text-underline-offset: 4px;
  font: 16px var(--rtd-text-fontfamily);
  cursor: pointer;
  padding: 7px; /* enlarge hitarea */
  margin-left: -7px;
  margin-right: -7px;
  /* NOTE: override .rtdcontent > *:last-child */
  margin-bottom: 23px !important;
}

/*
.pageheader__togglefilterbutton::after
{
  display: inline-block;

  font: var(--fontawesome-regular);
  content: var(--fa-arrow-right);
  font-size: 16px;

  position: relative;
  top: 2px;

  margin-left: 10px;
  text-decoration: none;
}
*/