/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*

- Used for CAK demo

*/
.pageheader--textinset + .page__body > .page__contentarea {
  --header-to-content-margin: var(--header-textinset-margin-to-content);
}

.pageheader--textinset {
  --header-imagestickingout-image-coverheader-amount: 87px;
  margin-top: var(--header-textinset-margin-above);
  /* When we use the full contentarea width, the header must also align to this width.
     This means the image will use the full contentarea width and credits
     will be shown below the image.
  */
  /* When content is centered whe'll use the default (text)content width
     of that page to align the title and image to.
     The credits will be positioned at the right in the area at the right of
     the current pages default content area.
  */
}
.pageheader--textinset.pageheader {
  /*
  --rtd-heading-color: #FFFFFF;
  --rtd-text-color: #FFFFFF;
  --rtd-link-color: #FFFFFF;
  */
}
.pageheader--textinset:focus .pageheader__imagewrapper {
  outline: var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);
}
.pageheader--textinset .pageheader__text {
  color: var(--rtd-text-color);
}
.pageheader--textinset .pageheader__when {
  font: var(--header-imagestickingout-pagedate-font);
  margin-bottom: var(--header-imagestickingout-pagedate-spacing);
}
.pageheader--textinset .pageheader__when::before {
  font: var(--header-when-icon-font);
  content: var(--header-when-icon-content);
  margin-right: 10px;
}
.pageheader--textinset .pageheader__title {
  font: var(--header-textinset-title-font);
}
.pageheader--textinset .pageheader__source {
  font: var(--header-imagestickingout-source-font);
}
.pageheader--textinset .pageheader__imagecredits {
  font: var(--pageheader-credits-font);
}
@media screen {
  .pageheader--textinset.pageheader {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: min-content var(--header-imagestickingout-image-coverheader-amount) min-content;
  }
  .pageheader--textinset .pageheader__imagewrapper {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 1/span 2;
    height: clamp(237px, 25vw, 343px);
    background-color: var(--currenttheme-color);
    border-radius: var(--contentpanels-radius-outsidegutter);
  }
  .pageheader--textinset .pageheader__image {
    border-radius: var(--contentpanels-radius);
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .pageheader--textinset .pageheader__text {
    grid-column: var(--pagegrid-col-default);
    grid-row: 2/span 2;
    background: var(--contentpanels-background);
    border-radius: var(--contentpanels-radius);
    box-shadow: var(--common-itemblocks-box-shadow);
    padding: var(--common-itemblocks-padding);
    z-index: 0;
    --rtd-spacing-paragraph: 15px;
  }
}
@media screen and (max-width: 1023px) {
  .pageheader--textinset .pageheader__imagecredits {
    margin-top: 8px;
    grid-column: var(--pagegrid-col-default);
    grid-row: 4;
    color: var(--common-caption-color);
    /* Take out of normal left-to-right reading flow */
    text-align: right;
  }
}
@media screen and (min-width: 1024px) {
  .pageheader--textinset + .page__body > .page__contentarea .page-contentstart {
    display: block;
    grid-row: 1;
    grid-column: var(--pagegrid-col-narrow);
    aspect-ratio: 3/2;
    aspect-ratio: var(--headerimage-aspectratio);
    max-height: max(250px, 100vh - 323px - 100px);
    margin-bottom: calc(var(--header-imagestickingout-image-coverheader-amount) * -1);
  }
  .pageheader--textinset .page-contentstart, .pageheader--textinset .deeplinks-wrapper {
    grid-row: 1;
  }
  .pageheader--textinset + .page__body > .page__contentarea .page-contentstart + * {
    grid-row: 2;
  }
  .pageheader--textinset .pageheader__imagecredits {
    grid-column: var(--pagegrid-col-narrow-gutter-right);
    grid-row: 2;
    padding: var(--header-imagestickingout-imagecredits-padding);
    color: var(--color-theme-faded);
    align-self: end; /* towards the bottom of the visible page-header panel */
    text-align: right;
  }
}
@media print {
  .pageheader--textinset.pageheader {
    background: transparent;
    --rtd-heading-color: #000000;
    --rtd-text-color: #000000;
    --rtd-link-color: #000000;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .pageheader--textinset .pageheader__background {
    display: none;
  }
  .pageheader--textinset .pageheader__text {
    grid-row: 1;
    grid-column: var(--pagegrid-col-verywide);
    margin-bottom: 0.1cm;
  }
  .pageheader--textinset .pageheader__imagewrapper {
    grid-row: 2;
    grid-column: 1/-1;
  }
  .pageheader--textinset .pageheader__image {
    width: 100%; /* abide to the given width */
    height: 7cm;
    object-fit: cover;
  }
  .pageheader--textinset .pageheader__imagecredits {
    grid-row: 3;
    text-align: right;
    color: var(--common-caption-color);
    font: var(--pageheader-credits-font);
    grid-column: var(--pagegrid-col-verywide);
  }
}