  .widget-highlight
, .widget-highlight-preview
{
  grid-column:   var(--pagegrid-col-default);
  border-radius: var(--contentpanels-radius);

  background-color: var(--currenttheme-background);
  box-shadow:       var(--currenttheme-boxshadow);
}

  .widget-highlight__content
, .widget-highlight-preview
{
  padding: var(--widget-highlight-padding);
}

  .widget-highlight .rtdcontent
, .widget-highlight-preview
{
  /*color: #4F4F4F;*/
  --rtd-text-color:    var(--currenttheme-text-color);
  --rtd-heading-color: var(--currenttheme-heading-color);
}
