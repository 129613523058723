/* ZVI rtd and contentpanel settings */
html
{
  /* Contentpanels
     - panels on the page which use the default or verywide content area width
     - menu dropdowns

  ZVI:

  - Large
    - dekstop: 30 pixels
    - mobile:   8 pixels
    - used by: home highlights widget

  - medium
    - desktop: 15 pixels
    - mobile:   8 pixels
     (CTA widget compact, CTA bar, footer, image with caption)

  -  8 pixels (news item, person item)

  */
  --contentpanels-box-shadow-color:     rgba(0,0,0,0.35);
  --contentpanels-background:           #FFFFFF;
  --contentpanels-box-shadow:           0 2px 17px 0 var(--contentpanels-box-shadow-color);
  --contentpanels-radius:               9px;
  --contentpanels-radius-outsidegutter: 9px; /* this will be reset to 0 when outsidegutter touches the edge of the screen */

  --contentpanels-radius-large:                30px;
  --contentpanels-radius-medium:               15px;
  --contentpanels-radius-small:                 8px;
  --contentpanels-radius-large-viewport-edge:  30px;
  --contentpanels-radius-medium-viewport-edge: 15px;
  --contentpanels-radius-small-viewport-edge:   8px;



  --contentpanels-padding-v: 30px; /* --widget-highlight-padding:           20px 25px; */
  --contentpanels-padding: 30px;

  --widget-highlight-padding:           20px 25px;

  --contentpanels-padleft:              25px;
  --contentpanels-padright:             25px;

  --contentpanels-color-grey:               #EEEEEE;
  --contentpanels-color-grey-caption-color: #6C6C6C; /* slightly darker color for --common-caption-color when used on the grey background */


  /* Richtext content COLORS ───────────────────────────────────────────────────── */

  --rtd-heading-color:      var(--color-purple1);
  --rtd-intro-color:        #333333;
  --rtd-text-color:         #333333;
  --rtd-text-faded-color:   var(--common-caption-color); /* FIXME: color in design */
  /*--rtd-intro-color:        var(--color-purple-dark);*/

  --rtd-link-color:         var(--color-purple1);
  --rtd-link-color-hover:   var(--color-purple1);

  --rtd-link-font-weight:   normal;

  --rtd-list-ul-marker-color:  var(--color-purple2);
  --rtd-list-ol-marker-color:  var(--color-purple2);



  /* Richtext content FONTS ───────────────────────────────────────────────────── */

  --rtd-heading-fontfamily:  "Poppins";
/*  --rtd-text-fontfamily:     "Source Sans 3";*/
  --rtd-text-fontfamily:     "RO Sans";

  /* Paragraphs */
  --rtd-text-font:           19px/30px var(--rtd-text-fontfamily);
  --rtd-text-compact-font:   19px/28px var(--rtd-text-fontfamily); /* smaller line-height for table heading, list items (such as newsoverview page summary) and home-intro-inset-question-option */

  /* FIXME: how to get these from the desigm? */
  --rtd-table-font:          17px/24px var(--rtd-text-fontfamily); /* FIXME: not designed */
  --rtd-text-subtle-font:    17px/24px var(--rtd-text-fontfamily); /* used in --component-spctile-text-font */
  --rtd-text-small-font:     16px/22px var(--rtd-text-fontfamily); /* CTA widget subtitle, image with caption caption */
  --rtd-text-tiny-font:      13px/20px var(--rtd-text-fontfamily); /* ?? header image caption? */

  --pageheader-credits-font: 16px/24px var(--rtd-text-fontfamily); /* was called --rtd-footnote-font) before

  /* Headings & intro */
  --rtd-h1-font:             44px/58px var(--rtd-heading-fontfamily);
  --rtd-h1-fontsize:         44px; /* for use in clamp() */
  --rtd-intro-font:          20px/36px var(--rtd-heading-fontfamily);
  --rtd-h2-font:             24px/36px var(--rtd-heading-fontfamily);
  --rtd-h2-scaling-font:     clamp(22px,1.8vw,24px)/150% var(--rtd-heading-fontfamily);
/*  --rtd-h2-smaller-font:    bold 22px/32px var(--rtd-heading-fontfamily);*/
  --rtd-h3-font:             20px/30px var(--rtd-heading-fontfamily);
/*  --rtd-h3-compact-font:    bold 20px/26px var(--rtd-heading-fontfamily); - ACOI newsitem h2/h3 */
  --rtd-h4-font:             18px/24px var(--rtd-heading-fontfamily);

  --rtd-table-heading-font:  17px/26px var(--rtd-heading-fontfamily);


  --rtd-widget-heading-font:   var(--rtd-h1-font);
  --rtd-widget-heading-margin: 25px;



  /* contentarea layout */
  --rtd-spacing-paragraph:                30px;
  --rtd-spacing-intro-after:              30px;
  --rtd-spacing-h2-after:                 15px;
  --rtd-spacing-h3-after:                 10px;
  --rtd-spacing-h4-after:                  5px;
  --rtd-spacing-widget-after:             32px;

  --rtd-spacing-widget-with-title-before: 65px;
/*  --rtd-spacing-widget-opaque-before:     65px;*/
/*  --rtd-spacing-widget-opaque-after:      65px;*/
  --rtd-spacing-widget-opaque-before:     32px;
  --rtd-spacing-widget-opaque-after:      32px;

/*  text-decoration-thickness: 2px;*/
  text-underline-offset: 3px;
}

@media (max-width: 780px)
{
  html
  {
    --contentpanels-radius-large:    8px;
    --contentpanels-radius-medium:   8px;
    --contentpanels-radius-small:    8px;
  }
}



/* FIXME:  */
.wh-form
{
  --rtd-h1-font:             44px/58px var(--rtd-heading-fontfamily); /* regular instead of bold */
  --rtd-intro-font:          19px/30px var(--rtd-heading-fontfamily); /* 19px instead of 20px */
}


@media (max-width: 600px)
{
  html
  {
    /* Paragraphs */
    --rtd-text-font:           16px/24px var(--rtd-text-fontfamily);
    --rtd-text-compact-font:   16px/22px var(--rtd-text-fontfamily); /* smaller line-height */

    --rtd-table-font:          var(--rtd-text-font);
    --rtd-text-subtle-font:    var(--rtd-text-font);
    --rtd-text-small-font:     13px/20px var(--rtd-text-fontfamily); /* also used for image captions */

    --pageheader-credits-font: 14px/20px var(--rtd-text-fontfamily); /* was called --rtd-footnote-font) before

    /* Headings & intro */
    --rtd-h1-font:             23px/30px var(--rtd-heading-fontfamily);
    --rtd-h1-fontsize:         23px; /* for use in clamp() */
    --rtd-intro-font:          15px/24px var(--rtd-heading-fontfamily);
    --rtd-h2-font:             19px/28px var(--rtd-heading-fontfamily);
    --rtd-h2-scaling-font:     clamp(19px,2vw,28px)/125% var(--rtd-heading-fontfamily);
  /*  --rtd-h2-smaller-font:    bold 22px/32px var(--rtd-heading-fontfamily);*/
    --rtd-h3-font:             17px/26px var(--rtd-heading-fontfamily);
/*  --rtd-h3-compact-font:    bold 20px/26px var(--rtd-heading-fontfamily); - ACOI newsitem h2/h3 */
    --rtd-h4-font:             15px/24px var(--rtd-heading-fontfamily);

    --rtd-table-heading-font:  17px/26px var(--rtd-heading-fontfamily);


    /* In mobile layout we use the H4 size instead of H3 */
    --accordion-header-font:   var(--rtd-h4-font);

    --rtd-widget-heading-margin: 15px;


    /* contentarea layout */
    --rtd-spacing-paragraph:                15px;
    --rtd-spacing-intro-after:              15px;
    --rtd-spacing-h2-after:                 15px;
    --rtd-spacing-widget-after:             32px;

    --rtd-spacing-widget-with-title-before: 32px;
    --rtd-spacing-widget-opaque-before:     32px;
    --rtd-spacing-widget-opaque-after:      32px;
  }

  .widget-button + .widget-button
  {
    margin-top: 15px;
  }
}

