/* Anchor for positioning (sticky item within the grid) */
.skiplinksbutton__wrapper
{
  z-index: 2000;

  grid-row: 1;
/*  grid-column: var(--pagegrid-col-verywide);*/
  grid-column: var(--site-menubar-grid-column);
  justify-self: start;

/*  contain: size;*/
}

.skiplinks-buttonbar
{
  position: relative;
  z-index: 1000;
}

@media (min-width: 601px)
{
  .skiplinksbutton__wrapper
  {
    position: sticky;
    left:    15px;
    top:     18px;
  }

  .skiplinks-buttonbar
  {
/*    margin-left: -15px;*/
    display: flex;
    column-gap: 15px;

    background-color: rgba(0,0,0,0.08);
    padding: 10px 15px;
    border-radius: 33px;

    outline: 3px solid #FFFFFF;
  }
}


@media (max-width: 600px)
{
  .skiplinksbutton__wrapper
  {
    position: sticky;
    left: 10px;
    top: 10px;
  }

  .skiplinks-buttonbar
  {
    display: flex;
    column-gap: 10px;
    justify-content: space-between;

    background-color: rgba(0,0,0,0.08);
    padding: 10px 15px;
    border-radius: 30px;

    outline: 3px solid #FFFFFF;
  }
}




.skiplinksbutton__wrapper
{
  /* be hidden, but be available for focus by keyboard */
  opacity: 0; /* visibility: hidden; would remove it from the tab navigation */
  pointer-events: none;
}

  .skiplinksbutton__wrapper:focus-within
, .skiplinksbutton__wrapper:hover /* in Safari the button shortly loses focus upon click, so prevent it from disappearing before the click lands */
{
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}


a.skiplinksbutton
{
  border: 0;
  appearance: none;

  flex: none;

  z-index: 100;

  display: flex;
  width: min-content;
  align-items: center;

  white-space: nowrap;

/*
  / * decorative * /
  padding: 0 16px;
  min-height: 44px;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 16px;
*/
}

/*
  a.skiplinksbutton:focus
, a.skiplinksbutton:hover // in Safari the button shortly loses focus upon click, so prevent it from disappearing before the click lands
{
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

body:has(a.skiplinksbutton:focus) .header-menubar__identity
{
  opacity: 0;
}
*/

body:has(.skiplinksbutton__wrapper:focus-within) .header-menubar__identity
{
  opacity: 0;
}
