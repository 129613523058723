.widget-ctabar
{
  grid-column: var(--pagegrid-col-verywide);

  /* outer layout */
  display:        flex;
  align-items:    center;

  flex-wrap: wrap;
  row-gap: 20px;

  padding-top:    18px;
  padding-bottom: 18px;
  padding-left:   var(--contentpanels-padleft);
  padding-right:  var(--contentpanels-padright);
  border-radius:  var(--contentpanels-radius-medium);

  background-color: #0A66C2;
  color: #FFFFFF;


  --rtd-text-color: #FFFFFF;
/*  --rtd-text-font: var(--rtd-text-subtle-font);*/
  --rtd-text-font: 16px/24px var(--rtd-heading-fontfamily);

  text-decoration: none;
}

.widget-ctabar::before
{
  content: var(--fa-chevron-right);
  font:    var(--fontawesome-solid);
  font-size: 19px;
  margin-right: 15px;
}

@media (max-width: 599px)
{
  .widget-ctabar
  {
    display: grid;
    padding-bottom: 26px;
  }

  .widget-ctabar::before
  {
    grid-column: 1;
    grid-row: 1;
    align-self: baseline;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background-color: #FFFFFF;
    color: var(--color-blue1);
    border-radius: 50%;

    font: var(--fontawesome-regular);
    font-size: 13px;
  }

  .widget-ctabar__text
  {
    grid-column: 2;
    grid-row: 1;
  }

  .widget-ctabar__logo
  {
    grid-column: 2;
    grid-row: 2;
  }

  .widget-ctabar__logo
  {
    height: 29px;
  }
}

@media (min-width: 600px)
{
  .widget-ctabar__text
  {
    max-width: 80%;
    margin-right: auto;
  }

  .widget-ctabar__logo
  {
    margin-left: 30px;
    height: 29px;
  }
}
