.header-menubar-background {
  border-bottom: 1px solid var(--color-purple3);
}

html {
  /* NEW settings (SU + ACOi/ZVI) */
  --sidebar-menu-itemhover-background: transparent;
  --sidebar-menu-itemhover-textcolor: var(--color-purple);
  --sidebar-menu-itemhover-textdecoration: underline;
  --site-searchpanel-background: var(--color-purple1);
  --site-menubar-grid-column: var(--pagegrid-col-verywide-outsidegutter-text);
  --site-menubar-logo-width: 142px;
  --site-menubar-logo-height: clamp(32px, 8vw, 67px);
  --site-menubar-icon-color: var(--color-theme);
  --site-menubar-icon-size: 24px;
  --site-menubar-button-padding: 7px 7px 9px 7px; /* to increate the hitarea */
  --menubar-level1-font: 18px var(--rtd-heading-fontfamily);
  --menubar-level1-padding-left: 15px;
  --menubar-level1-padding-right: 15px;
  --menubar-level1-spacing: 0px;
  --menubar-level1-spacing-min: 0px;
  --menubar-bar-item-borderadius: 8px;
  --menubar-bar-item-color: var(--color-theme);
  --menubar-bar-item-decoration: none; /* underline 3px transparent; */
  --menubar-bar-item-hover-background: var(--color-purple3);
  --menubar-bar-item-hover-color: var(--color-theme);
  --menubar-bar-item-hover-decoration: none; /* underline 3px var(--color-salmon); */
  --menubar-bar-toggler-width: 22px;
  --menubar-bar-toggler-margin: 5px;
  --menubar-bar-toggler-extrahitarea: 9px;
  --menubar-bar-toggler-shifty: 6px;
  --menubar-bar-toggler-font: var(--fontawesome-solid);
  --menubar-bar-toggler-size: 12px;
  --menubar-bar-toggler-content: var(--fa-chevron-down);
  --menubar-bar-toggler-color: #333333;
  --menubar-bar-toggler-hover-color: var(--color-theme);
  --menubar-pulldown-zindex: 10;
  --menubar-pulldown-minwidth: 250px;
  --menubar-pulldown-maxwidth: 320px;
  --menubar-pulldown-anim-open: menuanim_fadein 0.2s;
  --menubar-pulldown-border: none;
  --menubar-pulldown-border-radius: 8px;
  --menubar-pulldown-padding: 15px 0 15px 0;
  --menubar-pulldown-bgcolor: #FFFFFF;
  --menubar-pulldown-box-shadow: var(--contentpanels-box-shadow);
  /*
  --menubar-pulldown-itemhover-bgcolor:   var(--common-linkblock-hover);
  --menubar-pulldown-itemhover-textcolor: #000000;
  --menubar-pulldown-itemhover-textdecoration: underline;
  */
  --menubar-pulldown-itemhover-bgcolor: transparent;
  --menubar-pulldown-itemhover-textcolor: var(--color-theme);
  --menubar-pulldown-itemhover-textdecoration: underline;
  --menubar-level2-color: #333333;
  --menubar-level2-font: 14px/19px var(--rtd-heading-fontfamily);
  --menubar-level2-padding-left: 19px;
  --menubar-level2-padding-right: 15px;
  --menubar-level2-padding-top: 7px;
  --menubar-level2-padding-bottom: 7px;
  --menubar-level2-expandicon: var(--fa-chevron-right);
  --menubar-level2-collapseicon: var(--fa-chevron-down);
  --menubar-level2-toggle-font: 400 14px "Font Awesome 5 Pro"; /* solid */
  --menubar-level3-color: #333333;
  --menubar-level3-font: 14px/19px var(--rtd-heading-fontfamily);
  --menubar-level3-padding-left: 36px;
  --menubar-level3-padding-right: 15px;
  --menubar-level3-padding-top: 7px;
  --menubar-level3-padding-bottom: 7px;
  --menubar-level3-expandicon: var(--fa-plus);
  --menubar-level3-collapseicon: var(--fa-minus);
  --menubar-level3-toggle-font: 400 14px "Font Awesome 5 Pro"; /* regular */
  --menubar-level4-color: var(--color-theme);
  --menubar-level4-font: 13px/17px var(--rtd-heading-fontfamily);
  --menubar-level4-padding-left: 42px;
  --menubar-level4-padding-right: 15px;
  --menubar-level4-padding-top: 5px;
  --menubar-level4-padding-bottom: 5px;
}

/*

.spc-menubar__currentpagebranch > .spc-menubar__item
  Would automatically also have .spc-menubar__item--selected

*/
.spc-menubar__level2 .spc-menubar__currentpagebranch > .spc-menubar__item > .spc-menubar__item__title {
  border-left: 6px solid var(--color-purple2);
  padding-left: calc(var(--menubar-level2-padding-left) - 6px);
}

.spc-menubar__currentpagebranch .spc-menubar__level3 .spc-menubar__item__title {
  border-left: 6px solid var(--color-purple3);
  padding-left: calc(var(--menubar-level3-padding-left) - 6px);
}

.spc-menubar__pulldown .spc-menubar__item__togglebutton.spc-menubar__item__togglebutton {
  order: 10;
  position: absolute;
  left: auto;
  right: 12px;
}

.spc-menubar__pulldown {
  margin-top: 0;
  margin-left: -15px;
  text-underline-offset: 3px;
}

.spc-menubar__pulldown .spc-menubar__level2 {
  margin-top: 10px;
}

.spc-menubar__pulldown .spc-menubar__item__toggle {
  position: absolute;
  left: 8px;
}

.spc-menubar > ul > li > .spc-menubar__item > .spc-menubar__item__title {
  /* Any block type -- for example: block, flow-root, inline, inline-block, inline-table */
  display: block;
  /* We cannot use flex or grid, otherwise we cannot disable the text-decoration for the ::after */
  padding-top: 6px;
  padding-bottom: 6px;
}

.spc-menubar > ul > li.spc-menubar__currentpagebranch > .spc-menubar__item a {
  text-decoration-color: var(--color-salmon);
}

/*
a.spc-menubar__item--currentpage.spc-menubar__item--currentpage.spc-menubar__item--currentpage.spc-menubar__item--currentpage
{
  maybe we want some styling to indicate on which page we currently are?
}
*/
.spc-menubar__item--hassubitems.spc-menubar__item--hassubitems:hover > a,
.spc-menubar__item--hassubitems.spc-menubar__item--hassubitems:hover > .spc-menubar__item a {
  position: relative;
  z-index: 99;
}

.header-menubar__showsearchpanel.header-menubar__showsearchpanel {
  font-size: 0;
}

.header-menubar__showsearchpanel.header-menubar__showsearchpanel::before {
  margin-right: 0;
}