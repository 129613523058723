/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*

- Used for CAK demo

*/
.pageheader--textinset-cta + .page__body > .page__contentarea > .page-contentstart + *,
.pageheader--textinset-cta + .page__body > .page__contentarea > .deeplinks-wrapper {
  margin-top: var(--header-textinset-cta-margin-to-content);
}

.pageheader--textinset-cta {
  --header-imagestickingout-image-coverheader-amount: 87px;
  margin-top: var(--header-textinset-cta-margin-above);
  /* When we use the full contentarea width, the header must also align to this width.
     This means the image will use the full contentarea width and credits
     will be shown below the image.
  */
  /* When content is centered whe'll use the default (text)content width
     of that page to align the title and image to.
     The credits will be positioned at the right in the area at the right of
     the current pages default content area.
  */
}
.pageheader--textinset-cta.pageheader {
  /*
  --rtd-heading-color: #FFFFFF;
  --rtd-text-color: #FFFFFF;
  --rtd-link-color: #FFFFFF;
  */
}
.pageheader--textinset-cta:focus .pageheader__imagewrapper {
  outline: var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);
}
.pageheader--textinset-cta .pageheader__text {
  color: var(--rtd-text-color);
}
.pageheader--textinset-cta .pageheader__when {
  font: var(--header-imagestickingout-pagedate-font);
  margin-bottom: var(--header-imagestickingout-pagedate-spacing);
}
.pageheader--textinset-cta .pageheader__when::before {
  font: var(--header-when-icon-font);
  content: var(--header-when-icon-content);
  margin-right: 10px;
}
.pageheader--textinset-cta .pageheader__title {
  font: var(--header-textinset-cta-title-font);
}
.pageheader--textinset-cta .pageheader__source {
  font: var(--header-imagestickingout-source-font);
}
.pageheader--textinset-cta .pageheader__imagecredits {
  font: var(--pageheader-credits-font);
}
@media screen {
  .pageheader--textinset-cta.pageheader {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .pageheader--textinset-cta .pageheader__imagewrapper {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 1/span 2;
    background: var(--currenttheme-color);
    border-radius: var(--contentpanels-radius-outsidegutter);
  }
  .pageheader--textinset-cta .pageheader__image {
    border-radius: var(--contentpanels-radius-outsidegutter);
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .pageheader--textinset-cta .pageheader__text {
    margin-top: 44px;
    margin-bottom: 56px;
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 1/span 1;
    justify-self: end;
    max-width: 800px;
    background: var(--contentpanels-background);
    border-radius: var(--contentpanels-radius);
    box-shadow: var(--common-itemblocks-box-shadow);
    padding: var(--common-itemblocks-padding);
    z-index: 0;
    --rtd-spacing-paragraph: 15px;
  }
  .pageheader--textinset-cta .pageheader__quicklinks {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 2/span 2;
  }
}
@media (max-width: 767px) {
  .pageheader--textinset-cta .pageheader__quicklinks {
    display: none;
  }
}
@media (min-width: 768px) {
  .pageheader--textinset-cta.pageheader {
    grid-template-rows: min-content var(--header-imagestickingout-image-coverheader-amount) min-content;
  }
  .pageheader--textinset-cta .pageheader__imagewrapper {
    min-height: 237px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .pageheader--textinset-cta .pageheader__quicklinks {
    box-shadow: var(--contentpanels-box-shadow);
    border-radius: var(--contentpanels-radius);
    background-color: #FFE252;
    overflow: hidden;
    column-count: 2;
    padding: 15px 25px;
  }
  .pageheader--textinset-cta .pageheader__quicklinks a {
    display: block;
    text-decoration: none;
    color: var(--color-theme);
    padding: 4px 8px;
    break-inside: avoid; /* don't move the padding-bottom to the next column */
  }
  .pageheader--textinset-cta .pageheader__quicklinks a .line + .line::before {
    content: " ";
  }
  .pageheader--textinset-cta .pageheader__quicklinks a br {
    display: none;
  }
  .pageheader--textinset-cta .pageheader__quicklinks a:hover {
    background-color: var(--color-theme);
    color: #FFFFFF;
  }
}
@media (min-width: 1200px) {
  .pageheader--textinset-cta .pageheader__quicklinks {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 2/span 2;
    box-shadow: var(--contentpanels-box-shadow);
    border-radius: var(--contentpanels-radius);
    background-color: #FFE252;
    overflow: hidden;
    height: 170px;
    display: flex;
    flex-wrap: wrap;
  }
  .pageheader--textinset-cta .pageheader__quicklinks a {
    flex: 1 1 0%;
    white-space: nowrap; /* the site owner determines where to wrap */
    min-width: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font: var(--rtd-h3-font);
    color: var(--rtd-heading-color);
    text-decoration: none;
    transition: background-color 0.1s;
    padding: 20px 4%;
  }
  .pageheader--textinset-cta .pageheader__quicklinks a .line {
    display: block;
  }
  .pageheader--textinset-cta .pageheader__quicklinks a:last-child {
    background-color: var(--color-theme);
    color: #FFFFFF;
    font-size: 25px;
  }
  .pageheader--textinset-cta .pageheader__quicklinks a:hover span {
    transform: scale(1);
    transition: transform 0.25s;
  }
  .pageheader--textinset-cta .pageheader__quicklinks a:hover {
    background-color: var(--color-theme);
    color: #FFFFFF;
  }
  .pageheader--textinset-cta .pageheader__quicklinks a:hover span {
    transform: scale(1.15);
  }
  .pageheader--textinset-cta .divider {
    flex: none;
    margin-top: 23px;
    margin-bottom: 23px;
    width: 1px;
    background-color: rgba(84, 10, 81, 0.37);
  }
}
@media screen and (max-width: 1023px) {
  .pageheader--textinset-cta .pageheader__imagecredits {
    margin-top: 8px;
    grid-column: var(--pagegrid-col-default);
    grid-row: 4;
    color: var(--common-caption-color);
    /* Take out of normal left-to-right reading flow */
    text-align: right;
  }
}
@media screen and (min-width: 1024px) {
  .pageheader--textinset-cta + .page__body > .page__contentarea .page-contentstart {
    display: block;
    grid-row: 1;
    grid-column: var(--pagegrid-col-narrow);
    aspect-ratio: 3/2;
    aspect-ratio: var(--headerimage-aspectratio);
    max-height: max(250px, 100vh - 323px - 100px);
    margin-bottom: calc(var(--header-imagestickingout-image-coverheader-amount) * -1);
  }
  .pageheader--textinset-cta .page-contentstart, .pageheader--textinset-cta .deeplinks-wrapper {
    grid-row: 1;
  }
  .pageheader--textinset-cta + .page__body > .page__contentarea .page-contentstart + * {
    grid-row: 2;
  }
  .pageheader--textinset-cta .pageheader__imagecredits {
    grid-column: var(--pagegrid-col-narrow-gutter-right);
    grid-row: 2;
    padding: var(--header-imagestickingout-imagecredits-padding);
    color: var(--color-theme-faded);
    align-self: end; /* towards the bottom of the visible page-header panel */
    text-align: right;
  }
}
@media print {
  .pageheader--textinset-cta.pageheader {
    background: transparent;
    --rtd-heading-color: #000000;
    --rtd-text-color: #000000;
    --rtd-link-color: #000000;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .pageheader--textinset-cta .pageheader__background {
    display: none;
  }
  .pageheader--textinset-cta .pageheader__text {
    grid-row: 1;
    grid-column: var(--pagegrid-col-verywide);
    margin-bottom: 0.1cm;
  }
  .pageheader--textinset-cta .pageheader__imagewrapper {
    grid-row: 2;
    grid-column: 1/-1;
  }
  .pageheader--textinset-cta .pageheader__image {
    width: 100%; /* abide to the given width */
    height: 7cm;
    object-fit: cover;
  }
  .pageheader--textinset-cta .pageheader__imagecredits {
    grid-row: 3;
    text-align: right;
    color: var(--common-caption-color);
    font: var(--pageheader-credits-font);
    grid-column: var(--pagegrid-col-verywide);
  }
}