/*
.page--spotlerform .mailplusform
{
}
*/
.page--spotlerform .wh-form__fieldgroup--custom-info {
  margin-top: 60px !important;
  margin-bottom: 0 !important;
}

.widget--spotler {
  background-color: #F5F5F5;
  padding: 25px;
}

.mailplusform label {
  cursor: pointer;
}

.mpFormTable {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mpQuestionTable + .mpQuestionTable {
  margin-top: 20px;
}

.mpQuestionTable {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.mpQuestionTable:focus-within .mpFormLabel {
  color: var(--color-theme);
}

.mpFormLabel {
  flex: 0 0 170px;
  padding-right: 30px;
}

.mpFormField {
  flex: 1 1 0px;
}

.mpFormField > ul {
  margin: 0;
  padding: 0;
}

.mpFormField > input[type=text],
.mpFormField > input[type=email] {
  width: 100%;
}

.mpFormField li {
  display: flex;
  align-items: center;
}

.mpFormField li input + label {
  flex: none;
  margin-right: 10px;
}

.mpFormField li + li {
  margin-top: 10px;
}

.mpMultipleLabel::after {
  position: absolute;
  left: 0;
}

.mpErrorRow {
  width: 100%;
  padding-left: 100px;
  color: #A00;
}

.mpQuestionTable .mpFormField ul {
  list-style: none;
  margin: 0;
}

.mpLabelRow {
  display: flex;
  width: 100%;
}

.mpLabelRow > .submitCell {
  margin-left: auto;
}

.submitButton {
  margin-top: 30px;
}

.submitButton::before {
  font: var(--fontawesome-solid);
  content: var(--fa-chevron-right);
  font-size: 13px;
  vertical-align: middle;
  margin-right: 15px;
}

@media (max-width: 640px) {
  .mpQuestionTable + .mpQuestionTable {
    margin-top: 18px;
  }
  .mpFormLabel {
    flex: 0 0 100%;
    padding-bottom: 3px;
    font-weight: bold;
  }
}