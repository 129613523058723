/*

"Side by Side" (sidebyside) and "Search" (search) header


- .pageheader__background
  Is used to display the background a little wider than the normal page content.
  We do need to give the textpanel the same background color, just so accessibility checker which aren't smart enough
  to see this is the background don't think the white text is on a white background.


- We give the .pageheader__background and .pageheader__text
  - a margin-top and margin-bottom to give the impression the image sticks out

*/
/* input and compact searchbutton (icon only) connected to eachother */
/* NOTE: use ~, we can have a crumblepath inbetween */
.pageheader--sidebyside ~ .page__body > .page__contentarea {
  --header-to-content-margin: var(--header-sidebyside-margin-to-content);
}

/*
.page__contentarea:has(.pageheader--sidebyside)
{
  --header-to-content-margin:  var(--header-sidebyside-margin-to-content);
}
*/
html.pageheadertype--sidebyside .header-crumblepath {
  border-bottom: 0;
}

@media (max-width: 767px) {
  .pageheader--sidebyside {
    --formcontrols-height: 48px;
    /*
      .spc-search__heading
      {
        display: block;
        width: 100%;
        margin-bottom: 12px;

        font: var(--rtd-h3-font);
        color: var(--rtd-heading-color);
      }
    */
  }
  .pageheader--sidebyside .spc-searchbar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }
  .pageheader--sidebyside .spc-searchbar__input.spc-searchbar__input {
    flex: 1 1 0;
    /* have input and button side-by-side */
    border-radius: var(--header-searchbar-radius);
    margin: 0; /* Safari has some default margins on <input> */
    padding-right: 55px;
  }
  .pageheader--sidebyside .spc-searchbar__searchbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    flex: none;
    border-radius: 0;
    font-size: 0;
    height: var(--formcontrols-height);
    position: absolute;
    right: 0;
    /*
        margin-left: calc(-1 * 55px);

        width: 30px;
        padding-right: 25px;
    */
    color: var(--color-purple1);
    /*
        // @include wh-form-hidenativecontrol;
        position: absolute;
        height: 0;
        width: 0;
        min-height: 0;
        padding: 0;
        margin: 0;
        border: 0;
        overflow: hidden;
    */
  }
  .pageheader--sidebyside .spc-searchbar__searchbutton::after {
    font: var(--fontawesome-regular);
    font-size: 20px;
    content: var(--fa-search);
  }
}
@media (min-width: 768px) {
  .pageheader--sidebyside {
    --formcontrols-height: 58px;
  }
  .pageheader--sidebyside .spc-searchbar {
    grid-column: 1/-1;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    --formcontrols-height: 58px;
  }
  .pageheader--sidebyside .spc-search__heading {
    display: none;
  }
  .pageheader--sidebyside .spc-searchbar__input.spc-searchbar__input {
    flex: 1 1 0px;
    border-radius: 6px;
    margin: 0; /* Safari has a default margin for <input> */
  }
  .pageheader--sidebyside .spc-searchbar__searchbutton {
    margin-left: 13px;
  }
  .pageheader--sidebyside .spc-searchbar__searchbutton::after {
    vertical-align: middle;
    font: var(--fontawesome-regular);
    font-size: 17px;
    content: var(--fa-search);
    margin-left: 30px;
  }
}
.pageheader--sidebyside .spc-eventtile__specsheet {
  color: var(--currenttheme-text-color);
}
.pageheader--sidebyside .spc-eventtile__specsheet .icon {
  color: var(--color-theme);
}

.pageheader--withfiltertoggle .pageheader__text {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 30px;
}

.pageheader--withfiltertoggle .pageheader__text > * {
  flex: 0 0 100%;
}

.pageheader--withfiltertoggle .pageheader__text > .pageheader__togglefilterbutton {
  flex: none;
}

.pageheader--sidebyside {
  --formcontrols-focus-boxshadow: 0 0 0 3px #FFFFFF70;
  --formcontrols-focus-outline: 2px solid #FFFFFF70;
  --header-background: var(--currenttheme-background);
  --header-togglefilter-textcolor: #FFFFFF;
  /*
  NOTE: Most website will use this news icon

    .pageheader__when::before
    {
      font: var(--header-when-icon-font);
      content: var(--header-when-icon-content);

      margin-right: 10px;
    }
  */
  /*
    .spc-searchbar__searchbutton
    {
      --button-color: var(--color-salmon);
    }
  */
  /*
  .pageheader__backlink
  {
    color: var(--rtd-text-color);
    text-decoration: none;
  }

  .pageheader__backlink:hover
  {
    text-decoration: underline;
  }

  .pageheader__backlink::before
  {
    display: inline-block; / * to prevent inheriting the underline on hover * /

    font: var(--icon-link-font);
    content: var(--icon-backlink-content);

    margin-right: 20px;
  }
  */
  /*
  Mobile layout:
  - title at top
  - image after title
  - description and filter toggle at the bottom
  */
  /*
  @media print
  {
    &.pageheader
    {
      background: transparent;
      --rtd-heading-color: #000000;
      --rtd-text-color: #000000;
      --rtd-link-color: #000000;

      display: grid;
      grid-template-columns: var(--pagegrid-template-columns);
    }

    .pageheader__background
    {
      display: none;
    }

    .pageheader__text
    {
      grid-row: 1;
      grid-column: var(--pagegrid-col-verywide);

      margin-bottom: 0.1cm;
    }

    .pageheader__imagewrapper
    {
      grid-row: 2;
      grid-column: 1 / -1;
    }

    .pageheader__image
    {
      width: 100%; / * abide to the given width * /
      // aspect-ratio: 16 / 9;
      height: 7cm;
      object-fit: cover;
    }

    .pageheader__imagecredits
    {
      grid-row: 3;
      text-align: right;

      color: var(--common-caption-color);
      font: var(--pageheader-credits-font);

      grid-column: var(--pagegrid-col-verywide);
    }
  }
  */
}
.pageheader--sidebyside .pageheader__tags {
  margin-bottom: 22px;
}
.pageheader--sidebyside .pageheader__when {
  color: var(--header-sidebyside-pagedate-color);
  font: var(--header-sidebyside-pagedate-font);
  margin-bottom: var(--header-sidebyside-pagedate-spacing);
}
.pageheader--sidebyside .pageheader__when__prefix {
  font-weight: bold;
  margin-right: 10px;
}
.pageheader--sidebyside .pageheader__title {
  color: var(--currenttheme-heading-color);
  font: var(--header-sidebyside-title-font);
  text-wrap: balance;
}
.pageheader--sidebyside .pageheader__subtitle {
  font: var(--header-sidebyside-subtitle-font);
  text-wrap: balance;
}
.pageheader--sidebyside .pageheader__source {
  font: var(--header-sidebyside-source-font);
  color: inherit;
}
.pageheader--sidebyside .pageheader__text > p {
  text-wrap: balance;
}
.pageheader--sidebyside .pageheader__text__specsheet {
  margin-top: 15px;
}
@media screen {
  .pageheader--sidebyside {
    margin-top: var(--header-sidebyside-margin-above);
  }
  .pageheader--sidebyside.pageheader {
    --rtd-heading-color: var(--currenttheme-heading-color);
    --rtd-text-color: var(--currenttheme-text-color);
    --rtd-link-color: var(--currenttheme-text-color);
  }
  .pageheader--sidebyside.pageheader--search {
    --header-background: #ECE4EC;
    --rtd-heading-color: var(--currenttheme-heading-color);
    --rtd-text-color: #000000;
    --rtd-link-color: #000000;
    --header-togglefilter-textcolor: var(--currenttheme-headin);
  }
  .pageheader--sidebyside.pageheader--search .spc-searchbar__searchbutton {
    --button-color: var(--color-purple-dark);
    --button-bordercolor: var(--color-purple-dark);
  }
  .pageheader--sidebyside:focus .pageheader__background {
    outline: var(--formcontrols-focus-outline);
    outline-offset: var(--formcontrols-focus-outline-offset);
  }
  .pageheader--sidebyside .pageheader__text {
    color: var(--rtd-text-color);
  }
}
@media screen and (min-width: 768px) {
  .pageheader--sidebyside .page--newsoverview2 .pageheader__title,
  .pageheader--sidebyside .page--eventsoverview .pageheader__title {
    text-align: center;
  }
  .pageheader--sidebyside .pageheader--withfiltertoggle .pageheader__text > .pageheader__togglefilterbutton {
    margin-left: auto;
  }
  .pageheader--sidebyside.pageheader {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .pageheader--sidebyside .pageheader__background {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 1/span 2;
    background: var(--header-background);
    border-radius: var(--header-sidebyside-border-radius);
  }
  .pageheader--sidebyside .pageheader__title {
    margin-top: -4px; /* was -13px -- compensate for the whitespace above our text */
  }
  .pageheader--sidebyside .spc-searchbar__searchbutton {
    margin-left: 24px;
  }
  .pageheader--sidebyside .spc-search__heading {
    display: block; /* always visible */
    width: 100%;
    font: bold 17px var(--rtd-heading-fontfamily);
    color: var(--color-purple-dark);
    padding-bottom: 4px;
  }
  .pageheader--sidebyside.pageheader--withimage {
    /* shift so it connects to the visual header bar (from which the image protrudes) */
    /* .pageheader--sidebyside.pageheader--withimage */
  }
  .pageheader--sidebyside.pageheader--withimage .pageheader__background {
    /* keep out of the area of the grid where the image sticks out */
    margin-top: var(--header-sidebyside-image-protrude-amount);
    margin-bottom: var(--header-sidebyside-image-protrude-amount);
  }
  .pageheader--sidebyside.pageheader--withimage .pageheader__text {
    grid-column: var(--header-sidebyside-text-grid-column);
    padding-left: var(--header-sidebyside-text-padside);
    padding-right: var(--header-sidebyside-text-marginbetween);
    /* keep out of the area of the grid where the image sticks out */
    margin-top: var(--header-sidebyside-image-protrude-amount);
    margin-bottom: var(--header-sidebyside-image-protrude-amount);
  }
  .pageheader--sidebyside.pageheader--withimage .pageheader__imagewrapper {
    grid-column: var(--header-sidebyside-image-grid-column);
    grid-row: 1;
  }
  .pageheader--sidebyside.pageheader--withimage .pageheader__imagecredits {
    grid-column: var(--header-sidebyside-image-grid-column);
    grid-row: 3;
    margin-top: 5px;
    justify-self: end; /* at the right */
    color: var(--common-caption-color);
    font: var(--pageheader-credits-font);
  }
  .pageheader--sidebyside.pageheader--withimage + .page__body > .pagetabs:first-child {
    margin-top: calc(var(--header-sidebyside-image-protrude-amount) * -1);
    z-index: -1;
  }
  .pageheader--sidebyside.pageheader--noimage {
    /*
    .pageheader__text
    {
      grid-column: var(--pagegrid-col-verywide);
    }
    */
  }
  .pageheader--sidebyside.pageheader--noimage .pageheader__title, .pageheader--sidebyside.pageheader--noimage .pageheader__text {
    grid-column: var(--pagegrid-col-default);
  }
  .page--newsoverview .pageheader--sidebyside.pageheader--noimage .pageheader__title, .page--newsoverview .pageheader--sidebyside.pageheader--noimage .pageheader__text {
    grid-column: var(--pagegrid-col-verywide);
  }
  .pageheader--sidebyside .pageheader__text {
    grid-row: 1;
    align-self: var(--header-sidebyside-text-valign);
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .pageheader--sidebyside .pageheader__image {
    border-radius: var(--pageheader-photo-border-radius);
    display: block;
    width: 100%;
    height: auto;
    min-height: 100%;
    aspect-ratio: var(--header-sidebyside-image-aspectratio);
    object-fit: cover; /* we want the image to maintain it's aspect ratio but be cut off in the 16/9 */
    box-shadow: var(--pageheader-photo-box-shadow);
  }
  .pageheader--sidebyside * + .pageheader__searchpanel {
    margin-top: 25px;
  }
  .pageheader--sidebyside .pageheader__togglefilterbutton {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .pageheader--sidebyside {
    /* Image fullwidth */
    /* Image inset (header background around the image) *****************
    &.pageheader
    {
      padding-bottom: 30px;
    }

    .pageheader__image
    {
      // border-radius: var(--contentpanels-radius);
    }
    /*************************/
    /*
    .pageheader__when
    .pageheader__title
    .pageheader__imagewrapper
    .pageheader__text__description
    .pageheader__togglefilterbutton
    */
  }
  .pageheader--sidebyside.pageheader {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: min-content min-content min-content min-content;
    background: var(--header-background);
    padding-top: 25px;
    --header-sidebyside-image-aspectratio: 16 / 9;
  }
  .pageheader--sidebyside.pageheader--withimage .pageheader__text {
    padding-top: 25px;
  }
  .pageheader--sidebyside .pageheader__title {
    grid-row: 1;
  }
  .pageheader--sidebyside .pageheader__imagewrapper {
    grid-row: 2;
  }
  .pageheader--sidebyside .pageheader__imagewrapper {
    margin-top: 15px;
  }
  .pageheader--sidebyside .pageheader__text > *,
  .pageheader--sidebyside .pageheader__imagewrapper {
    grid-column: var(--pagegrid-col-verywide);
  }
  .pageheader--sidebyside .pageheader__text__specsheet {
    margin: 0;
    margin-bottom: 20px;
  }
  .pageheader--sidebyside .pageheader__text__description {
    padding-bottom: 25px;
  }
  .pageheader--sidebyside .pageheader__text__description:empty {
    display: none;
  }
  .pageheader--sidebyside .pageheader__imagewrapper {
    grid-column: var(--pagegrid-col-fullwidth);
  }
  .pageheader--sidebyside.pageheader--search {
    padding-top: 18px;
    padding-bottom: 20px;
  }
  .pageheader--sidebyside .pageheader__background {
    display: none;
  }
  .pageheader--sidebyside .pageheader__text {
    display: contents;
  }
  .pageheader--sidebyside .pageheader__image {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: var(--header-sidebyside-image-aspectratio);
    object-fit: cover; /* we want the image to maintain it's aspect ratio but be cut off in the 16/9 */
    /*
    We cannot use min-height: 100%;
    because Safari will then make this use min 100% of the grid height instead
    of the height of the grid-row we are placed into.
    */
    min-height: 100%;
    box-shadow: var(--pageheader-photo-box-shadow);
  }
  .pageheader--sidebyside .pageheader__imagecredits {
    display: none;
  }
  .pageheader--sidebyside * + .pageheader__searchpanel {
    margin-top: 20px;
  }
  .pageheader--sidebyside .spc-search__heading {
    font-size: 16px;
    margin-bottom: 6px;
  }
}