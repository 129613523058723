input[type=text],
input[type=url],
input[type=email],
input[type=password],
input[type=number],
input[type=date],
input[type=time],
input[type=search],
.wh-form__dateinputgroup,
.wh-form__timeinputgroup,
textarea,
select:not(.wh-rtd__toolbarstyle),
.multiselect__panel__trigger {
  padding-left: var(--textcontrols_padleft);
  padding-right: var(--textcontrols_padright);
  font: var(--textcontrols-font);
  height: var(--formcontrols-height);
  border: var(--formcontrols-border-width) solid var(--formcontrols-border-color);
  border-radius: var(--formcontrols-radius);
  background-color: var(--textcontrols-bgcolor);
  color: var(--textcontrols_textcolor);
}
input[type=text]:focus,
input[type=url]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=date]:focus,
input[type=time]:focus,
input[type=search]:focus,
.wh-form__dateinputgroup:focus,
.wh-form__timeinputgroup:focus,
textarea:focus,
select:not(.wh-rtd__toolbarstyle):focus,
.multiselect__panel__trigger:focus {
  /*@mixin textfield-focusstyling*/
  border-color: var(--formcontrols-border-color-focus);
  border-width: var(--formcontrols-border-width-focus);
  /* shrink padding to prevent the content shifting in case the border width changes for the focus styling */
  padding-left: calc(var(--textcontrols_padleft) + var(--formcontrols-border-width) - var(--formcontrols-border-width-focus));
  padding-right: calc(var(--textcontrols_padright) + var(--formcontrols-border-width) - var(--formcontrols-border-width-focus));
  outline: 0;
  box-shadow: var(--formcontrols-focus-boxshadow) !important;
}
input[type=text]:disabled, input[type=text][data-wh-form-disabled], input[type=text].wh-form__dateinputgroup--disabled, input[type=text].wh-form__timeinputgroup--disabled,
input[type=url]:disabled,
input[type=url][data-wh-form-disabled],
input[type=url].wh-form__dateinputgroup--disabled,
input[type=url].wh-form__timeinputgroup--disabled,
input[type=email]:disabled,
input[type=email][data-wh-form-disabled],
input[type=email].wh-form__dateinputgroup--disabled,
input[type=email].wh-form__timeinputgroup--disabled,
input[type=password]:disabled,
input[type=password][data-wh-form-disabled],
input[type=password].wh-form__dateinputgroup--disabled,
input[type=password].wh-form__timeinputgroup--disabled,
input[type=number]:disabled,
input[type=number][data-wh-form-disabled],
input[type=number].wh-form__dateinputgroup--disabled,
input[type=number].wh-form__timeinputgroup--disabled,
input[type=date]:disabled,
input[type=date][data-wh-form-disabled],
input[type=date].wh-form__dateinputgroup--disabled,
input[type=date].wh-form__timeinputgroup--disabled,
input[type=time]:disabled,
input[type=time][data-wh-form-disabled],
input[type=time].wh-form__dateinputgroup--disabled,
input[type=time].wh-form__timeinputgroup--disabled,
input[type=search]:disabled,
input[type=search][data-wh-form-disabled],
input[type=search].wh-form__dateinputgroup--disabled,
input[type=search].wh-form__timeinputgroup--disabled,
.wh-form__dateinputgroup:disabled,
.wh-form__dateinputgroup[data-wh-form-disabled],
.wh-form__dateinputgroup.wh-form__dateinputgroup--disabled,
.wh-form__dateinputgroup.wh-form__timeinputgroup--disabled,
.wh-form__timeinputgroup:disabled,
.wh-form__timeinputgroup[data-wh-form-disabled],
.wh-form__timeinputgroup.wh-form__dateinputgroup--disabled,
.wh-form__timeinputgroup.wh-form__timeinputgroup--disabled,
textarea:disabled,
textarea[data-wh-form-disabled],
textarea.wh-form__dateinputgroup--disabled,
textarea.wh-form__timeinputgroup--disabled,
select:not(.wh-rtd__toolbarstyle):disabled,
select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
select:not(.wh-rtd__toolbarstyle).wh-form__dateinputgroup--disabled,
select:not(.wh-rtd__toolbarstyle).wh-form__timeinputgroup--disabled,
.multiselect__panel__trigger:disabled,
.multiselect__panel__trigger[data-wh-form-disabled],
.multiselect__panel__trigger.wh-form__dateinputgroup--disabled,
.multiselect__panel__trigger.wh-form__timeinputgroup--disabled {
  color: var(--textcontrols_disabled_textcolor);
  border-color: var(--formcontrols-border-color-disabled) !important;
  background-color: var(--textcontrols_disabled_bgcolor);
  box-shadow: none;
}

select:not(.wh-rtd__toolbarstyle) {
  --textcontrols_padright: 40px;
}

textarea {
  flex: 1 0 0px;
  padding-right: var(--textcontrols_padleft);
}