.widget-image__image
{
  display: block;
  width: 100%;

  border-radius: var(--widget-imagewithcaption-caption-radius);
}

.widget-image__caption
{
  margin-top: var(--widget-imagewithcaption-caption-margintoimage);

  /* Caption has richtext content */
  --rtd-text-color: var(--widget-imagewithcaption-caption-color);
  --rtd-text-font:  var(--widget-imagewithcaption-caption-font);

  /*
  display: flex;
  align-items: baseline;
  */
}

/*
.widget-image__caption::before
{
  font: var(--fontawesome-solid);
  font-size: 16px;
  content: var(--fa-image);
  margin-right: 11px;
}
*/
