/*
Safari double-border bug:
https://bugs.webkit.org/show_bug.cgi?id=243167
*/
.spc-tag-category--issuer {
  --component-tag-font: 17px var(--rtd-text-fontfamily);
}

.fwinstrument__tagslist + * {
  margin-top: 5px;
}

.page--financielewegwijzer-instrument .page__metadata hr {
  display: none;
}

.fwinstrument__specsheet {
  border-collapse: collapse;
  margin-top: 30px;
  font: var(--rtd-table-font);
  --rtd-text-font: var(--rtd-table-font);
  --component-taglist-gap-h: 7px;
  --component-taglist-gap-v: 10px;
}

.fwinstrument__specsheet .rtdcontent a {
  color: var(--rtd-link-color);
  text-decoration: none;
}

.fwinstrument__specsheet a:hover {
  text-decoration: underline;
}

.fwinstrument__specsheet th {
  font-weight: 500;
  text-align: left;
  color: var(--color-theme);
  width: 245px;
  padding-right: 38px;
}

.fwinstrument__specsheet td {
  width: calc(100% - 245px);
}

/* Mobile */
@media (max-width: 500px) {
  table.fwinstrument__specsheet,
  table.fwinstrument__specsheet tbody {
    display: block;
  }
  .fwinstrument__specsheet tr {
    display: block;
    border-top: 1px solid #C5C5C5;
    padding-top: 15px;
    padding-bottom: 17px;
  }
  .fwinstrument__specsheet th {
    margin-bottom: 5px;
  }
  .fwinstrument__specsheet th,
  .fwinstrument__specsheet td {
    display: block;
    width: 100%;
  }
}
/* Desktop */
@media (min-width: 501px) {
  .fwinstrument__specsheet th,
  .fwinstrument__specsheet td {
    border-top: 1px solid #C5C5C5;
    padding-top: 16px;
    padding-bottom: 16px;
    vertical-align: baseline;
    /* All text line related values of vertical-align will
       trigger the double-border bug on a table cell in Safari.
       .... except for -webkit-baseline-middle (I found).
    */
    vertical-align: -webkit-baseline-middle;
  }
  .fwinstrument__specsheet tr:last-child th,
  .fwinstrument__specsheet tr:last-child td {
    border-bottom: 1px solid #C5C5C5;
  }
}