.widget-news
{
  --content-layout:             verywide;
  --content-squeeze-preference: needwide;

  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);

  z-index: var(--zindex-overlap-deeplinks);
}

.widget-news .widget__header
{
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 1;

  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.widget-news__title.widget-news__title
{
  margin-bottom: var(--rtd-widget-heading-margin);
}

.widget-news__morelink
{
/*  font: var(--rtd-text-subtle-font);*/
  font: 17px/24px var(--rtd-heading-fontfamily);
  text-decoration: underline;
}

.widget-news__items
{
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 2;

  --component-spctile-when-font:  15px/24px var(--rtd-heading-fontfamily);
  --component-spctile-title-font: 17px/24px var(--rtd-heading-fontfamily);
  --gridimage-aspectratio: 16 / 9;

  --component-spctile-padding-h: 0;
  --component-spctile-padding-v: 0;
}

@media (max-width: 650px)
{
  .widget-news__items
  {
    --component-spctile-when-font:  14px/24px var(--rtd-heading-fontfamily);
    --component-spctile-title-font: 15px/24px var(--rtd-heading-fontfamily);
  }
  .widget-news__items.spc-tiles--strip .spc-tile
  {
    --gridimage-aspectratio: 16 / 9 !important;
  }
}



.widget-news__items .spc-tile__top
{
  position: relative;
  z-index: 0; /* encapsulate z-index of content within */
  overflow: hidden;

  /* width: var(--component-spctile-image-listview-width); */
  aspect-ratio: var(--gridimage-aspectratio);

  border: var(--component-spctile-image-border);
  border-radius: var(--component-spctile-image-border-radius);
  overflow: hidden;
}

.widget-news__items .spc-tile__image
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  aspect-ratio: var(--gridimage-aspectratio);
  min-height: 100%; /* stretch height beyong our aspect ratio if our available space is more (due to the news-items next to us) */

  background-size: cover;

  transition: transform 0.15s ease-in-out
            , opacity   0.25s;
}

.widget-news__items .spc-tile__meta
{
  word-break: break-word; /* text must always wrap */
}

.widget-news__items .spc-tile
{
  text-decoration: none;
}

.widget-news__items .spc-tile__when
{
  margin-top: 7px;
  color: var(--component-spctile-when-color);
  font: var(--component-spctile-when-font);
}

.widget-news__items .spc-tile__title
{
  color: var(--component-spctile-title-color);
  font:  var(--component-spctile-title-font);
  text-transform: var(--component-spctile-title-text-transform);
}

.widget-news__items .spc-tile:hover .spc-tile__title
{
  text-decoration: underline;
}

.widget-news__items .spc-tile__description
{
  color: var(--component-spctile-text-color);
  font:  var(--component-spctile-text-font);

  --rtd-text-color: var(--component-spctile-text-color);
  --rtd-text-font:  var(--component-spctile-text-font);
}

.widget-news__footer
{
  display: none;
}


/* Mobile */
@media (max-width: 650px)
{
  .widget__header .widget-news__morelink
  {
    display: none;
  }

  .widget-news__items .spc-tile
  {
    display: block;

    --component-spctile-padding-h: 10px;
    --component-spctile-padding-v: 10px;
  }

  /* hide all items starting from the 3th */
  .widget-news__items .spc-tile:nth-child(1n + 3)
  {
    display: none;
  }

  .widget-news__items .spc-tile + .spc-tile
  {
    margin-top: 5px;
  }

  .widget-news__footer
  {
    display: block;
    margin-top: 12px;
    border-top: 1px solid var(--color-purple3);
    padding-top: 12px;

    grid-row: 3;
    grid-column: var(--pagegrid-col-verywide);
  }
}


/* Tablet (two column) */
@media (min-width: 651px) and (max-width: 999px)
{
  .widget-news__items
  {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 30px;
    row-gap: 30px;
  }
}


/* Desktop (three column, highlight top right is 2x2) */
@media (min-width: 1000px)
{
  .widget-news__items .spc-tile.spc-tile
  {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .widget-news__items .spc-tile__top.spc-tile__top
  {
    flex: 1 0 auto;
  }

  .widget-news__items
  {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 30px;
    row-gap: 30px;
  }

  .widget-news__items > *:nth-child(1)
  {
    grid-column: 2 / span 2;
    grid-row: 1 / span 2;
    --component-spctile-when-font:  15px/24px var(--rtd-heading-fontfamily);
    --component-spctile-title-font: 24px/32px var(--rtd-heading-fontfamily);

  }


  .widget-news__items > .spc-tile:nth-child(1n + 4)
  {
    display: flex;
    flex-direction: row;
  }

  .widget-news__items > .spc-tile:nth-child(1n + 4) .spc-tile__top
  {
    flex: none;
    width: 80px;
    height: 80px;
    margin-right: 16px;
  }

  .widget-news__items > .spc-tile:nth-child(1n + 4) .spc-tile__when
  {
    margin-top: -3px;
    /*
    leading-trim: both;
    text-edge: cap alphabetic;
    */
  }
}
