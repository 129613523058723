.widget--podcast
{
  --podcast-pad-h: 15px;
  --podcast-pad-v: 18px;
  --podcast-small-font: 15px/22px var(--rtd-text-fontfamily);

  --podcast-thumbnail-size: 150px;
  --podcast-thumbnail-radius:  var(--contentpanels-radius-small);

  --podcast-slider-height: 12px;
  --podcast-slider-radius: 6px;
  --podcast-slider-background: #D9D9D9;

  --podcast-slider-thumb-size: 20px;
  --podcast-slider-thumb-background: var(--color-theme);
  --podcast-slider-thumb-boxshadow: 0 0 0 10px #5F0EAE24;
/*  --podcast-slider-thumb-boxshadow: 0 0 0 10px rgba(95, 14, 174, 0.14);*/

  /* NOTE: Safari uses bg color #EFEFEF with border #B4B4B4 */
  --podcast-playback-position-background-color: #D9D9D9;
  --podcast-playback-position-border-color:     #949494; /* The border must ensure a 3:1 contrast (#949494 on #FFFFFF is 3:1) */

  --podcast-descriptionpanel-background: #EEE4F2;
  --podcast-descriptionpanel-font: 17px/24px var(--rtd-text-fontfamily);

  --icon-applepodcast-color: var(--color-purple1);
}

.podcastwidget--playing.podcastwidget--playing
{
  /*
  background-color: #F5F5F5;
  border-width: 2px;
  */
}

.podcastwidget__content
{
  position: relative; /* position so we are drawn over the "description"-panel that's moved to be behind us */
  background: #FFFFFF;

  grid-column: var(--pagegrid-col-default);

  border: 1px solid var(--color-theme);
  border-radius: var(--contentpanels-radius-small);

  padding: var(--podcast-pad-v) var(--podcast-pad-h);
}

.podcastwidget__image
{
  flex: none;
  width:  var(--podcast-thumbnail-size);
  height: var(--podcast-thumbnail-size);
  border-radius: var(--podcast-thumbnail-radius);
  margin-right: 20px;
}

/* placeholder */
div.podcastwidget__image
{
  background-color: #F0F0F0;
}


/* Inner layout of the podcast widget for tablet and desktop */
@media (min-width: 768px)
{
  .podcastwidget__content
  {
    display: flex;
  }

  .podcastwidget__rightside
  {
    flex: 1 0 0;

    /* If there's not much text we would like to have our content perfectly
       aligned to the image next to it. */
    display: flex;
    flex-direction: column;
  }

      /* Define the minimum distance */
        .podcastwidget__playback
      , .podcastwidget__resources
      {
        margin-top: 12px;
      }

      /* When the image beside us takes more room than we need
         (for our content and "default" margin) distribute the extra space. */
        .podcastwidget__meta
      , .podcastwidget__playback
      {
        margin-bottom: auto;
      }

      .podcastwidget__resources
      {
        margin-bottom: -8px; /* align the underline of the "view transcript" link to the bottom of the image */
      }

      .podcastwidget__resources:not(.includes-transcript) .podcastwidget__externalproviders
      {
        margin-left: auto;
      }
}


@media (max-width: 767px)
{
  .podcastwidget__content
  {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content min-content min-content;
  }

  .podcastwidget__rightside
  {
    display: contents;
  }

  .podcastwidget__image
  {
    grid-column: 1;
    grid-row: 1 / span 2;
    max-width: 30vw;
    aspect-ratio: 1 / 1;
    height: auto;
  }

  .podcastwidget__meta
  {
    grid-column: 2;
    grid-row: 1;
  }

  .podcastwidget__resources
  {
    grid-column: 2;
    grid-row: 2;
  }

  .podcastwidget__playback
  {
    margin-top: 15px;
    grid-column: 1 / span 2;
    grid-row: 3;
  }
}


@media (max-width: 500px)
{
/*
  .podcastwidget__resources
  {
    grid-column: 1 / span 2;
    grid-row: 4;
  }
*/
  .podcastwidget__resources
  {
    margin-left: 0;
    flex-direction: column;
  }

  .podcastwidget__externalproviders
  {
    grid-column: 1;
    grid-row: 3;
  }

  .podcastwidget__externalproviders span
  {
    display: none;
  }

  .podcastwidget__downloadtranscript
  {
    color: #767676 !important;
  }
}




.podcastwidget__podcasttitle
{
  color: #767676;
}

@media (min-width: 1024px)
{
  /* By restoring the font-size on :first-line,
     we can make the | disappear. (since we prevent the title from wrapping the | will always be the second line)
  */
  .podcastwidget__podcasttitle
  {
    font-size: 0;
  }

      .podcastwidget__podcasttitle .part
      {
        white-space: nowrap;
      }
}



    .podcastwidget__seperator
    {
    }

    .podcastwidget__podcasttitle:first-line
    {
      font: var(--podcast-small-font);
    }


    .podcastwidget__publicationdate
    {
      font: var(--podcast-small-font);

      display: inline-block;
      white-space: nowrap;
    }

.podcastwidget__episodetitle
{
  color: var(--color-theme);
  font: 18px/24px var(--rtd-heading-fontfamily);
}


.podcastwidget__playback
{
  display: flex;
}

    .podcastwidget__playbutton
    {
      border: 0;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      background: var(--color-theme);

      cursor: pointer;

      outline-offset: 4px;

      margin-right: 15px;
    }

    .podcastwidget__playbutton::before
    {
      content: var(--fa-play);
      font: var(--fontawesome-solid);
      font-size: 17px;
      color: #FFFFFF;

      margin-left: 4px;
      margin-top: 1px;
    }

    .podcastwidget--playing .podcastwidget__playbutton::before
    {
      font: var(--fontawesome-solid);
      content: var(--fa-pause);
      font-size: 20px;

      margin-left: 0;
      margin-top: 2px;
     }

    .podcastwidget__playbutton:hover
    {
      background: var(--color-purple2);
    }

    .podcastwidget__playback__time
    {
      flex: 1 0 0;

      align-self: center;
    }

        .podcastwidget__timeline
        {
          margin: 10px 0 0 0;

          display: block; /* otherwise it'll be inline and you'll get the line-height (and whitespace) around it */
          width: 100%;
          height: 12px;
          border-radius: 6px;

          cursor: pointer;

          border-left: 1px solid var(--podcast-playback-position-border-color);
          border-right: 1px solid var(--podcast-playback-position-border-color);

          border-radius: 5px;

          /*
          background-color: var(--podcast-playback-position-background-color);
          background-size:  var(--podcast-playback-position-percentage) 100%;
          background-image: linear-gradient(var(--color-theme), var(--color-theme));
          */

          background-repeat: no-repeat;

          /*
          NOTE:
          - background 1 - shows the playback progress (purple bar which gets larger by using the background-size to stretch the width)
          - background 2 - emulate an 1px border, however the first background is drawn over this border
          */
          background-size: var(--podcast-playback-position-percentage) 100%
                         , 100% 100%;
          background-image:
                      linear-gradient( var(--color-theme), var(--color-theme) )
                    , linear-gradient( var(--podcast-playback-position-border-color) 0px
                                     , var(--podcast-playback-position-background-color) 1px
                                     , var(--podcast-playback-position-background-color) calc(100% - 1px)
                                     , var(--podcast-playback-position-border-color) 100%
                                     );
        }

        .podcastwidget--hasbeenplaying .podcastwidget__timeline
        {
/*          border-left-width: 0px;*/
        }

        .podcastwidget__volume_and_time
        {
          display: flex;
          align-items: baseline;

          line-height: 100%;
        }

            .podcastwidget__time
            {
              margin-left: auto;
              margin-top: 5px;

              display: flex;
              align-items: baseline;
            }

                  .podcastwidget__time-current
                , .podcastwidget__time-duration
                {
                  color: #767676;
                  font: var(--podcast-small-font)
                }

                .podcastwidget__time-divider
                {
                  margin-left:  4px;
                  margin-right: 4px;
                  opacity: 0.4;
                  font-size: 80%;
                }
                .podcastwidget__time-divider::before
                {
                  content: "|";
                }


.podcastwidget__resources
{
  display: flex;
  align-items: baseline;

  flex-wrap: wrap; /* zorg dat "View transcript" and "Also on:" are on seperate lines if there don't fit on a single line without wrapping text */
  column-gap: 30px;
  justify-content: space-between; /* when on the same line push the content to the sides */
}

    .podcastwidget__downloadtranscript
    {
      color: var(--rtd-link-color);
      font: var(--podcast-small-font)
    }

    .podcastwidget__externalproviders
    {
      color: #767676;
      font: var(--podcast-small-font);

      flex: none;

      display: flex;
      align-items: baseline;
    }

    .podcastwidget__externalproviders__items
    {
      display: flex;
    }

    .podcastwidget__externalproviders span
    {
      margin-right: 15px;
    }

    .podcastwidget__externalproviders a
    {
      padding: 5px; /* increate hitarea and size of our hover background */
      cursor: pointer;
    }

    .podcastwidget__externalproviders a:last-child
    {
      margin-right: -5px; /* fix alignment with content above */
    }

    .podcastwidget__externalproviders a:hover
    {
      background-color: #F0F0F0;
      border-radius: 3px;
    }

    .podcastwidget__externalproviders svg
    {
      vertical-align: middle;
      width:  21px;
      height: 21px;
      --icon-color:        var(--component-sharepage-button-color);
      --icon-within-color: #FFFFFF;

      --icon-color-g1: #0066D9;
      --icon-color-g2: #4285F4;
      --icon-color-g3: #EA4335;
      --icon-color-g4: #34A853;
      --icon-color-g5: #FAB908;
    }

    .podcastwidget__externalproviders:hover svg
    {
/*
      // --icon-color:        var(--component-sharepage-button-hover-color);
      // --icon-within-color: var(--component-sharepage-button-hover-color-inside);
*/
    }

        .podcastwidget__googlepodcast
        {
          margin-left: 30px;
        }

        .podcastwidget__spotify-podcasts svg
        {
          --icon-color: #1ED760;
        }

        .podcastwidget__applepodcasts
        {

        }


.podcastwidget__description
{
  margin-top: calc(-1 * var(--contentpanels-radius-small));

  border-radius: 0 0 var(--contentpanels-radius-small) var(--contentpanels-radius-small);

  background: var(--podcast-descriptionpanel-background);

  padding: calc(15px + var(--contentpanels-radius-small))
           var(--podcast-pad-h)
           15px
           var(--podcast-pad-h)
           ;

  font: var(--podcast-descriptionpanel-font);
  --rtd-text-font: var(--podcast-descriptionpanel-font);
}

.podcast-description-ellipsis .podcastwidget__description .rtdcontent
{
  /* NOTE: Safari applies the line-clamp to all paragraphs within this element
           instead of the combined text lines of content/paragraphs within.
           There for Safari we need to set limit on the visual height. */
  max-height: calc(24px * 3 + 6px);

/* // ONLY works with -webkit-box*/
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.podcastwidget__description__readmorebutton
{
  border: 0;
  padding: 0;
  background: none;
  font: inherit;
  color: var(--rtd-link-color);
  text-decoration: underline;
  cursor: pointer;
}

.widget--podcast.widget--readmore-activated .podcastwidget__description .rtdcontent
{
  -webkit-line-clamp: initial;
  max-height: none;
}

.widget--podcast.widget--readmore-activated .podcastwidget__description__readmorebutton
{
  display: none;
}



input[type=range]
{
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
}

input[type=range]:focus
{
  outline-offset: 4px;
}


/* NOTE: If the slider has no dimensions (display:none; or -webkit-appearance:none; and no width or height set
         Chromium and Webkit browsers will */
input[type=range]::-webkit-slider-thumb
{
  opacity: 0;

/*  -webkit-appearance: none;*/
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb
{
  opacity: 0;
}




input[type=range]::-webkit-slider-runnable-track
{
  width: 100%;
  height: var(--podcast-slider-height);
  cursor: pointer;
  border-radius: var(--podcast-slider-radius);
}

input[type=range]:focus::-webkit-slider-runnable-track
{
}

input[type=range]::-moz-range-track
{
  width: 100%;
  height: var(--podcast-slider-height);
  border-radius: var(--podcast-slider-radius);

  cursor: pointer;
}

