/* Filter tweaks */
.page--financielewegwijzer .acoifilterpage-filters__filters {
  --filterpage-filtergroup-padding: 20px 12px; /* 15px 12px; */
  --textcontrols_padleft: 12px;
  --textcontrols_padright: 12px;
}
@media (max-height: 800px) {
  .page--financielewegwijzer .acoifilterpage-filters__filters {
    --filterpage-filtergroup-padding: 15px 12px;
    --formcontrols-height: 35px; /* instead of 40px */
    --tagpanel-option-font: 13px/22px var(--rtd-heading-fontfamily);
  }
  .page--financielewegwijzer .acoifilterpage-filters__filters .tagcategory__title {
    border-bottom: none !important;
    margin-bottom: 0;
  }
  .page--financielewegwijzer .acoifilterpage-filters__filters .multiselect__panel__buttonbar {
    height: 50px;
  }
  .page--financielewegwijzer .acoifilterpage-filters__filters .multiselect__panel__clear, .page--financielewegwijzer .acoifilterpage-filters__filters .multiselect__panel__ok {
    font-size: 15px;
    line-height: 100%;
    height: var(--button-height);
    --button-height: 30px; /* 30px */
  }
}
.page--financielewegwijzer .acoifilterpage-filters__filters .filtergroup {
  border: 1px solid var(--color-theme);
  border-radius: var(--contentpanels-radius-small);
}
.page--financielewegwijzer .acoifilterpage-filters__filters .tagcategory__title {
  border-bottom: 1px solid var(--color-theme);
  --tagpanel-heading-margin: 17px;
}
.page--financielewegwijzer .multiselect__summary {
  padding-bottom: 10px;
}

.filterpanel--fwselections {
  --filterpage-filtergroup-spacing: 24px;
  display: flex;
  flex-direction: column;
  row-gap: var(--filterpage-filtergroup-spacing);
}
.filterpanel--fwselections .filterpanel + .filterpanel {
  margin-top: 0 !important;
}
.filterpanel--fwselections .filterpanel__showmorebutton {
  align-self: center;
}
.filterpanel--fwselections .filterpanel--extrafilters:not([hidden]) {
  display: contents;
}