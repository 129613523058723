/*
NOTES:

The <summary> element cannot be display: flex; in Safari.
Also see:
- https://bugs.webkit.org/show_bug.cgi?id=190065
- https://github.com/philipwalton/flexbugs#flexbug-9

About accordion's and useability:
https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html


DOM setup:

<details>

  <summary>
    .widget-accordion__item__headerwrapper - Used for defining the grid because Safari(?) doesn't allow <summary> to be a grid
      .widget-accordion__item__header (flex)
        .widget-accordion__item__header-icon  - Icon (baseline aligned to the 1st line of the title)
        .widget-accordion__item__header-title - Title (vertically centered within the header)

  .widget-accordion__item__body - Wrapper for clipping
    .widget-accordion__item__body__content - For setting up a grid for the richtext content
*/



/* Accordion as part of the page grid
////////////////////////////////////////////////////////////////////////////////////////

We are specific in which situation we go fullwidth
(in a page with inset such as Projects-DU-details we might not want to go fullwidth.
That page uses it's on contentarea container.
*/
  .page__contentarea--rtddoc > .widget-accordion
, .wh-form__richtext > .widget-accordion
{
  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);

}

  .widget-accordion__title
, .widget-accordion__item__header
{
  grid-column: var(--pagegrid-col-default);
}

/* NOTE: <details> cannot be grid, so make sure to just give it the full width
         and have a wrapper within be the grid. */
.widget-accordion__item
{
  grid-column: var(--pagegrid-col-fullwidth);
}

.widget-accordion .widget-accordion__item__headerwrapper /* because <summary> cannot be a grid */
{
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}



/* Although officially you cannot create an FAQ widget within a two column widget,
   it is possible for it to exist. (for example if you copy & paste an FAQ widget to the
   richtext content of the FAQ) */
  .widget-richcontent__column .widget-accordion__item__body__content > *
, .widget--rovid .widget-accordion__item__body__content > *
{
  grid-column: var(--pagegrid-col-fullwidth);
}




.spc-accordion
{
  display: contents;
  --accordion-item-line: 1px solid var(--accordion-item-line-color);
  --accordion-item-line-top: var(--accordion-item-line);
  --accordion-item-line-bottom: var(--accordion-item-line);
}

/* Ensure only the visible part of the header is interactive (hoverable/clickable) */
.spc-accordion details summary { pointer-events: none; }
.spc-accordion details .widget-accordion__item__header { pointer-events: auto; }


/* Hide default arrow browsers show on <summary> */
.spc-accordion details summary { list-style-type: none; } /* Firefox, Chrome 90+ */
.spc-accordion details summary::-webkit-details-marker { display: none; } /* Chrome 12-88 */
.spc-accordion details summary::marker { display: none; } /* Chrome 84+ (oct 2020), FF68 (jul 2019), SF11.1 (mar 2018) */


.spc-accordion summary:focus
{
  outline: 0;
}

.spc-accordion summary:focus-visible .widget-accordion__item__header /* iOS 15.4 */
{
  animation: var(--component-focus-animation);
}

.widget-accordion__item__header
{
  display: flex;

  color: var(--accordion-header-color);
  font:  var(--accordion-header-font);

  border-top: var(--accordion-item-line);

  min-height:   var(--accordion-header-minheight);
  padding:      var(--accordion-header-padding);
  padding-left: var(--accordion-item-indent);

  position: relative;

  -webkit-user-select: none; /* Chrome <54, all SF & iOS */
  user-select: none;

  cursor: pointer;
}

.widget-accordion__item:first-child .widget-accordion__item__header
{
  border-top: var(--accordion-item-line-top);
}

.widget-accordion__item:not([open]):last-child .widget-accordion__item__header
{
  border-bottom: var(--accordion-item-line-bottom);
}

/*.widget-accordion__item__header::before*/
.widget-accordion__item__header-icon::before
{
  color:     var(--accordion-header-symbol-color);
  font:      var(--accordion-header-symbol-font);
  content:   var(--accordion-header-symbol-content);
  font-size: var(--accordion-header-symbol-size);

  flex:  none;
  width: var(--accordion-item-symbolgutterwidth);

  position: relative;
  top: var(--accordion-header-symbol-shift-y);
}

.widget-accordion__item__header-icon
{
  align-self: baseline;
}

.widget-accordion__item__header-title
{
  align-self: center;
  flex: 1 0 0;
}



.widget-accordion__item__header:hover
{
  background-color: var(--accordion-header-hover-background);
}

.widget-accordion__item[open] .widget-accordion__item__header-icon::before
{
  content: var(--accordion-header-symbol-open-content);
}



.widget-accordion__item__body__content
{
  padding-top:     5px;
  padding-bottom: 53px;
  padding-left:   var(--accordion-item-indent);

  position: relative;
}

.widget-accordion__item:last-child .widget-accordion__item__body__content::after
{
  content: "";

  position: absolute;
  width: 100%;
  bottom: 0;

  grid-column: var(--pagegrid-col-default);
  border-top: var(--accordion-item-line);
}

.widget-accordion__item:last-child .widget-accordion__item__body__content
{
/*  padding-bottom: 0;*/
}
