.fwitem
{
  background-color: var(--component-spctile-background);
  border-radius: var(--contentpanels-radius-small);

  box-shadow: var(--contentpanels-box-shadow);

  padding: 28px 30px;
}

@media (max-width: 640px)
{
  .fwitem
  {
    padding: 20px 15px;
  }
}

.fwitem.notmatching
{
  display: none;
}

.fwitem__tagslist
{
  margin-bottom: 15px;
}

.fwitem__title
{
  font: var(--rtd-h2-font);
/*  font: var(--rtd-h2-scaling-font);*/
}

.fwitem__description
{
  margin-top: 15px;
  font: 15px/24px var(--rtd-text-fontfamily);

  display: -webkit-box; /* ONLY works with -webkit-box */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}


@media (max-width: 800px)
{
  .fwitem__buttonbar
  {
    margin-top: 30px;
  }
}

@media (min-width: 801px)
{
  .fwitem__specs_and_button
  {
    display: flex;
    column-gap: 30px;

    flex-wrap: wrap;
    row-gap: 30px;

    /* when wrapping the specsheet will take the full width and the buttons shrinkwrap so we can justify them to be at the right */
    justify-content: end;
  }

  .fwitem__buttonbar
  {
    justify-content: end;
  }
}


.fwitem__specsheet
{
  flex: 1 1 auto;

  margin-top: 20px;
  /*
  font: var(--rtd-text-tiny-font);
  --rtd-text-font: var(--rtd-text-tiny-font);
  */
  font: 13px var(--rtd-heading-fontfamily);
  --rtd-text-font: 13px var(--rtd-heading-fontfamily);

  width: min-content; /* try to have the specsheet stay next to the buttons */

  display: grid;
  grid-template-columns: min-content 1fr;
  row-gap: 6px;
}

.fwitem__specsheet__item
{
  display: contents;
}

.fwitem__specsheet__item > *
{
  white-space: nowrap;
}

.fwitem__specsheet__item dt
{
  font-weight: 500;
  color: var(--color-theme);
  padding-right: 15px;
  white-space: nowrap;
}

.fwitem__specsheet__item .rtdcontent
{
  white-space: normal;
}

.fwitem__buttonbar
{
  display: flex;
  column-gap: 11px;
  row-gap: 11px;
  flex-wrap: wrap;
}

.fwitem__detailslink
{
  --spc-appearance: button-outline;
  align-self: end;
  white-space: nowrap;
}

.fwitem__providerlink
{
  --spc-appearance: button-solid;
  align-self: end;
  white-space: nowrap;
}
