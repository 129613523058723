.widget-links ul
{
  list-style: none;
  padding: 0;
  margin: 0;

  color: var(--rtd-link-color);
  font:  var(--rtd-text-compact-font);
}

.widget-links li
{
  display: flex;
  align-items: baseline;
}

/* We need the icon to be outside the <a> so we can
   ensure the icon won't get an underline on hover. */
.widget-links li::before
{
  flex: none;

  content: var(--fa-chevron-circle-right);
  font: var(--fontawesome-light);
  font-size: 19px;

  position: relative;
  left: -1px;
  top: 2px;

  width: 30px; /* keep the different icons (with different widths) aligned */

  margin-right: -30px; /* overlap into the <a> area */
}

.widget-links a
{
  padding-left: 30px; /* create space for the icon to be */
  color: inherit;
  text-decoration: none;
}

.widget-links a:hover
{
  text-decoration: underline;
}

.widget-links li + li
{
  margin-top: 3px;
}

.widget-links li.isexternal::before
{
  content: var(--fa-arrow-right);
  transform: rotate(-45deg);
  font-size: 17px;

  position: relative;
  left: -2px;
  top: -4px;
}

/*
.widget-links li:has(a:hover)
{
  color: #000000;
}

.widget-links a:hover
{
  color: #000000;
}
*/
