.spc-eventtile__specsheet .icon
{
  color: var(--color-theme);
}

.spc-eventtile__specsheet .icon::before
{
  font: var(--fontawesome-light);
  font-size: 16px;
  width: 25px;
  display: block;
}

.spc-eventtile__specsheet__date .icon::before
{
  content: var(--fa-calendar-alt);
  padding-left: 1px;
}

.spc-eventtile__specsheet__time .icon::before
{
  content: var(--fa-clock);
}

.spc-eventtile__specsheet__location .icon::before
{
  content: var(--fa-map-marker-alt);
  padding-left: 2px;
}

.spc-tile--isexternallink
{
  --color-theme:                        var(--color-green1);
  --component-tag-border-color:         var(--color-green1);
  --component-spctile-title-color:      var(--color-green1);
  --component-spctile-hover-background: var(--color-green4);
}
