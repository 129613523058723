/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** @short The active menu selection will look like a tab from which the dropdown extends.
 *  @long Use this mixin on the .spc-menubar container
 */
/** @short Dropdown appears slightly below the menubar item with an arrow pointing to the menu item it belongs to
 * (FIXME: test)
*/
/** @short Dropdown appears slightly below the menubar item with the top of the dropdown having kind of a half arrow pointing to the menu item the dropdown belongs to
 * (FIXME: test)
*/
@media (min-width: 950px) {
  html {
    /* the height when the page has scrolled */
    --siteheader-height-approximation: 133px;
    --siteheader-deeplinks-spacing: 30px;
  }
}
@media (max-width: 949px) {
  html {
    --siteheader-height-approximation: 39px;
    --siteheader-deeplinks-spacing: 20px;
  }
}
/***********************************************************************
**
**  Header layout
*/
.header-top {
  z-index: 1;
  /* workaround for Safari 15.5 renderbug in which the menubar overlaps the button (stickout out of the topbar) even though the topbar has a higher z-index */
  -webkit-transform: translate3d(0, 0, 0);
}

.header-top,
.header-menubar {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.header-top__content,
.header-menubar__content {
  grid-column: var(--site-menubar-grid-column);
  display: flex;
  align-items: center;
}

.languagemenu__link,
.languagemenu__current {
  color: #767676;
  font: 16px/22px var(--rtd-text-fontfamily);
  padding: 3px 5px;
  text-decoration: none;
  text-underline-offset: 3px;
}

.languagemenu__current {
  text-decoration: underline;
}

.header-top__languagemenu + * {
  margin-left: 20px;
}

.languagemenu__divider {
  border-left: 1px solid #767676;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 1px;
}

.header-menubar-background,
.header-menubar {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
}

.header-menubar-background {
  /*
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    transition: -webkit-backdrop-filter 0.25s
              , backdrop-filter 0.25s;
  */
}

.header-menubar {
  padding-top: var(--site-menubar-padding-top);
  padding-bottom: var(--site-menubar-padding-bottom);
}

.header-menubar__identity img {
  display: block;
}

.site__header__menuitems {
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap; /* in this site we cannot have a multiline menu */
  height: 43px;
  display: flex;
}

.site__header__menuitems > li {
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
}

/***********************************************************************
**
**  Effect on menubar when searchpanel is active
*/
/***********************************************************************
**
**  Header layout
*/
.header-menubar__search,
.header-menubar__showsidemainmenu {
  flex: none;
}

.header-menubar__spacer {
  flex: 1 0 20px;
}

.header-menubar__menubar {
  /* allow shrinking, this will also allow the menu-overflow derection code to work */
  flex: 0 1 min-content;
  min-width: 0; /* FIXME: why won't flex-shrink be enough ? */
}

.header-menubar__menubar + .header-menubar__spacer {
  flex: 0 0 30px;
}

.header-menubar--hidemenuitems .header-menubar__menubar {
  visibility: hidden;
  pointer-events: none;
  position: relative;
  overflow: hidden;
}

.header-menubar__content {
  display: flex;
  align-items: center;
  /* FIXME: prevention for overflow...*/
  width: -webkit-fill-available;
  width: -moz-available;
  overflow: hidden;
  font: var(--menubar-level1-font);
  color: #DDDDDD; /* just so we can see it if we forgot to give a menubar element a color */
}

.header-menubar__showsidemainmenu {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: var(--transparenticonbuttons-radius);
  padding: var(--site-menubar-button-padding);
  margin-right: -5px;
  color: var(--site-menubar-icon-color);
  font-size: var(--site-menubar-icon-size);
  cursor: pointer;
}

.header-menubar__showsidemainmenu:hover {
  background-color: var(--transparenticonbuttons-hover-background);
}

.header-menubar__showsidemainmenu::before {
  font: var(--fontawesome-regular);
  content: var(--fa-bars);
  font-size: var(--site-menubar-icon-size);
}

.header-menubar__showsearchpanel {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  padding: var(--site-menubar-button-padding);
  border-radius: var(--transparenticonbuttons-radius);
  font: inherit;
  color: var(--site-menubar-icon-color);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-menubar__showsearchpanel:hover {
  background-color: var(--transparenticonbuttons-hover-background);
}

.header-menubar__showsearchpanel:hover .text {
  text-decoration: underline;
}

.header-menubar__showsearchpanel::before {
  font: var(--fontawesome-regular);
  content: var(--fa-search);
  color: var(--site-menubar-icon-color);
  font-size: var(--site-menubar-icon-size);
  margin-right: 15px; /* distance to the text */
}

.header-menubar__showsidemainmenu {
  margin-right: 14px;
}

.header-menubar__identity {
  display: grid;
}

.header-menubar__identity a,
.header-menubar__identity img {
  grid-column: 1;
  grid-row: 1;
}

.header-menubar__homelink {
  z-index: 0;
}

@media (min-width: 950px) {
  .header-menubar {
    color: #FFFFFF;
  }
  .header-menubar {
    top: 0px;
  }
  .header-menubar-background {
    top: -3px;
  }
  /* when the menubar is hidden because it doesn't fit, FORCE the sidebar menu toggle to be visible */
  .header-menubar:not(.header-menubar--hidemenuitems) .header-menubar__showsidemainmenu {
    display: none;
  }
  .site-scrollprogressbar {
    position: sticky;
    top: 97px;
  }
}
@media (max-width: 949px) {
  html {
    --siteheader-height-approximation: 39px;
    --site-menubar-icon-size: 25px;
  }
  .header-top {
    display: none;
  }
  .header-menubar {
    min-height: 53px;
    top: -4px;
  }
  a.header-menubar__identity {
    text-decoration: none;
    font: bold 28px var(--rtd-heading-fontfamily);
    font-size: max(20px, min(3.5vw, 28px));
    font-size: clamp(20px, 3.5vw, 28px);
  }
  .header-menubar__identity > img {
    width: auto;
    height: var(--site-menubar-logo-height);
  }
  .header-menubar__menubar {
    display: none;
  }
  .header-menubar-background {
    top: -8px;
  }
  .header-menubar__showsearchpanel.header-menubar__showsearchpanel {
    font-size: 0;
  }
  .header-menubar__showsearchpanel::before {
    margin-right: 0;
  }
  .header-menubar__searchinput.header-menubar__searchinput {
    height: 39px;
  }
  .site-scrollprogressbar {
    position: sticky;
    top: 39px;
  }
}