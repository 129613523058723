.embeddedobject--privacysettings {
  margin-top: 30px;
}

.privacysettings__option {
  background-color: var(--consentsettings-option-background);
  border: var(--consentsettings-option-border);
  border-radius: 7px;
  padding: 22px 20px 20px 20px;
}

.privacysettings__option + .privacysettings__option {
  margin-top: 16px;
}

.privacysettings__option__labelwrapper {
  display: flex;
}

/* higher specificy than .spc-toggle + label + label */
.privacysettings__option__label.privacysettings__option__label {
  margin-left: 14px;
  font: var(--consentsettings-option-font);
  color: var(--consentsettings-option-color);
  display: table; /* shrinkwrap */
}

.privacysettings__option__labelwrapper + .privacysettings__option__description {
  margin-top: 15px;
}

/*
input[type="checkbox"].spc-toggle + label
{
  width: 61px;
  height: 30px;
  border-radius: 15px;
}

input[type="checkbox"].spc-toggle + label::before
{
  width:  24px;
  height: 24px;
  margin-top: -12px;
}

input[type="checkbox"].spc-toggle + label::after
{
  line-height: 25px;
}

input[type=checkbox].spc-toggle:checked + label::before
{
  left: calc(100% - 18px - 6px);
}
*/
.embeddedobject--privacysettings .privacysettings--savebutton {
  display: table;
  margin-left: auto;
  margin-top: 25px;
}

.embeddedobject--privacysettings:not(.submitted) .privacysettings__page--submitted {
  display: none;
}

.embeddedobject--privacysettings.submitted .privacysettings__page--settings {
  display: none;
}