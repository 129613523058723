/*
NOTE: KEEP this file plain CSS so the RTD document editor can use this stylesheet.
*/

.person__name
{
  font: 18px/24px var(--rtd-heading-fontfamily);
  color: var(--color-theme);
  margin-bottom: 2px;
}

.person__role
{
  font: 15px/20px var(--rtd-text-fontfamily);
  color: var(--common-caption-color);
}

  .person__quote
, .person__meta__spacer
, .person__profilelink
{
  display: nonel;
}



.widget-persons__item
{
  position: relative;
}

.widget-persons__item::after
{
  content: var(--fa-plus);
  font: var(--fontawesome-regular);
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: var(--color-purple1);
  color: #FFFFFF;
  border-radius: 0 var(--contentpanels-radius-small) 0 var(--contentpanels-radius-small);
}

.widget-persons__item:hover
{
  outline: 3px solid var(--color-purple1);
  outline-offset: 4px;
}

