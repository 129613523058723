/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*

- .pageheader__background
  Is used to display the background a little wider than the normal page content.
  We do need to give the textpanel the same background color, just so accessibility checker which aren't smart enough
  to see this is the background don't think the white text is on a white background.


- We give the .pageheader__background and .pageheader__text
  - a margin-top and margin-bottom to give the impression the image sticks out

*/
.pageheader--imagestickingout ~ .page__body > .page__contentarea {
  --header-to-content-margin: var(--header-imagestickingout-margin-to-content);
}

.pageheader--imagestickingout {
  margin-top: var(--header-imagestickingout-margin-above);
  /* FIXME */
  /* When we use the full contentarea width, the header must also align to this width.
     This means the image will use the full contentarea width and credits
     will be shown below the image.
  */
  /* When content is centered whe'll use the default (text)content width
     of that page to align the title and image to.
     The credits will be positioned at the right in the area at the right of
     the current pages default content area.
  */
  /* Mobile layout is almost the same as the "Side-by-side" page-header.
  */
}
html.site--acoi .pageheader--imagestickingout.pageheader {
  --rtd-heading-color: #FFFFFF;
  --rtd-text-color: #FFFFFF;
  --rtd-link-color: #FFFFFF;
}
.pageheader--imagestickingout:focus .pageheader__background {
  outline: var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);
}
.pageheader--imagestickingout .pageheader__text {
  color: var(--rtd-text-color);
}
.pageheader--imagestickingout .pageheader__when {
  font: var(--header-imagestickingout-pagedate-font);
  margin-bottom: var(--header-imagestickingout-pagedate-spacing);
}
.pageheader--imagestickingout .pageheader__when::before {
  font: var(--header-when-icon-font);
  content: var(--header-when-icon-content);
  margin-right: 10px;
}
.pageheader--imagestickingout .pageheader__title {
  font: var(--header-imagestickingout-title-font);
}
.pageheader--imagestickingout .pageheader__source {
  font: var(--header-imagestickingout-source-font);
}
.pageheader--imagestickingout .pageheader__imagecredits {
  font: var(--pageheader-credits-font);
}
@media screen and (min-width: 768px) {
  .pageheader--imagestickingout.pageheader {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: min-content var(--header-imagestickingout-image-coverheader-amount) min-content min-content;
  }
  .pageheader--imagestickingout.pageheader--noimage {
    --header-imagestickingout-image-coverheader-amount: 0px; /* don't reserve the space, there's no image! */
  }
  .pageheader--imagestickingout .pageheader__background {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 1/span 2;
    /* Whe'll let part of the image stick out.
       An special element in the contentarea will push down the content
       (using the aspect ratio minus the header overlap value.
       This way we can have the gutter content (page index) next
       to the header image and properly be sticky.
    */
    background: var(--currenttheme-background);
    border-radius: var(--contentpanels-radius-outsidegutter);
  }
  .pageheader--imagestickingout .pageheader__text {
    grid-column: var(--pagegrid-col-narrow);
    grid-row: 1;
    background: var(--currenttheme-background);
    padding: var(--header-imagestickingout-title-padding);
  }
  .pageheader--imagestickingout .pageheader__imagewrapper {
    grid-column: var(--pagegrid-col-narrow);
    grid-row: 2/span 2;
  }
  .pageheader--imagestickingout .pageheader__image {
    border-radius: var(--contentpanels-radius);
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 3/2;
    object-fit: cover; /* in case we want to use aspect-ratio */
    min-height: 100%;
    max-height: 44vh;
    /* at least enough to stick out of the header,
       but try to keep enough room below the header
       to have page rich content (text) visible.
    */
    max-height: max(250px, 100vh - 323px - 100px);
    box-shadow: var(--pageheader-photo-box-shadow);
  }
}
@media screen and (max-width: 1023px) {
  .pageheader--imagestickingout .pageheader__text, .pageheader--imagestickingout .pageheader__imagewrapper {
    grid-column: var(--pagegrid-col-default);
  }
  .pageheader--imagestickingout .pageheader__imagecredits {
    margin-top: 8px;
    grid-column: var(--pagegrid-col-default);
    grid-row: 4;
    color: var(--common-caption-color);
    /* Take out of normal left-to-right reading flow */
    text-align: right;
  }
}
@media screen and (min-width: 1024px) {
  .pageheader--imagestickingout .pageheader__imagewrapper {
    grid-row: 2/span 1; /* let the image stick out */
  }
  .pageheader--imagestickingout ~ .page__body > .page__contentarea .page-contentstart {
    display: block;
    grid-row: 1;
    grid-column: var(--pagegrid-col-narrow);
    aspect-ratio: 3/2;
    aspect-ratio: var(--headerimage-aspectratio);
    max-height: max(250px, 100vh - 323px - 100px);
    margin-bottom: calc(var(--header-imagestickingout-image-coverheader-amount) * -1);
  }
  .pageheader--imagestickingout .page-contentstart, .pageheader--imagestickingout .deeplinks-wrapper {
    grid-row: 1;
  }
  .pageheader--imagestickingout ~ .page__body > .page__contentarea .page-contentstart + * {
    grid-row: 2;
  }
  .pageheader--imagestickingout .pageheader__imagecredits {
    grid-column: var(--pagegrid-col-narrow-gutter-right);
    grid-row: 2;
    padding: var(--header-imagestickingout-imagecredits-padding);
    color: var(--color-theme-faded);
    align-self: end; /* towards the bottom of the visible page-header panel */
    text-align: right;
  }
}
@media screen and (max-width: 767px) {
  .pageheader--imagestickingout .pageheader__imagecredits {
    display: none;
    /*
    grid-column: var(--pagegrid-col-default);
    grid-row: 1;
    align-self: end; // bottom
    justify-self: end;
    padding: 10px;
    color: #FFFFFF;
    text-shadow: 1px 1px 1px #000000;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    */
  }
  .pageheader--imagestickingout.pageheader {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: min-content min-content;
    row-gap: 24px;
    background: var(--currenttheme-background);
    padding-top: 25px;
    padding-bottom: 32px;
  }
  .pageheader--imagestickingout .pageheader__background {
    display: none;
  }
  .pageheader--imagestickingout .pageheader__text {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 2;
  }
  .pageheader--imagestickingout .pageheader__imagewrapper {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 1;
  }
  .pageheader--imagestickingout .pageheader__image {
    border-radius: var(--contentpanels-radius);
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 5/2; /* on mobile we fix the aspect-ratio */
    object-fit: cover;
    min-height: 100%;
    box-shadow: 3px 3px 17px 0 rgba(0, 0, 0, 0.24);
  }
}
@media print {
  .pageheader--imagestickingout.pageheader {
    background: transparent;
    --rtd-heading-color: #000000;
    --rtd-text-color: #000000;
    --rtd-link-color: #000000;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .pageheader--imagestickingout .pageheader__background {
    display: none;
  }
  .pageheader--imagestickingout .pageheader__text {
    grid-row: 1;
    grid-column: var(--pagegrid-col-verywide);
    margin-bottom: 0.1cm;
  }
  .pageheader--imagestickingout .pageheader__imagewrapper {
    grid-row: 2;
    grid-column: 1/-1;
  }
  .pageheader--imagestickingout .pageheader__image {
    width: 100%; /* abide to the given width */
    height: 7cm;
    object-fit: cover;
  }
  .pageheader--imagestickingout .pageheader__imagecredits {
    grid-row: 3;
    text-align: right;
    color: var(--common-caption-color);
    font: var(--pageheader-credits-font);
    grid-column: var(--pagegrid-col-verywide);
  }
}