/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.page__body {
  display: flex;
  flex-direction: column;
}

.page__body > .page__contentarea {
  flex: 1 0 auto;
}

/* Whitespace between normal/RTD page content and the footer.
   This "balloon" can expand if needed to push the footer
   to the bottom of the viewport. (in case the viewport is
   larger than the content of the page) */
.page__contentarea .page__balloon {
  margin-top: 0 !important; /* override selectors such as ".widget + *" setting a margin */
  height: var(--whitespace-footer-content);
  grid-column: var(--pagegrid-col-fullwidth);
}

/* Whitespace between custom page content and the footer
   FIXME: do we still use this? */
body > .page__balloon {
  grid-column: var(--pagegrid-col-fullwidth);
  flex: 1 0 auto;
  height: var(--whitespace-footer-content);
}

.page__body > .page__footer {
  flex: none;
}

.page__body,
.page__contentarea,
.wh-form--webtoolform,
.widget-accordion__item__body__content,
html.wh-widgetpreview body {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
  grid-auto-rows: max-content; /* when we get a height or flex-grow don't let it stretch our rows */
}

.page__body > .pageheader {
  grid-row: 1;
}

.page__contentarea {
  grid-column: 1/-1;
}

/* When the grid layout has been disabled,
   we make the whole .page__contentarea use the
   default content width. */
.page__body--disablegridlayout {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
}

.page__body--disablegridlayout > .page__contentarea {
  display: block;
  grid-column: var(--pagegrid-col-default);
}

.page-header__title {
  grid-column: var(--pagegrid-col-default);
}

@media (max-width: 1023px) {
  /* On mobile makes the default wide use the full viewport */
  .page__contentarea--rtddoc > *,
  .page__contentarea--formwebtool > .page-header__title,
  .page__contentarea--formwebtool > *,
  .widget-accordion__item__body__content > *,
  html.wh-widgetpreview body > * {
    --pagegrid-col-default: var(--pagegrid-col-verywide);
    --pagegrid-col-default-max8col: var(--pagegrid-col-verywide);
    --pagegrid-col-default-left: var(--pagegrid-col-verywide-left);
    --pagegrid-col-default-right: var(--pagegrid-col-verywide-right);
    grid-column: var(--pagegrid-col-verywide);
  }
  /*
    dialog
    {
      --pagegrid-col-default:         var(--pagegrid-col-verywide);
      --pagegrid-col-default-max8col: var(--pagegrid-col-verywide);

      --pagegrid-col-default-left:  var(--pagegrid-col-verywide-left);
      --pagegrid-col-default-right: var(--pagegrid-col-verywide-right);
    }
  */
}
@media (min-width: 1024px) {
  .page__contentarea--rtddoc > *,
  .page__contentarea--formwebtool > .page-header__title,
  .page__contentarea--formwebtool > *,
  .widget-accordion__item__body__content > *,
  html.wh-widgetpreview body > * {
    grid-column: var(--pagegrid-col-default);
  }
}
/* Support grid layout for formwebtool documents

   - !! Don't use a wrapper, becauce it will override the specificy
        and therefore may override layout of widgets in form OR forms embedded within page content.

   - But we CAN use :where now (iOS 14+). This adds 0 specificity.
     :where(.page__contentarea > .wh-form), because the selectors in :where have 0 specificy
*/
@media (max-width: 1023px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: var(--pagegrid-col-default);
  }
}

.wh-form--webtoolform,
.wh-form--webtoolform .wh-form__page,
.wh-form--webtoolform .wh-form__fieldgroup--richtext {
  grid-column: var(--pagegrid-col-fullwidth);
}

.wh-form--webtoolform .wh-form__richtext {
  width: 100%;
}

.wh-form--webtoolform,
.wh-form--webtoolform .wh-form__page--visible,
.wh-form--webtoolform .wh-form__richtext {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
}

.wh-form > a.wh-anchor,
.wh-form__page > a.wh-anchor,
.wh-form__richtext > a.wh-anchor {
  position: relative;
}