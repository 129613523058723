.widget-quote
{
  grid-column: var(--pagegrid-col-default-max8col);
}

.widget-quote__person__name
{
  color: var(--widget-quote-name-color);
  font:  var(--widget-quote-name-font);
}

.widget-quote__person__function
{
  color: var(--widget-quote-function-color);
  font: var(--widget-quote-function-font);
}




.widget-quote--hasimage
{
  display: flex;
  align-items: center;
}

.widget-quote--hasimage .widget-quote__imagewrapper
{
  flex: none;

  margin-right: var(--rtd-quote-margin);

  display: grid;
  grid-template-columns: min-content 1fr;
}

.widget-quote--hasimage .widget-quote__image
{
  width:  var(--rtd-quote-photosize);
  height: var(--rtd-quote-photosize);
  border-radius: 50%;

  grid-column: 1;
  grid-row:  1;
}


.widget-quote--hasimage .widget-quote__symbol
{
  grid-column: 1;
  grid-row:  1;

  /*width: calc(63px / var(--rtd-quote-photosize) * 100%);*/
  width: 34%;
  height: auto;

  align-self: center;
  justify-self: end;
  transform: translateX(calc(100% - 21px));

  /*
  width: 63px;
  height:  71px;
  */
}

.widget-quote--hasimage .widget-quote__text
{
  flex: 1 0 0px;
}

.widget-quote--hasimage .widget-quote__quote.widget-quote__quote
{
  color: var(--widget-quote-quote-color);
  font: var(--widget-quote-withimage-font);
}

.widget-quote--hasimage .widget-quote__quote + .widget-quote__person
{
  margin-top: 23px;
}




.widget-quote--withoutimage
{
  display: grid;
  grid-template-columns: min-content 1fr;
}

.widget-quote--withoutimage .widget-quote__symbol
{
  /*width: 8%;*/
  width: 63px;
  /*height: 71px;*/
  margin-right: 40px;
}

.widget-quote--withoutimage .widget-quote__quote.widget-quote__quote
{
  margin-top: -8px; /* compesate for font alignment */

  color: var(--widget-quote-quote-color);
  font: var(--widget-quote-withoutimage-font);
}

.widget-quote--withoutimage .widget-quote__person__name
{
  grid-column: 2;
  grid-row: 1;

  margin-top: 20px;
}

.widget-quote--withoutimage .widget-quote__person__function
{
  grid-column: 2;
  grid-row: 2;
}
