.footer--zvi {
  /* Extend the background a little further
     (we still need the background on .footer__panel, otherwise accessibility checkers cannot check the background to foreground contrast.
  */
  /* Apply the background color to all panels which contain text,
     so accessibility checkers can verify the contrast.
  */
  /*
  , .footer__sociallinks h2 + *
  {
    margin-top: var(--rtd-spacing-h2-after)
  }
  */
}
.footer--zvi.footer {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
.footer--zvi .footer__panel {
  grid-column: var(--pagegrid-col-fullwidth);
  --rtd-link-color: currentColor; /* inherit the color (use we use inherit the CSS variable (themecolor) will be inherited) */
  --rtd-link-color-hover: currentColor;
  --rtd-text-font: var(--footer-text-font);
  --rtd-heading-color: #FFFFFF;
  --rtd-link-color: var(--rtd-text-color);
  --rtd-link-color-hover: var(--rtd-text-color);
  --rtd-text-color: var(--footer-text-color);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  padding: var(--footer-panel-padding);
}
.footer--zvi .footer__panel__background {
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  grid-row: 1/span 2;
  background: var(--footer-panel-background);
  border-radius: var(--footer-panel-radius);
}
@media (max-width: 767px) {
  .footer--zvi .footer__panel__logo-zvi {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  .footer--zvi .footer__panel__content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
  }
}
.footer--zvi .footer__panel__content {
  padding-bottom: var(--footer-panel-padding-bottom);
  grid-row: 1;
  grid-column: var(--pagegrid-col-verywide);
}
.footer--zvi .footer__panel__identity {
  flex: 1 0 0;
}
.footer--zvi .footer__panel__rightside {
  flex: 3 0 0;
  padding-top: var(--footer-panel-padding-top);
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 60px;
}
.footer--zvi .footer__textcontent {
  flex: 0 1 auto;
}
.footer--zvi .footer__panel__bottomlinks {
  flex: 0 0 100%;
}
.footer--zvi .footer__panel__identity {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer--zvi .footer__identity__logo-rijksoverheid img {
  display: block;
  width: 189px;
  height: 130px;
}
.footer--zvi .footer__panel__logo-zvi {
  width: 188px;
  height: 54px;
}
.footer--zvi .footer__panel__bottomlinks {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* vertical align within the available (minimum) height */
  column-gap: var(--footer-bottombar-item-spacing);
  row-gap: 0;
  color: #FFFFFF;
  font: var(--footer-bottombar-font);
}
.footer--zvi .footer__panel__bottomlinks a {
  display: inline-block; /* allows us to use padding to increate the hit-area */
  padding: 2px 2px;
  color: inherit;
  text-decoration: none;
}
.footer--zvi .footer__panel__bottomlinks a:hover {
  text-decoration: underline;
}
@media (max-width: 500px) {
  .footer--zvi .footer__panel__content {
    --rtd-spacing-h2-after: 2px;
  }
  .footer--zvi .footer__panel__identity__logo {
    height: 42px;
  }
}
.footer--zvi .footer__quicklinks {
  font: var(--footer-text-font);
}
.footer--zvi .footer__panel h2 {
  font: var(--footer-heading-font);
}
.footer--zvi .footer__quicklinks li {
  display: flex;
  align-items: baseline;
  color: #FFFFFF;
}
.footer--zvi .footer__quicklinks li::before {
  position: relative;
  top: -2px;
  display: block;
  font: var(--fontawesome-solid);
  font-size: 11px;
  content: var(--fa-chevron-right);
  margin-right: 14px;
}
.footer--zvi .footer__quicklinks ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer--zvi .footer__quicklinks h2 + *,
.footer--zvi .footer__sociallinks h2 + * {
  margin-top: var(--rtd-spacing-h2-after);
}
.footer--zvi .footer__quicklinks a {
  color: var(--rtd-text-color);
  text-decoration: none;
}
.footer--zvi .footer__quicklinks a:hover {
  text-decoration: underline;
}
@media (min-width: 501px) {
  .footer--zvi {
    margin-bottom: 32px;
  }
  .footer--zvi .footer__textcontent .widget-button {
    white-space: nowrap;
  }
  .footer--zvi .footer__bottombar {
    justify-content: center;
  }
}
.footer--zvi .footer__sociallinks {
  column-gap: var(--component-sharepage-button-spacing);
  font: var(--rtd-text-font);
  --component-sharepage-button-spacing: 10px;
  --component-sharepage-button-background: transparent;
  --component-sharepage-button-border: 1px solid #C5C5C5;
  --component-sharepage-button-color: #FFFFFF;
  --component-sharepage-button-color-inside: var(--color-theme);
  --component-sharepage-button-size: 38px;
  --component-sharepage-button-hover-background: #FFFFFF;
  --component-sharepage-button-hover-color: var(--color-theme);
  --component-sharepage-button-hover-color-inside: #FFFFFF;
  --component-sharepage-button-hover-border: 1px solid var(--color-theme);
}
.footer--zvi .footer__sociallinks .spc-sociallink:hover {
  --component-sharepage-button-hover-background: #FFFFFF;
  --component-sharepage-button-hover-border: 2px solid #FFFFFF;
  --component-sharepage-button-hover-color: var(--color-theme);
}
.footer--zvi .footer__sociallinks__items {
  column-gap: var(--component-sharepage-button-spacing);
  flex-wrap: wrap;
}
.footer--zvi .footer__sociallinks h2 {
  margin-bottom: var(--rtd-spacing-h2-after);
}
@media (min-width: 1001px) {
  .footer--zvi .footer__sociallinks__items > * {
    display: inline-flex;
    margin-right: 10px;
  }
  .footer--zvi .spc-sociallink-breakline ~ .spc-sociallink {
    margin-top: 10px;
  }
}
@media (max-width: 1000px) {
  .footer--zvi .footer__sociallinks__items {
    display: flex;
    row-gap: 10px;
  }
  .footer--zvi .footer__sociallinks__items .spc-sociallink-breakline,
  .footer--zvi .footer__sociallinks__items br {
    display: none;
  }
}