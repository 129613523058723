/*
Chrome build in <dialog> styling:

dialog:-internal-dialog-in-top-layer
{
  position: fixed;
  inset-block-start: 0px;
  inset-block-end: 0px;
  max-width: calc((100% - 6px) - 2em);
  max-height: calc((100% - 6px) - 2em);
  user-select: text;
  visibility: visible;
  overflow: auto;
}
*/
::backdrop {
  background-color: rgba(0, 0, 0, 0.71);
  backdrop-filter: blur(2px);
  backdrop-filter: contrast(0.3) blur(1.5px);
}

html:has(dialog[open]) {
  /* Disable the scrollbar but keep the space reserved so the
     page in the background doesn't shift. */
  overflow: hidden;
}

html:has(.widget-person-popup[open]) {
  scrollbar-gutter: stable; /* Chrome 94 (sep 2021), Firefox 97 (feb 2022) - NOT Safari/iOS yet */
  background-color: #363636; /* is drawn into the spawn the space is reserved for the scrollbar */
}

.widget-person-popup {
  overflow: auto;
  overscroll-behavior: contain;
  background: transparent;
  border: 0;
  margin: 0 !important;
  /* NOTE: fit-content doesn't work correctly here for Safari (works in Chrome and Firefox though) */
  height: 100%;
  height: 100dvh; /* iOS 16.4 */
  max-height: none;
  /* Use full horizontal viewport area */
  max-width: none;
  width: auto;
  width: -webkit-fill-available;
  width: -moz-available;
  display: grid;
  grid-template-rows: 1fr min-content 1fr;
}

.widget-person-popup__content {
  grid-row: 2;
  grid-column: var(--pagegrid-col-verywide);
}

@media (max-width: 699px) {
  .widget-person-popup {
    padding: 20px;
  }
}
/* Vertically center person popup */
@media (min-width: 700px) {
  /* Only cover a horizontal strip */
  .widget-person-popup {
    padding: 44px;
  }
}
/* Close to the sides (still in grid) */
@media (min-width: 700px) and (max-width: 1149px) {
  .widget-person-popup {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-person-popup__content {
    grid-row: 2;
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
  }
}
/* Align with grid (wide contentarea) */
@media (min-width: 1150px) {
  .widget-person-popup {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
}
.widget-person-popup__backdrop {
  display: none;
  /*
  grid-column: var(--pagegrid-col-fullwidth);
  grid-row:    1;
  */
}

/* Positioning container */
.widget-person-popup__content {
  position: relative;
  background-color: #FFFFFF;
  border-radius: var(--contentpanels-radius-small);
  box-shadow: 0 0 20px 1px rgba(255, 255, 255, 0.1);
}

.widget-person-popup__headerbar {
  position: sticky;
  top: 0;
  right: 0;
}

.personcard__closebutton {
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: var(--color-purple2);
  color: #FFFFFF;
  border-radius: 0 var(--contentpanels-radius-small) 0 var(--contentpanels-radius-small);
  cursor: pointer;
  transition: border 0.2s, width 0.2s, height 0.2s, right 0.2s, top 0.2s;
}

@media (max-width: 699px) {
  .dialog--scrolled .personcard__closebutton {
    border-radius: var(--contentpanels-radius-small);
    width: 30px;
    height: 30px;
    right: 10px;
    top: -5px;
  }
}
.personcard__closebutton:hover {
  background: var(--color-purple1);
}

.personcard__closebutton::before {
  font: var(--fontawesome-regular);
  font-size: 18px;
  content: var(--fa-times);
}

/* Stacked - image at top, name/role/description/specs below */
@media (max-width: 699px) {
  .personcard {
    overflow: hidden;
  }
  .personcard__photo {
    grid-column: 1;
    grid-row: 1/-1;
    /* aspect ratio of 3:4 based on 100% of our column */
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    border-radius: var(--contentpanels-radius-small) var(--contentpanels-radius-small) 0 0;
    display: block;
  }
  .personcard__name {
    font: 24px/36px var(--rtd-heading-fontfamily);
    color: var(--rtd-heading-color);
  }
  .personcard__role {
    font: 15px/24px var(--rtd-heading-fontfamily);
    color: var(--text-grey-small);
  }
  .personcard__meta {
    grid-column: 2;
    /*    padding: var(--contentpanels-padding);*/
    padding: 15px;
  }
  .personcard__quote {
    margin-top: 38px;
    margin-bottom: 38px;
    font: 17px/28px var(--rtd-text-fontfamily);
    color: #333333;
  }
}
/* Side by side - image next to title/role/description/specsheet */
@media (min-width: 700px) {
  .personcard {
    display: grid;
    grid-template-columns: clamp(200px, 30vw, 343px) 1fr;
  }
  .personcard__photo {
    grid-column: 1;
    grid-row: 1/-1;
    /* aspect ratio of 3:4 based on 100% of our column */
    width: 100%;
    aspect-ratio: 3/4;
    min-height: 100%;
    object-fit: cover;
    border-radius: var(--contentpanels-radius-small) 0 0 var(--contentpanels-radius-small);
  }
  .personcard__header {
    grid-column: 2;
    grid-row: 1;
    padding-right: 70px; /* leave space for the closebutton */
  }
  .personcard__name {
    font: 24px/36px var(--rtd-heading-fontfamily);
    color: var(--rtd-heading-color);
  }
  .personcard__role {
    font: 15px/24px var(--rtd-heading-fontfamily);
    color: var(--text-grey-small);
  }
  .personcard__meta {
    grid-column: 2;
    padding: var(--contentpanels-padding);
  }
  .personcard__quote {
    margin-top: 38px;
    margin-bottom: 38px;
    font: 17px/28px var(--rtd-text-fontfamily);
    color: #333333;
  }
}
.personcard__contactlinks {
  margin-top: 35px;
  border-top: 1px solid #C5C5C5;
  padding-top: 17px;
  /*  column-count: 2;*/
  /*  column-gap: 100px;*/
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
  text-align: left;
  font: 17px/28px var(--rtd-text-fontfamily);
}

.personcard__contactlinks__column {
  flex: 0 1 50%;
}

.personcard__contactlinks__column > * {
  display: flex;
  align-items: center;
}

.personcard__contactlinks .icon {
  flex: none;
  color: var(--color-theme);
  width: 30px;
  font-size: 21px;
}

.personcard__contactlinks a {
  color: var(--rtd-link-color);
  text-decoration: underline;
}

.personcard__contactlinks__linkedin,
.personcard__contactlinks__twitter {
  white-space: nowrap;
}

.personcard__contactlinks__linkedin .icon,
.personcard__contactlinks__twitter .icon {
  font-size: 16px;
}

/*
.table-wrapper { column-count: 2; }
table tr { break-inside: avoid; }
*/