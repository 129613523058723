.sidebar__search {
  position: relative;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
}

.sidebar__search + * {
  margin-top: 20px;
}

.sidebar__search__input.sidebar__search__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: -15px; /* 14px padding + 1px border */
  width: calc(100% + 15px);
  width: -webkit-fill-available;
  width: -moz-available;
  height: var(--formcontrols-height);
  padding-left: 14px;
  padding-right: 45px;
  border: 1px solid var(--color-theme);
  font: 16px var(--rtd-text-fontfamily);
  border-radius: var(--contentpanels-radius-small);
  background-color: #FFFFFF;
}
.sidebar__search__input.sidebar__search__input::placeholder {
  color: #767676;
  opacity: 1;
}

/*
  .sidebar__search__input::-webkit-search-decoration
, .sidebar__search__input::-webkit-search-cancel-button
, .sidebar__search__input::-webkit-search-results-button
, .sidebar__search__input::-webkit-search-results-decoration
*/
.sidebar__search__input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.sidebar__search__input.sidebar__search__input::selection {
  color: #FFFFFF;
  background-color: var(--color-theme);
}

.sidebar__search__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 2px;
  top: 0;
  padding: 0 10px; /* increate hitarea */
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar__search__submit::before {
  font: var(--fontawesome-regular);
  content: var(--fa-search);
  font-size: 18px;
  color: var(--color-theme);
}

.sidebar__search__submit:hover::before {
  color: #000000;
}