.widget--rovid button:before,
.widget--rovid__fullscreencontent button:before {
  display: none;
}
.widget--rovid video,
.widget--rovid__fullscreencontent video {
  cursor: pointer;
}
.widget--rovid .video-js,
.widget--rovid__fullscreencontent .video-js {
  width: 100%;
  /*
      .vjs-slider
      {
        // background: #7D7D7D;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: var(--widget-rovid-controlbar-slider-radius);
      }
  */
}
.widget--rovid .video-js .vjs-big-play-button,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  width: auto;
  height: auto;
  display: block;
  vertical-align: top;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 0.25s;
}
.widget--rovid .video-js .vjs-big-play-button .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button .vjs-icon-placeholder {
  display: none;
}
.widget--rovid .video-js .vjs-big-play-button,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button {
  content: "";
  /* Center play icon within */
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--component-video-playbutton-size);
  height: var(--component-video-playbutton-size);
  line-height: 0;
  background: var(--component-video-playbutton-background);
  color: var(--component-video-playbutton-icon-color);
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0);
  transition: background-color 0.2s, box-shadow 0.5s;
  outline-offset: 4px;
  outline-width: 10px;
}
.widget--rovid .video-js .vjs-big-play-button::after,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button::after {
  display: block;
  content: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m20.702381 14.49167-20.702381-14.49167v28.98334z' fill='%23fff' fill-rule='evenodd' transform='translate(.172619 .50833)'/%3E%3C/svg%3E");
  height: var(--component-video-playbutton-icon-size);
  width: auto;
  aspect-ratio: 2/3;
  margin-left: 3px;
  transition: transform 0.3s cubic-bezier(0.425, 1.525, 0.58, 1.355); /* a kind of easeOutBack */
}
.widget--rovid .video-js .vjs-poster:hover ~ .vjs-big-play-button,
.widget--rovid .video-js .vjs-big-play-button:hover,
.widget--rovid .video-js .vjs-big-play-button:focus,
.widget--rovid__fullscreencontent .video-js .vjs-poster:hover ~ .vjs-big-play-button,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button:hover,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button:focus {
  background-color: var(--color-salmon-dark);
  box-shadow: 0 0 8px rgb(0, 0, 0);
}
.widget--rovid .video-js .vjs-poster:hover ~ .vjs-big-play-button::after,
.widget--rovid .video-js .vjs-big-play-button:hover::after,
.widget--rovid .video-js .vjs-big-play-button:focus::after,
.widget--rovid__fullscreencontent .video-js .vjs-poster:hover ~ .vjs-big-play-button::after,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button:hover::after,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button:focus::after {
  opacity: 0.95;
  transform: scale(1.2);
}
.widget--rovid .video-js .vjs-control-bar,
.widget--rovid__fullscreencontent .video-js .vjs-control-bar {
  width: auto;
  position: absolute;
  left: 10px;
  right: 10px;
  top: auto;
  bottom: 12px;
  height: var(--widget-rovid-controlbar-height);
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.7);
}
.widget--rovid .video-js .vjs-play-control,
.widget--rovid__fullscreencontent .video-js .vjs-play-control {
  display: inline-flex;
  align-items: center;
}
.widget--rovid .video-js .vjs-control,
.widget--rovid__fullscreencontent .video-js .vjs-control {
  outline: none;
}
.widget--rovid .video-js .vjs-time-control, .widget--rovid .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.widget--rovid .video-js .vjs-mute-control .vjs-icon-placeholder:before,
.widget--rovid .video-js .vjs-play-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-time-control,
.widget--rovid__fullscreencontent .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-mute-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-play-control .vjs-icon-placeholder:before {
  line-height: var(--widget-rovid-controlbar-height);
}
.widget--rovid .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  font-size: 24px;
}
.widget--rovid .video-js .vjs-play-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-play-control .vjs-icon-placeholder:before {
  font-size: 26px;
}
.widget--rovid .video-js .vjs-mute-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-mute-control .vjs-icon-placeholder:before {
  font-size: 20px;
}
.widget--rovid .video-js .vjs-time-control,
.widget--rovid__fullscreencontent .video-js .vjs-time-control {
  font-size: 14px;
  padding-right: 6px;
}
.widget--rovid .video-js .vjs-control.vjs-play-control,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-play-control {
  order: 0;
}
.widget--rovid .video-js .vjs-control.vjs-currenttime,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-currenttime {
  order: 1;
}
.widget--rovid .video-js .vjs-control.vjs-progress-control,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-progress-control {
  order: 2;
}
.widget--rovid .video-js .vjs-control.vjs-duration,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-duration {
  order: 3;
}
.widget--rovid .video-js .vjs-control.vjs-volume-panel,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-volume-panel {
  order: 4;
}
.widget--rovid .video-js .vjs-volume-panel,
.widget--rovid__fullscreencontent .video-js .vjs-volume-panel {
  order: 1;
}
.widget--rovid .video-js .vjs-captions-toggle,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle {
  order: 3;
}
.widget--rovid .video-js .vjs-fullscreen-control,
.widget--rovid__fullscreencontent .video-js .vjs-fullscreen-control {
  order: 4;
}
.widget--rovid .video-js .vjs-time-control.vjs-duration,
.widget--rovid__fullscreencontent .video-js .vjs-time-control.vjs-duration {
  display: block;
}
.widget--rovid .video-js .vjs-remaining-time,
.widget--rovid__fullscreencontent .video-js .vjs-remaining-time {
  display: none;
}
.widget--rovid .video-js .vjs-load-progress,
.widget--rovid__fullscreencontent .video-js .vjs-load-progress {
  display: none;
}
.widget--rovid .video-js .vjs-play-progress,
.widget--rovid__fullscreencontent .video-js .vjs-play-progress {
  background-color: #0DB24C;
  border-radius: var(--widget-rovid-controlbar-slider-radius);
}
.widget--rovid .video-js .vjs-current-time, .widget--rovid .video-js .vjs-remaining-time-display,
.widget--rovid .video-js .vjs-duration,
.widget--rovid__fullscreencontent .video-js .vjs-current-time,
.widget--rovid__fullscreencontent .video-js .vjs-remaining-time-display,
.widget--rovid__fullscreencontent .video-js .vjs-duration {
  display: block;
  font: var(--widget-rovid-controlbar-text-font);
  padding: 0;
  opacity: 0.8;
}
.widget--rovid .video-js .vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control,
.widget--rovid .video-js .vjs-volume-bar,
.widget--rovid__fullscreencontent .video-js .vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar {
  height: 100%;
}
.widget--rovid .video-js .vjs-volume-bar,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar {
  margin: 0;
  display: flex;
  align-items: center;
}
.widget--rovid .video-js .vjs-volume-bar:focus,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar:focus {
  outline: 0;
  box-shadow: none;
}
.widget--rovid .video-js .vjs-volume-bar .vjs-volume-level:before,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar .vjs-volume-level:before {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -4.5px;
}
.widget--rovid .video-js .vjs-volume-bar:focus .vjs-volume-level:before,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar:focus .vjs-volume-level:before {
  outline: none;
  box-shadow: 0 0 2px rgb(255, 255, 255);
}
.widget--rovid .video-js .vjs-slider-horizontal .vjs-volume-level,
.widget--rovid__fullscreencontent .video-js .vjs-slider-horizontal .vjs-volume-level {
  border-radius: var(--widget-rovid-controlbar-slider-radius);
  height: var(--widget-rovid-controlbar-slider-height);
}
.widget--rovid .video-js .vjs-volume-level.vjs-volume-level,
.widget--rovid__fullscreencontent .video-js .vjs-volume-level.vjs-volume-level {
  position: relative;
  z-index: 1;
}
.widget--rovid .video-js .vjs-volume-bar::after,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  height: var(--widget-rovid-controlbar-slider-height);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: var(--widget-rovid-controlbar-slider-radius);
}
.widget--rovid .video-js .vjs-picture-in-picture-control,
.widget--rovid__fullscreencontent .video-js .vjs-picture-in-picture-control {
  display: none;
}
.widget--rovid .video-js .vjs-captions-toggle,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle {
  width: auto;
  padding: 0 8px;
  cursor: pointer;
}
.widget--rovid .video-js .vjs-captions-toggle.cc,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.cc {
  margin-left: 2px;
  margin-right: 0;
}
.widget--rovid .video-js .vjs-captions-toggle.ad,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.ad {
  margin-right: 3px;
}
.widget--rovid .video-js .vjs-captions-toggle.vjs-on .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.vjs-on .vjs-icon-placeholder {
  opacity: 1;
}
.widget--rovid .video-js .vjs-captions-toggle .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle .vjs-icon-placeholder {
  color: #fff;
  display: block;
  cursor: pointer;
  border: 1px solid #fff;
  font-family: Arial;
  font-size: 8px;
  display: flex;
  align-items: center;
  padding: 3px 3px;
  opacity: 0.6;
  letter-spacing: 0.7px;
}
.widget--rovid .video-js .vjs-progress-holder,
.widget--rovid__fullscreencontent .video-js .vjs-progress-holder {
  height: 5px;
  border-radius: 8px;
}
.widget--rovid .video-js .vjs-progress-holder:focus-visible,
.widget--rovid__fullscreencontent .video-js .vjs-progress-holder:focus-visible {
  box-shadow: 0 0 1px 1px rgb(255, 255, 255);
  outline: 0;
}
.widget--rovid .video-js .vjs-play-progress:before,
.widget--rovid__fullscreencontent .video-js .vjs-play-progress:before {
  content: "";
  width: var(--widget-rovid-playprogress-knob-size);
  height: var(--widget-rovid-playprogress-knob-size);
  background-color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: calc(-1 * (var(--widget-rovid-playprogress-knob-size) - 5px) / 2);
  right: calc(var(--widget-rovid-playprogress-knob-size) / 2);
}
.widget--rovid .video-js.vjs-has-started .vjs-big-play-button,
.widget--rovid__fullscreencontent .video-js.vjs-has-started .vjs-big-play-button {
  display: block;
  opacity: 0;
}
.widget--rovid .video-js.vjs-has-started + .widget--rovid__duration,
.widget--rovid__fullscreencontent .video-js.vjs-has-started + .widget--rovid__duration {
  display: block;
}
.widget--rovid .video-js .vjs-text-track-cue,
.widget--rovid__fullscreencontent .video-js .vjs-text-track-cue {
  transform: translateY(-30px);
  line-height: 30px !important;
}
.widget--rovid .video-js .vjs-text-track-cue > div,
.widget--rovid__fullscreencontent .video-js .vjs-text-track-cue > div {
  padding: 5px;
}

.video-js .vjs-control.vjs-control:focus:before,
.video-js .vjs-control.vjs-control:hover:before,
.video-js .vjs-control.vjs-control:focus {
  text-shadow: 0 0 4px rgb(255, 255, 255), 1px 1px 1px rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.1);
}

.vjs-slider::focus,
.widget--rovid .video-js .vjs-volume-bar::after,
.vjs-volume-bar:focus .vjs-volume-level {
  box-shadow: 0 0 1px 1px rgb(0, 0, 0), 0 0 1px 2.5px rgb(255, 255, 255);
}

.vjs-volume-control {
  padding-left: 2px;
  padding-right: 2px;
}

.widget--rovid .video-js .vjs-captions-toggle.vjs-on .vjs-icon-placeholder {
  background-color: var(--color-green);
  color: #FFFFFF;
}