/*

ADDME: animate background-gradient on purple ZVI buttons
https://stackoverflow.com/questions/29591465/use-css-variables-with-rgba-for-gradient-transparency


FIXME: in the future we want to use style container queries
       to apply themecolors to part of the DOM.

@container style(--themecolor: purple)
{
  --currenttheme-background:    var(--color-purple-panel-background);
  --currenttheme-color:         var(--color-purple-panel-textcolor);
  --currenttheme-text-color:    #121212;
  --currenttheme-heading-color: var(--color-p);
}

Cherry chopstick background linear-gradient(270deg, #f00 0%, #f0f 100%)




https://stackoverflow.com/questions/6542212/use-css3-transitions-with-gradient-backgrounds


*/

html
{
  /* Themecolors */
  --color-purple1:      #5F0EAE; /* headers */
  --color-purple2:      #8E4DE1; /* summary markers */
  --color-purple3:      #EEE4F2;
  --color-purple4:      #F8F4F9; /* themas grid background */
  --color-purple-panel-background:    var(--color-purple3);
  --color-purple-panel-text-color:    #333333;
  --color-purple-panel-heading-color: var(--color-purple1);
  --color-purple-overlay: #5F0EAED0;
  /* FIXME: marker */

  --color-green:        #00947B;
  --color-green1:       #00947B;
  --color-green-plus:   #00856F;
  --color-green2:       #21BF84;
  --color-green3:       #A3F4D3;
  --color-green4:       #DCFCEF;
  --color-green-panel-background:    var(--color-green4);
  --color-green-panel-text-color:    #333333;
  --color-green-panel-heading-color: var(--color-purple1);

  --color-blue1:        #3653D9;
  --color-blue2:        #748BF7;
  --color-blue3:        #BAC4FB;
  --color-blue4:        #DBE0FD;
  --color-blue-panel-background:    var(--color-blue4);
  --color-blue-panel-text-color:    #333333;
  --color-blue-panel-heading-color: var(--color-purple1);

  --color-yellow1:      #C38900;
  --color-yellow1-plus: #946800;
  --color-yellow2:      #F2C937;
  --color-yellow3:      #FDEBB2;
  --color-yellow4:      #FFF2C8;
  --color-yellow-panel-background:    var(--color-yellow4);
  --color-yellow-panel-text-color:    #333333;
  --color-yellow-panel-heading-color: var(--color-purple1);

  --color-black:        #000000;
  --color-black-panel-background:     #000000;
  --color-black-panel-text-color:     #FFFFFF;
  --color-black-panel-heading-color:  #FFFFFF;

  --color-grey-panel-background:      #D0D0D0;
  --color-grey-panel-text-color:      #000000;
  --color-grey-panel-heading-color:   #000000;

  --color-darkgrey:     #333333;
  --color-greycaption:  #545454;
  --color-grey:         #919191;
  --color-lightgrey:    #BCBCBC;

  --color-purple-cta-background:       linear-gradient(270deg, #5F0EAE 0%, #9255E2 100%);

/*  --color-purple-cta-background-hover: linear-gradient(270deg, var(--color-purple1) 0%, var(--color-purple1) 100%);*/
/*  --color-purple-cta-background-hover: var(--color-purple1);*/
/*  --color-purple-cta-background-hover: linear-gradient(270deg, rgba(var(--color-purple1), 1) 0%, rgba(var(--color-purple1), 1) 100%);*/
  --color-purple-cta-background-hover: linear-gradient(270deg, #5F0EAE 0%, #5F0EAE 100%);

  --color-purple-cta-textcolor:        #FFFFFF;

  --color-green-cta-background:        var(--color-green-plus);
  --color-green-cta-background-hover:  var(--color-green); /* FIXME */
  --color-green-cta-textcolor:         #FFFFFF;

  --color-blue-cta-background:         #0572D1; /*var(--color-blue1);*/
  --color-blue-cta-background-hover:   var(--color-blue1); /* FIXME */
  --color-blue-cta-textcolor:          #FFFFFF;

  --color-yellow-cta-background:       var(--color-yellow1-plus);
  --color-yellow-cta-background-hover: var(--color-yellow1); /* FIXME */
  --color-yellow-cta-textcolor:  #FFFFFF;


  --color-theme:   var(--color-purple1);
}


  .spc-button
, .wh-form__button--cta /* button in CTA widget */
, .wh-form__button--next
, .wh-form__button--submit
, .fwitem__detailslink
, .fwitem__providerlink
{
/*padding-left: var(--button-padding-side-withicon);*/
  padding-left: 10px !important;
}


/* External link symbol */
.fwitem__providerlink::before
{
  flex:   none;
  width:  26px;
  height: 26px;
  border-radius: 50%;

  background-color: #FFFFFF;
  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  font: var(--fontawesome-regular);
  content: var(--fa-arrow-right);
  transform: rotate(-45deg);

  color: var(--color-theme);
}






  .wh-form__button--next.wh-form__button--next.wh-form__button--next
, .wh-form__button--submit.wh-form__button--submit.wh-form__button--submit
{
  justify-content: initial;
}

/* override default forward icon */
  .wh-form__button--next::after
, .wh-form__button--submit::after
{
  display: none;
}

  html
, .wh-form__button--purple
, .spc-button--theme-purple
, .wh-form__button--cta /* button in CTA widget */
, .wh-form__button--next
, .wh-form__button--submit
{
  --button-background:          var(--color-purple-cta-background);
  --button-text-color:          var(--color-purple-cta-textcolor);
  --button-border:              1px solid transparent; /*var(--color-purple-cta-background);*/
  --button-icon-background:     #FFFFFF;
  --button-icon-color:          var(--color-purple1);

  --button-hover-background:    var(--color-purple-cta-background-hover);
  --button-hover-text-color:    var(--color-purple-cta-textcolor);
  --button-hover-border:        1px solid var(--color-purple1);
  --button-hover-icon-background:     #FFFFFF;
  --button-hover-icon-color:          var(--color-purple1);

  --button-underline-color:     var(--color-purple-cta-background);

  --textbutton-color:           var(--color-purple1);
  --textbutton-hover-color:     #000000;
}


/* Purple outline */
  .spc-button--outline
, .wh-form__button--outline
, .wh-form__buttongroup .wh-form__button--previous
, .effecttool__prevbutton
{
  --button-background:            #FFFFFF;
  --button-text-color:            var(--color-purple1);
  --button-border:                2px solid var(--color-purple1); /*var(--color-purple-cta-background);*/
  --button-icon-background:       var(--color-purple1);
  --button-icon-color:            #FFFFFF;

  --button-hover-background:      var(--color-purple-cta-background);
  --button-hover-text-color:      var(--color-purple-cta-textcolor);
  --button-hover-border:          2px solid var(--color-purple1);
  --button-hover-icon-background: #FFFFFF;
  --button-hover-icon-color:      var(--color-purple1);

  --button-underline-color:     var(--color-purple-cta-background);
}



  .wh-form__button--green
, .spc-button--theme-green
{
  --button-background:          var(--color-green-cta-background);
  --button-text-color:          var(--color-green-cta-textcolor);
  --button-border:              1px solid transparent; /*var(--color-purple-cta-background);*/

  --button-hover-background:    var(--color-green-cta-background-hover);
  --button-hover-text-color:    var(--color-green-cta-textcolor);
  --button-hover-border:        1px solid var(--color-green1);

  --button-underline-color:     var(--color-green-cta-background);

  --button-icon-background:     #FFFFFF;
  --button-icon-color:          var(--color-green1);

  --textbutton-color:           var(--color-green1);
  --textbutton-hover-color:     #000000;
}

/*.spc-button--outline.wh-form__button--green*/
.wh-form__button--outline.wh-form__button--green
{
  --button-background:          #FFFFFF;
  --button-text-color:          var(--color-green-plus);
  --button-border:              2px solid var(--color-green1); /*var(--color-purple-cta-background);*/
  --button-icon-background:     var(--color-green1);
  --button-icon-color:          #FFFFFF;

  --button-hover-background:      var(--color-green-cta-background);
  --button-hover-text-color:      var(--color-green-cta-textcolor);
  --button-hover-border:          2px solid transparent;
  --button-hover-icon-background: #FFFFFF;
  --button-hover-icon-color:      var(--color-green1);

  --button-underline-color:     var(--color-purple-cta-background);
}




  .wh-form__button--blue
, .spc-button--theme-blue
{
  --button-background:          var(--color-blue-cta-background);
  --button-text-color:          var(--color-blue-cta-textcolor);
  --button-border:              1px solid transparent; /*var(--color-purple-cta-background);*/

  --button-hover-background:    var(--color-blue-cta-background-hover);
  --button-hover-text-color:    var(--color-blue-cta-textcolor);
  --button-hover-border:        1px solid var(--color-blue1);

  --button-underline-color:     var(--color-blue-cta-background);

  --button-icon-background:     #FFFFFF;
  --button-icon-color:          var(--color-blue1);

  --textbutton-color:           var(--color-blue1);
  --textbutton-hover-color:     #000000;
}

.wh-form__button--outline.wh-form__button--blue
{
  --button-background:          #FFFFFF;
  --button-text-color:          var(--color-blue1);
  --button-border:              2px solid var(--color-blue1); /*var(--color-purple-cta-background);*/
  --button-icon-background:     var(--color-blue1);
  --button-icon-color:          #FFFFFF;

  --button-hover-background:      var(--color-blue-cta-background);
  --button-hover-text-color:      var(--color-blue-cta-textcolor);
  --button-hover-border:          2px solid transparent;
  --button-hover-icon-background: #FFFFFF;
  --button-hover-icon-color:      var(--color-blue1);

  --button-underline-color:     var(--color-purple-cta-background);
}







/*.spc-button--theme-white*/
.consentblocker button
{
  --button-color:               var(--color-white);
  --button-contrastcolor:       var(--rtd-text-color);
  --button-bordercolor:         var(--color-white);
/*
  --button-color-hover:         var(--color-blue-cta-background-hover);
  --button-contrastcolor-hover: var(--color-blue-cta-textcolor);
  --button-bordercolor-hover:   var(--color-blue-cta-background-hover);
*/
  --button-color-hover:         var(--color-blue-cta-background);
  --button-contrastcolor-hover: var(--color-blue-cta-textcolor);
  --button-bordercolor-hover:   var(--color-blue-cta-background);

  --button-icon-color:          var(--button-color);
}





/* Used by
   - "CTA Carrousel" widget
*/
  .colortheme--purple-cta /* FIXME: is still one being used ? */
, .widget-ctacarrousel
{
/*  --currenttheme-background:    var(--color-purple-cta-background);*/
  --currenttheme-background:    linear-gradient(314.29deg, #5F0EAE 0%, #9255E2 100%);
  --currenttheme-color:         var(--color-purple-cta-textcolor);
  --currenttheme-text-color:    var(--color-purple-cta-textcolor);
  --currenttheme-heading-color: var(--color-purple-cta-textcolor);
}

  .pageheader--type-search
, .page--newsoverview2       .pageheader--sidebyside
, .page--eventsoverview      .pageheader--sidebyside
, .page--kennisbank          .pageheader--sidebyside
, .page--financielewegwijzer .pageheader--sidebyside
{
  --header-background:          linear-gradient(180deg, #5F0EAE 0%, #9255E2 100%) !important;
  --currenttheme-background:    linear-gradient(180deg, #5F0EAE 0%, #9255E2 100%) !important;
  --currenttheme-heading-color: #FFFFFF !important;
  --currenttheme-text-color:    #FFFFFF !important;
/*  --header-togglefilter-textcolor: #FFFFFF !important;*/

/*  --header-sidebyside-title-font: clamp(22px,3vw,36px)/135% var(--rtd-heading-fontfamily);*/
  --header-sidebyside-title-font: clamp(22px,3vw,44px)/135% var(--rtd-heading-fontfamily) !important;
  --header-sidebyside-border-radius: 0 0 var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter) !important;
}



  .header-searchpanel-top
, .header-searchpanel-bottom
{
/*  --searchpanel-gridrow-background*/
  --site-searchpanel-background: linear-gradient(180deg, #5F0EAE 0%, #9255E2 100%) !important;

}


/*
  .pageheader--type-search   .header-crumblepath__content
, .page--newsoverview2       .header-crumblepath__content
, .page--eventsoverview      .header-crumblepath__content
, .page--kennisbank          .header-crumblepath__content
, .page--financielewegwijzer .header-crumblepath__content
{
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}
*/


/*
$minwidth-searchbar-sidebyside: 768px;
@media screen and (max-width: $minwidth-searchbar-sidebyside - 1)

For mobile we are a more subtle light purple background
*/
@media screen and (max-width: 767px)
{
    .pageheader--type-search
  , .page--newsoverview2       .pageheader--sidebyside
  , .page--eventsoverview      .pageheader--sidebyside
  , .page--kennisbank          .pageheader--sidebyside
/*  , .page--financielewegwijzer .pageheader--sidebyside*/
  {
    --header-background:      var(--color-purple3) !important;
    --currenttheme-background:  var(--color-purple3) !important;
/*    // --header-sidebyside-title-font: var(--rtd-heading-title);*/
    --header-togglefilter-textcolor: var(--color-purple1) !important;

/*    --rtd-heading-color: var(--color-purple1) !important;*/
    --currenttheme-heading-color: var(--color-purple1) !important;
    --rtd-text-color: var(--color-purple1) !important;
  }

  .page--financielewegwijzer .pageheader__title + .pageheader__text__description
  {
    margin-top: 15px !important;
  }
}



@media (min-width: 768px)
{
  .page--newsoverview2 .pageheader--sidebyside .pageheader__text
  {
    text-align: center;
  }
}

@media (max-width: 600px)
{
  .pageheader--type-search
  {
  /*  --currenttheme-background:    var(--color-purple-cta-background);*/
    --currenttheme-background:    linear-gradient(314.29deg, #5F0EAE 0%, #9255E2 100%);
    --currenttheme-color:         var(--color-purple1);
    --currenttheme-text-color:    var(--color-purple1);
    --currenttheme-heading-color: var(--color-purple1);
  }
}


/* Inerted solid button */
  .colortheme--purple-cta .wh-form__button--solid
, .widget-ctacarrousel .wh-form__button--cta
{
  --button-background:            #FFFFFF;
  --button-text-color:            var(--color-purple1);
  --button-border:                none;
  --button-icon-background:       linear-gradient(314.29deg, #5F0EAE 0%, #9255E2 100%);
  --button-icon-color:            #FFFFFF;

  --button-hover-background:      #FFFFFF;
  --button-hover-text-color:      var(--color-purple1);
  --button-hover-border:          none;
  --button-hover-icon-background: linear-gradient(314.29deg, #5F0EAE 0%, #9255E2 100%);
  --button-hover-icon-color:      #FFFFFF;

  --button-underline-color:     var(--color-purple-cta-background);
}

  .colortheme--purple-cta .wh-form__button--solid:focus
, .widget-ctacarrousel .wh-form__button--cta:focus
, .colortheme--purple-cta .wh-form__button--solid:hover
, .widget-ctacarrousel .wh-form__button--cta:hover
{
  outline: 2px solid #FFFFFF;
  outline-offset: 4px;
}


/*
  .colortheme--purple-cta .wh-form__button.wh-form__button
, .widget-ctacarrousel .wh-form__button.wh-form__button
{
  --button-color:               #FFFFFF;
  --button-contrastcolor:       var(--color-purple1);
  --button-bordercolor:         #FFFFFF;

  --button-color-hover:         var(--color-purple1);
  --button-contrastcolor-hover: #FFFFFF;
  --button-bordercolor-hover:   #FFFFFF;

  --button-icon-color:          #FFFFFF;
/ * linear-gradient(314.29deg, #5F0EAE 0%, #9255E2 100%); * /
}
*/



  .colortheme--purple
, .pageheader--imagestickingout
, .page--event .pageheader--sidebyside
{
  --currenttheme-background:    var(--color-purple-panel-background);
  --currenttheme-color:         var(--color-purple-panel-text-color);
  --currenttheme-text-color:    var(--color-purple-panel-text-color);
  --currenttheme-heading-color: var(--color-purple-panel-heading-color);

  --component-tag-border-color: transparent;
  --component-tag-text-color:   var(--color-theme);
}

  .colortheme--blue.colortheme--blue
, .pageheader--sidebyside
{
  --currenttheme-background:    var(--color-blue-panel-background);
  --currenttheme-color:         var(--color-blue-panel-text-color);
  --currenttheme-text-color:    var(--color-blue-panel-text-color);
  --currenttheme-heading-color: var(--color-blue-panel-heading-color);

  --header-sidebyside-pagedate-color: var(--color-purple-panel-heading-color);
}

.colortheme--green.colortheme--green
{
  --currenttheme-background:    var(--color-green-panel-background);
  --currenttheme-color:         var(--color-green-panel-text-color);
  --currenttheme-text-color:    var(--color-green-panel-text-color);
  --currenttheme-heading-color: var(--color-green-panel-heading-color);
}

.colortheme--yellow.colortheme--yellow
{
  --currenttheme-background:    var(--color-yellow-panel-background);
  --currenttheme-color:         var(--color-yellow-panel-text-color);
  --currenttheme-text-color:    var(--color-yellow-panel-text-color);
  --currenttheme-heading-color: var(--color-yellow-panel-heading-color);
}
/*
.pageheader--imagestickingout
{
  --currenttheme-background:    var(--);
  --currenttheme-color:         var(--rtd-text-color);
  --currenttheme-text-color:    var(--rtd-text-color);
  --currenttheme-heading-color: var(--rtd-heading-color);
}
*/
  .colortheme--transparent
, .colortheme--no-background
, .pageheader--type-zvi-home
/*, .pageheader--sidebyside*/
{
  /* FIXME: pageheader overrides --rtd-text- and --rtd-heading ... so we then use these values here... */
  --currenttheme-background:    transparent;
  --currenttheme-color:         var(--rtd-text-color);
  --currenttheme-text-color:    var(--rtd-text-color);
  --currenttheme-heading-color: var(--color-purple1); /* var(--rtd-heading-color); */
}

.pageheader--type-zvi-home
{
  --header-sidebyside-margin-above: 57px;
  --header-sidebyside-text-padside: 0;
/*  --header-sidebyside-image-aspectratio: auto !important;*/
/*  --header-sidebyside-image-grid-column: 13 / 27; / * 15/27 normally * /*/

/*  --header-sidebyside-title-font: 50px/58px var(--rtd-heading-fontfamily) !important;*/
  --header-sidebyside-title-font: clamp(22px,3vw,50px)/120% var(--rtd-heading-fontfamily);
  --rtd-text-font: 17px/28px var(--rtd-text-fontfamily);
  --header-background: transparent !important;
}
.pageheader__title + p
{
  --rtd-spacing-paragraph: 14px;
}
.pageheader--type-zvi-home .widget-button
{
  --rtd-spacing-paragraph: 25px;
}
@media (max-width: 550px)
{
  .pageheader--type-zvi-home
  {
    --header-sidebyside-margin-above: 0px;
    --header-sidebyside-title-font: 24px var(--rtd-heading-fontfamily) !important;
    --rtd-text-font: 16px/24px var(--rtd-text-fontfamily);
  }
}



.colortheme--black.colortheme--black
{
  --currenttheme-background:    var(--color-black-panel-background);
  --currenttheme-color:         var(--color-black-panel-text-color);
  --currenttheme-text-color:    var(--color-black-panel-text-color);
  --currenttheme-heading-color: var(--color-black-panel-text-color);
}

/* FIXME: remove ? */
.colortheme--grey.colortheme--grey
{
  --currenttheme-background:    var(--color-grey-panel-background);
  --currenttheme-color:         var(--color-grey-panel-text-color);
  --currenttheme-text-color:    var(--color-grey-panel-text-color);
  --currenttheme-heading-color: var(--color-grey-panel-text-color);
}

/* FIXME: remove ? */
.colortheme--shadowpanel.colortheme--shadowpanel
{
  --currenttheme-background: var(--contentpanels-background);
  --currenttheme-boxshadow:  var(--contentpanels-box-shadow);
  --currenttheme-text-color: var(--rtd-text-color);
  --currenttheme-heading-color: var(--rtd-heading-color);
}


.header-menubar.header-menubar
{
  padding: 0;
}

.header-menubar__content
{
  padding-top: var(--site-menubar-padding-top);
  padding-bottom: var(--site-menubar-padding-bottom);

  min-height: clamp(45px, 10vw, 100px);
}

/*.header-top*/
