.wh-requireconsent {
  position: relative;
  z-index: 0;
}

.wh-requireconsent__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 300ms;
}

.widget-spcvideo .wh-requireconsent__overlay {
  position: relative !important;
}

.wh-requireconsent__overlay[hidden] {
  visibility: hidden;
}

.wh-requireconsent__overlay:hover,
.wh-requireconsent__overlay:focus-within {
  opacity: 1;
}

html[data-wh-consent] .wh-requireconsent__overlay:not([hidden]),
html[data-wh-consent] .widget--slideshare .consentblocker,
html[data-wh-consent] .widget--image .consentblocker,
html[data-wh-consent] .widget--video .consentblocker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background-color: var(--contentblocker-background);
  color: var(--contentblocker-text-color);
  display: flex;
  align-items: center;
  text-align: center;
  transition: opacity 0.25s;
}

html:not([data-wh-consent]) .wh-requireconsent__overlay:not([hidden]),
html:not([data-wh-consent]) .widget--slideshare .consentblocker,
html:not([data-wh-consent]) .widget--image .consentblocker,
html:not([data-wh-consent]) .widget--video .consentblocker {
  display: none;
}

.consentblocker__title {
  font: var(--contentblocker-heading-font);
}

.consentblocker__message {
  font: var(--contentblocker-text-font);
}

.consentblocker__acceptcookies {
  margin-top: 25px;
  font-weight: normal;
}

.consentblocker__acceptcookies.consentblocker__acceptcookies:hover {
  --button-bordercolor: #FFFFFF;
  --button-color: #FFFFFF;
  --button-contrastcolor: #000000;
}

/*
.consentblocker__acceptcookies::after
{
  font: var(--fontawesome-regular);
  font-size: 20px;
  content: var(--fa-long-arrow-right);
  margin-left: 20px;
}
*/
.widget--image .consentblocker,
.widget--video .consentblocker {
  opacity: 0;
}

.consentblocker {
  width: 100%;
  text-align: center;
  padding: 5% 10%;
}

/* ensure the icon doesn't get underlined */
.consentblocker button {
  display: inline-block;
}

.consentblocker button::before {
  display: inline-block;
}

.consentblocker__acceptcookies {
  padding-left: 15px;
  padding-right: 20px;
}

.consentblocker__acceptcookies::before {
  position: relative;
  top: 2px;
  font: var(--fontawesome-regular);
  font-size: 20px;
  content: var(--fa-check);
  margin-right: 15px;
}

.consentblocker__acceptcookies:hover::before {
  text-decoration: none;
  color: #00AA00;
}

.widget--image .consentblocker:hover,
.widget--slideshare .consentblocker:hover,
.widget--video .consentblocker:hover {
  opacity: 1;
}

.widget--image__image {
  z-index: 0;
}