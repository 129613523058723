/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
@media (min-width: 768px) {
  html {
    --acoifilterpage-topbar-margin: 40px;
  }
}
@media (max-width: 767px) {
  html {
    --acoifilterpage-topbar-margin: 20px;
  }
}

.acoifilterpage-filters__filters,
.acoifilterpage__content,
.fwitem {
  outline-offset: 6px;
}

.page--search .acoifilterpage::before {
  content: "";
  grid-row: 6;
  grid-column: 1/4;
  border-top: 1px solid var(--color-purple3);
}
.page--search .acoifilterpage-filters__filters {
  padding-top: 20px;
}

.pageheader--type-search .acoifilterpage .header-crumblepath__content,
.page--newsoverview2 .acoifilterpage .header-crumblepath__content,
.page--eventsoverview .acoifilterpage .header-crumblepath__content,
.page--kennisbank .acoifilterpage .header-crumblepath__content,
.page--financielewegwijzer .acoifilterpage .header-crumblepath__content {
  grid-column: 2/7;
}

.notcurrentpage {
  display: none !important;
}

.acoifilterpage--withtopbar {
  margin-top: 0 !important;
}

.pageheader ~ .page__body .page__contentarea .acoifilterpage--withtabsbar {
  margin-top: 0 !important; /* always stick to the header */
}

/*
.acoifilterpage:not(.acoifilterpage--withtabsbar)
{
  margin-top: 10px;
}
*/
.acoifilterpage__topbar {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.acoifilterpage__backlink {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--color-theme);
  font: var(--rtd-text-font);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: none;
}
html.page--publicationsoverview-hidelanding .acoifilterpage__backlink {
  display: block;
}

.acoifilterpage__backlink:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.acoifilterpage__backlink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-left);
  margin-right: 20px;
}

.page--publicationsoverview .pageheader__text {
  grid-column: var(--pagegrid-col-verywide) !important;
}

.acoifilterpage-filters > .acoifilterpage-filters__header {
  align-self: center;
}

.acoifilterpage-filters__filters > .acoifilterpage-filters__header .acoifilterpage-filters__filters__title {
  margin-top: 20px;
}

.acoifilterpage-filters__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

/* we only show the header reset button when using in the dialog.
   When used within the normal .page__body / .acoifilterpage whe don't need it.
*/
.page__body .acoifilterpage-filters__header .filteredoverview-resetbutton {
  display: none;
}

.acoifilterpage-filters__topbar label {
  color: var(--color-theme);
  font-size: 17px;
  margin-right: 12px;
}

.acoifilterpage-filters {
  margin: 0;
  margin-top: 0 !important;
}

.acoifilterpage__results .searchresult.notmatching {
  display: none;
}

.acoifilterpage__results + * {
  margin-top: 23px;
}

.acoifilterpage {
  --acoifilterpage-whitespace-left: 0;
  --acoifilterpage-whitespace-right: 0;
  --pagegrid-filterpage-template-columns:
                   max(15px, calc((100% - 1380px) / 2))
                   var(--acoifilterpage-whitespace-left)
                   var(--acoifilterpage-sidebar-columndef) /* sidebar */
                   52px /* gutter */
                   3fr /* content */
                   var(--acoifilterpage-whitespace-right)
                   max(15px, calc((100% - 1380px) / 2));
}
@media (min-width: 1200px) {
  .acoifilterpage {
    --acoifilterpage-whitespace-right: min(7%, 295px);
  }
}

.page--financielewegwijzer .acoifilterpage {
  /*  --acoifilterpage-sidebar-preferredwidth: 286px;*/
  --filterpage-filtergroup-padding: 15px 12px;
  /*  --acoifilterpage-sidebar-columndef: minmax(min-content, min(30%, 300px));*/
  /* don't stretch to min-content here - our multiselects will stretch with the selected option */
  --acoifilterpage-sidebar-columndef: min(30%, 300px);
  --textcontrols-font: 16px var(--rtd-text-fontfamily);
  --textcontrols_textcolor: #333333;
}
@media (max-width: 1099px) {
  .page--financielewegwijzer .acoifilterpage {
    --acoifilterpage-whitespace-left: 15px;
    --acoifilterpage-whitespace-right: 15px;
  }
}
@media (min-width: 1100px) {
  .page--financielewegwijzer .acoifilterpage {
    --acoifilterpage-whitespace-left: 77px;
    --acoifilterpage-whitespace-right: 77px;
  }
}

/********************************************************
**
**  Desktop generic page layout
*/
@media (min-width: 768px) {
  /*.page--publicationsoverview .page__contentarea*/
  .acoifilterpage {
    /*--acoifilterpage-sidebar-columndef: minmax(338px, 1fr);*/
    --acoifilterpage-sidebar-preferredwidth: 250px;
    --acoifilterpage-sidebar-columndef: minmax(min-content, min(30%, var(--acoifilterpage-sidebar-preferredwidth) ));
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-filterpage-template-columns);
    grid-template-rows: min-content min-content min-content min-content var(--acoifilterpage-tabs-marginbottom) minmax(var(--acoifilterpage-feedbackbar-height), min-content) 1fr; /* results */
    align-items: start;
    --pagegrid-col-withsidebar-sidebar: 3;
    --pagegrid-col-withsidebar-content: 5;
    --pagegrid-col-withsidebar-tabs: 5 / span 2;
    --pagegrid-col-withsidebar-verywide-outsidegutter: 2 / 7;
  }
  .acoifilterpage-filters__filters {
    position: sticky;
    top: 130px;
  }
  .acoifilterpage {
    /*
    .header-crumblepath__background
    {
      grid-row: 1 / span 2;
      grid-column: 1 / -1;
      border-bottom: 1px solid #F00;
    }
    */
    /* Page-header nested in the filterpage layout */
  }
  .acoifilterpage .header-crumblepath {
    display: contents;
  }
  .acoifilterpage .header-crumblepath-hr {
    grid-row: 1;
    grid-column: 1/8;
    width: 100%;
    align-self: end;
    margin: 0;
    height: 0;
    padding: 0;
    border: 0;
    border-bottom: 1px solid var(--color-purple3);
  }
  .acoifilterpage .header-crumblepath__content {
    grid-row: 1;
    grid-column: var(--pagegrid-col-withsidebar-content);
    height: 55px;
  }
  .acoifilterpage .pageheader {
    grid-row: 2;
  }
  .acoifilterpage .pagetabs {
    display: contents;
  }
  .acoifilterpage .pagetabs__background {
    grid-row: 4;
    grid-column: 1/-1;
    border-bottom: 1px solid var(--color-purple3);
    height: 100%;
  }
  .acoifilterpage .pagetabs--tabs.pagetabs--tabs .pagetabs__content {
    grid-row: 4;
    grid-column: var(--pagegrid-col-withsidebar-tabs);
  }
  .acoifilterpage .acoifilterpage-custombar {
    display: contents;
  }
  .acoifilterpage .acoifilterpage-custombar > * {
    grid-row: 4;
  }
  .acoifilterpage .acoifilterpage-filters > .acoifilterpage-filters__header {
    grid-column: var(--pagegrid-col-withsidebar-sidebar);
    grid-row: 6;
  }
  .acoifilterpage .acoifilterpage-filters__filters {
    grid-column: var(--pagegrid-col-withsidebar-sidebar);
    grid-row: 7;
    align-self: start;
  }
  .acoifilterpage .acoifilterpage-filters__filters--includeheader {
    grid-row: 4/span 2;
  }
  .acoifilterpage .acoifilterpage__content {
    grid-column: var(--pagegrid-col-withsidebar-content);
    grid-row: 7;
    /* prevent page stretching and flex layouts stretching */
  }
  .acoifilterpage-filters {
    display: contents; /* we want to layout the .pagetabs, __header, __filters and __topbar withinin our grid */
  }
  .acoifilterpage-filters > .acoifilterpage-filters__header,
  .acoifilterpage-filters__topbar {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .page--search .acoifilterpage__content {
    padding-top: 20px;
  }
  /**************/
  .acoifilterpage-filters__topbar {
    grid-column: var(--pagegrid-col-withsidebar-content);
    grid-row: 5;
    display: flex;
    align-items: baseline;
    margin-bottom: var(--acoifilterpage-topbar-margin);
  }
  .acoifilterpage-filters__topbar > .filteredoverview__feedbackandviewbar {
    flex: 1 1 auto;
  }
  /*
    .acoifilterpage-filters__topbar > :first-child:last-child
    {
      margin-left: auto;
    }
    .acoifilterpage-filters__topbar > .filteredoverview__feedbackandviewbar + *
    {
      margin-left: 20px;
    }
  */
  .filteredoverview__feedback + .filtertags__items {
    margin-top: 20px;
  }
  .acoifilterpage-custombar > * {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .acoifilterpage-custombar > hr {
    grid-column: 1/-1;
    width: 100%;
    margin: 0;
    align-self: end;
    border: 0;
    border-bottom: 1px solid var(--color-purple3);
  }
}
/* Make tabs go full viewport width earlyer */
@media (max-width: 1000px) {
  /* FIXME: autodetect tabsbar not fitting right part and going fullw dith */
  .pagetabs--tabs.pagetabs--tabs .pagetabs__content {
    grid-column: 3/-3 !important;
  }
  /* NOTE: don't target a next subling (+) because in mobile some elements will be hidden */
  .page--newsoverview2 .acoifilterpage-filters__topbar {
    margin-top: 15px;
  }
}
/* Remove the empty space at the right */
@media (max-width: 1300px) {
  .acoifilterpage {
    --acoifilterpage-emptyness-columndef: 0px;
  }
}
/********************************************************
**
**  Mobile (and small tablets) layout
*/
@media (max-width: 767px) {
  .acoifilterpage {
    --acoifilterpage-emptyness-columndef: 0;
  }
  .acoifilterpage .header-crumblepath {
    display: contents;
  }
  .acoifilterpage .header-crumblepath__content {
    grid-row: 1;
    grid-column: var(--pagegrid-col-verywide);
    height: 55px;
  }
  .acoifilterpage .header-crumblepath__content {
    grid-column: var(--pagegrid-col-verywide) !important;
  }
  .acoifilterpage-filters {
    /*
        grid-column: var(--pagegrid-col-fullwidth);
        display: grid;
        grid-template-columns: var(--pagegrid-template-columns);

        // row-gap: 20px;
    */
    display: contents;
    --formcontrols-height: 40px;
  }
  .acoifilterpage-filters > .pageheader {
    grid-row: 2;
  }
  .acoifilterpage-filters > .acoifilterpage-filters__header,
  .acoifilterpage-filters__topbar {
    margin-top: 15px;
    margin-bottom: var(--acoifilterpage-topbar-margin);
  }
  .page--search .acoifilterpage::before {
    grid-column: 1/-1;
  }
  .page--search .acoifilterpage__content {
    padding-top: 20px;
  }
  /**************/
  /*
    .acoifilterpage-filters__topbar
    {
      margin-bottom: var(--acoifilterpage-topbar-margin);
    }
  */
  .acoifilterpage-filters__filters,
  .acoifilterpage-filters__topbar {
    grid-column: var(--pagegrid-col-verywide);
  }
  .filteredoverview__feedback i,
  .filteredoverview__feedbackandviewbar__content .filtertags__items {
    display: none;
  }
  .acoifilterpage-filters__filters {
    --component-tag-height: var(--formcontrols-height);
    --component-tag-padding: 4px 12px;
    --component-tag-font: 15px/22px var(--rtd-text-fontfamily);
  }
  .acoifilterpage-filters__filters .comp-togglebuttonbar {
    column-gap: 10px;
  }
  .acoifilterpage__results.acoifilterpage__results {
    --component-summary-padding-v: 20px;
  }
  .acoifilterpage-custombar {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 4;
    display: flex;
    flex-direction: column;
  }
  .financielewegwijzer-topbar__query {
    width: 100%;
  }
  .financielewegwijzer-topbar__resultdata.financielewegwijzer-topbar__resultdata {
    margin-top: 15px;
    display: flex;
    width: 100%;
  }
  .filteredoverview__feedback {
    flex: 1 0 0;
    min-width: 0;
  }
  .downloadresultsbutton {
    margin-left: 30px;
  }
  .acoifilterpage__content {
    grid-column: var(--pagegrid-col-withsidebar-content);
    grid-row: 7;
    /* prevent page stretching and flex layouts stretching */
  }
}
@media (max-width: 767px) {
  .acoifilterpage {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .header-crumblepath-hr {
    display: none;
  }
  .acoifilterpage.acoifilterpage--hidefilters-on-mobile .pagetabs,
  .acoifilterpage.acoifilterpage--hidefilters-on-mobile .acoifilterpage-filters__filters,
  .acoifilterpage-filters > .acoifilterpage-filters__header {
    position: absolute; /* take out of flow so a row-gap won't be applied */
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }
  .acoifilterpage__content.acoifilterpage__content {
    grid-column: var(--pagegrid-col-verywide);
  }
  .acoifilterpage__results.acoifilterpage__results {
    --component-summary-padding-v: 13px;
  }
}
.filteredoverview-resetbutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #767676;
  font: var(--rtd-text-small-font);
  text-decoration: underline;
  cursor: pointer;
}