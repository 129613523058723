html
{
  /************************************************************
   *
   *  Form controls / generic settings
   */

  --formcontrols-themecolor: var(--color-theme);


  /************************************************************
   *
   *  Page components / Buttons
   */

  --button-borderradius:      23px;

  --button-fontfamily:        var(--rtd-heading-fontfamily);
  --button-font:              16px/22px var(--button-fontfamily);
  /*--button-height:            50px;*/
  --button-disabled-backgroundcolor: #AAAAAA;
  --button-text-alignment-tweak:  2px; /* because of Officina font */

  --button-textbutton-font:  var(--button-font);


  --button-height:                46px;

  --button-padding-v:             6px;   /* in case the button text goes multiline we need padding to keep some whitespace */
  --button-padding-side-noicon:   21px;
  --button-padding-side-withicon: 11px;


  --button-large-height: 52px;
}

.page-backlink
{
  display: flex;
  align-items: center;

  --component-tag-text-color: var(--color-theme) !important;

  color: var(--component-tag-text-color);
  background: var(--component-tag-background);
  border-radius: var(--component-tag-radius);
  height: var(--component-tag-height);
  padding: var(--component-tag-padding);

  font: 17px var(--rtd-text-fontfamily);

  text-decoration: none;

/*  align-items: center;*/
  --button-padding-side-noicon: 15px;
}

.page-backlink::before
{
  font: var(--fontawesome-solid);
  content: var(--fa-chevron-left);

  font-size: 13px;
  vertical-align: middle;

  margin-right: 15px;
}

.page-backlink:hover
{
  text-decoration: underline;
}



/* a CTA button (from a widget) is a little larger */

.header-menubar__ctabutton
{
  /*--button-height: 57px;*/
  --button-padding-side-noicon:   30px;
  --button-padding-side-withicon: 19px;
  --button-font:  19px var(--rtd-text-fontfamily);
}

.footer__panel .widget-button a
{
  /*padding-left: var(--button-padding-side-noicon);*/
  padding-left: 27px;
  padding-right: 27px;
}

@media (max-width: 550px) /* $minwidth-desktop-fontsizes - 1px)*/
{
  html
  {
    --button-font: 500 16px/22px var(--button-fontfamily);
  }

  .footer__panel .widget-button a
  {
    padding-left: 26px;
    padding-right: 23px;
  }
  .footer__panel .widget-button a::after
  {
    margin-left: 15px;
  }

    .widget-button /* CTA button */
  , .widget-vacancy__link
  {
    --button-height: 45px;
    --button-large-height: 45px;
  }
}


.wh-form__fieldgroup--custom-info
{
  border-left: 4px solid var(--color-theme);
}


html
{
  /* "Velden met een sterretje(*) zijn verplicht om in te vullen." */
  --forms-infopanel-background: var(--color-purple3);
  --forms-infopanel-color:      #121212;
  --forms-infopanel-font:       var(--rtd-table-font);
  --forms-infopanel-padding:    16px 18px;

  --forms-infopanel-icon-font:  var(--fontawesome-solid);
  --forms-infopanel-icon-size:  20px;


  --formlayout-fieldgroup-info-color: var(#333333);
  --formlayout-fieldgroup-info-font:  var(--common-caption-font);



  /************************************************************
   *
   *  Form layout
   */

  --formlayout-label-width:   200px;
  --formlayout-label-color:   var(--color-theme);
  --formlayout-label-font:    600 19px/28px var(--rtd-text-fontfamily);
  --formlayout-label-spacing-h: 25px; /* horizontal spacing for side-by-side layout (label next to field) */
  --formlayout-label-spacing-v:  4px; /* vertical spacing for stacked layout (label above field) */

  --formlayout-fieldgroup-bgcolor-error: #FDE6E8;

  --formlayout-fieldgroup-padding-h: 16px;
  --formlayout-fieldgroup-padding-v: 11px;
  --formlayout-fieldgroup-padding:   var(--formlayout-fieldgroup-padding-v) var(--formlayout-fieldgroup-padding-h);
  --formlayout-fieldgroup-spacing: 0px;

  --formlayout-fieldgroup-error-textcolor: #D40819; /* var(--color-salmon-formcontrols-error-border); */
/*  --formlayout-fieldgroup-error-font: var(--formlayout-label-font);*/
  --formlayout-fieldgroup-error-font: 16px/28px var(--rtd-text-fontfamily);

  --formlayout-fieldlines-spacing: 20px;

  --formlayout-option-label-spacing-h: 12px;

  --formlayout-buttongroup-margin: 40px 0 0 0;
  --formlayout-buttongroup-border: 2px solid var(--color-theme);
  --formlayout-buttongroup-border-margin: 30px;


  /************************************************************
   *
   *  Form controls (textual fields)
   */

  /* Normal height for single-line controls */
  --formcontrols-height:             48px;
  --formcontrols-radius:              4px;

  --formcontrols-focus-boxshadow:       0 0 0 3px #540A5130;
  --formcontrols-focus-outline:         2px solid #0000AA;
  --formcontrols-focus-outline-offset:  4px;

  --formcontrols-border-width:          1px;
  --formcontrols-border-width-focus:    2px;

  --formcontrols-border-color:          #767676;
  --formcontrols-border-color-disabled: #C5C5C5;
  --formcontrols-border-color-error:    #D40819; /* var(--color-salmon-formcontrols-error-border); */
  --formcontrols-border-color-focus:    var(--color-purple-dark);

  --formcontrols-textcolor-error:       #121212; /* we make the area around it red and keep the text black */

  --formcontrols-backgroundcolor-error: #FFFFFF;



  --formcontrol-checkradio-label-color: var(--rtd-text-color);


  /* NOTE: if the font-size is below 16px iOS will zoom into the control when it recieves focus */
  --textcontrols-font:                 19px var(--rtd-text-fontfamily);
  --textcontrols_placeholdercolor:     #767676;
  --textcontrols-bgcolor:              #FFFFFF;
  --textcontrols_textcolor:            #333333;

  --textcontrols_disabled_bgcolor:     #F5F5F5;
 /*--textcontrols_disabled_bordercolor: #989898;*/
  --textcontrols_disabled_placeholder: #707070; /* 4.5:1 contrast on #F5F5F5 */
  --textcontrols_disabled_textcolor:   #707070; /* 4.5:1 contrast on #F5F5F5 */

  --textcontrols_padleft:         15px;
  --textcontrols_padright:        15px;
  --textcontrols_padleft-mobile:  10px;
  --textcontrols_padright-mobile: 10px;

  --controls-icon-color: var(--color-theme);


    /************************************************************
     *
     *  Form controls (default icons)
     */

    --formcontrol-icon-delete-font: 400 18px 'Font Awesome 5 Pro';
    --formcontrol-icon-delete-icon: var(--fa-trash-alt);


    /************************************************************
     *
     *  Form controls (checkbox and radio buttons)
     */

      /* Shared checkbox & radiobutton settings */
      --formcontrol-checkradio-size:                24px;
      --formcontrol-checkradio-bordercolor:         #767676;
      --formcontrol-checkradio-borderwidth:         1px;
      --formcontrol-checkradio-borderradius:        4px;

      /* Hover */
      /* Color for subtly showing the checkmark/bullet symbol upon hover */
      --formcontrol-checkradio-hover-color:         #FFFFFF;
      --formcontrol-checkradio-hover-bgcolor:       #FFFFFF;
      --formcontrol-checkradio-hover-bordercolor:   var(--formcontrols-themecolor);
      /*--formcontrol-checkradio-hover-boxshadow: 0 0 0 3px rgba(40,52,119,0.14);*/
      --formcontrol-checkradio-hover-boxshadow: 0 0 0 4px rgba(84,10,81,0.12);

      /* Checked radio button (white background) */
      --formcontrol-checkradio-checked-color:       #FFFFFF;
      --formcontrol-checkradio-checked-bgcolor:     var(--formcontrols-themecolor);
      --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
      --formcontrol-radio-checked-color:            var(--formcontrols-themecolor);


      /************************************************************
       *
       *  Form controls (checkbox)
       */

      --formcontrol-check-font:    700 15px/24px var(--fontawesome);
      --formcontrol-check-content: '\f00c'; /* checkmark in FA5 */
      /*
      --formcontrol-check-content: "\e931"; UT-icon-UT_icon_50_check-16px
      --formcontrol-check-content: "✓"; // checkmark
      --formcontrol-check-content: "✗"; // ballot X
      --formcontrol-check-content: "✕"; // multiplication
      */

      --formcontrol-check-shift:                        0;

      /* Disabled checkbox */
      --formcontrol-check-disabled-bgcolor:             #F5F5F5;
      --formcontrol-check-disabled-bordercolor:         #C5C5C5;
      --formcontrol-check-disabled-borderwidth:         1px;
      --formcontrol-check-disabled-iconcolor:           transparent;

      --formcontrol-check-disabled-checked-bgcolor:     #F2F2F2;
      --formcontrol-check-disabled-checked-bordercolor: #BBBBBB;
      --formcontrol-check-disabled-checked-iconcolor:   #BBBBBB;


      /************************************************************
       *
       *  Form controls (radio)
       */

      --formcontrol-radio-shift:                        0px;
      --formcontrol-radio-innersize:                    12px;

      --formcontrol-radio-disabled-bgcolor:             #F5F5F5;
      --formcontrol-radio-disabled-bordercolor:         #C5C5C5;
      --formcontrol-radio-disabled-borderwidth:         1px;
      --formcontrol-radio-disabled-iconcolor:           transparent;

      --formcontrol-radio-disabled-checked-bordercolor: #BBBBBB;
      --formcontrol-radio-disabled-checked-bgcolor:     #BBBBBB;
      --formcontrol-radio-disabled-checked-iconcolor:   #BBBBBB;

      /* Focus */
      --formcontrol-checkradio-focus-bordercolor:   #FFFFFF; /* create contrast to the box shadow */
      --formcontrol-checkradio-focus-boxshadow:     0 0 2px 3px rgb(44, 108, 214);



      /************************************************************
       *
       *  Form controls (checkbox / iOS switch style
       */

      /*
      4TU
      --component-checkbox-switch-borderwidth:   3px;
      --component-checkbox-switch-track-width:  55px;
      --component-checkbox-switch-track-height: 24px;
      --component-checkbox-switch-track-color:  #A4A4A4;
      --component-checkbox-switch-track-color-disabled: #A4A4A4;
      --component-checkbox-switch-track-color-active: var(--color-orange);
      --component-checkbox-switch-thumb-size:   18px;
      --component-checkbox-switch-thumb-color:  #FFFFFF;
      */

      --component-checkbox-switch-borderwidth:  4px;
      --component-checkbox-switch-track-width:  75px;
      --component-checkbox-switch-track-height: 35px;
      --component-checkbox-switch-track-color:  #A4A4A4;
      --component-checkbox-switch-track-color-disabled: #A4A4A4;
      --component-checkbox-switch-track-color-active: var(--color-purple);
      --component-checkbox-switch-thumb-size:   27px;
      --component-checkbox-switch-thumb-color:  #FFFFFF;
}

/* Smaller checkboxes for lists with many options */
.acoifilterpage-filters__filters
{
  /* Shared checkbox & radiobutton settings */
  --formcontrol-checkradio-size:                20px;
/*  --formcontrol-checkradio-bordercolor:         var(--color-purple1);*/
  /*--formcontrol-checkradio-borderwidth:         2px;*/
  --formcontrol-checkradio-borderradius:        4px;
  --formcontrol-check-font:    400 14px/24px var(--fontawesome);
}



@media (max-width: 500px)
{
  html
  {
    --formcontrols-height:             44px;
    --formcontrols-radius:              4px;
  }

  .pageheader
  , .header-searchpanel-content
  {
    --textcontrols-font: 16px var(--rtd-text-fontfamily);
  }
}

.wh-form__fieldgroup
{
  --button-height:     var(--formcontrols-height);
/*  --button-font:       bold 19px var(--button-fontfamily);*/
}
