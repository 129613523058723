.widget-quicklinkcolumns
{
  grid-column: var(--pagegrid-col-verywide);
}

.quicklinkcolumn
{
  background:    var(--contentpanels-background);
  border-radius: var(--contentpanels-radius);
  box-shadow:    var(--common-itemblocks-box-shadow);
  padding:       var(--common-itemblocks-padding);
}

.quicklinkcolumn__title
{
  grid-row: 1;
  margin-bottom: 30px;
}

.quicklinkcolumn ul.quicklinkcolumn__links
{
  grid-row: 2;
  margin: 0;
  padding: 0;
  list-style: none;
}

.quicklinkcolumn__button
{
  margin-top: 35px;

  grid-row: 3;
  justify-self: start;
}

.quicklinkcolumn__links li:focus-within
{
  outline:        var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);
  border-radius:  var(--contentpanels-radius);
}

.quicklinkcolumn__links li:focus-within a
{
  outline: none;
}


@media (max-width: 999px)
{
  .quicklinkcolumn + .quicklinkcolumn
  {
    margin-top: 48px;
  }
}


@media (min-width: 1000px)
{
  .widget-quicklinkcolumns
  {
    grid-column: var(--pagegrid-col-verywide);
    display: flex;
  }

  .quicklinkcolumn
  {
    flex: 1 1 50%;

    display: grid;
    grid-template-rows: min-content 1fr min-content;
  }

  .quicklinkcolumn + .quicklinkcolumn
  {
    margin-left: 48px;
  }
}



/* FIXME:  share with .quicklinkcolumn__links */
ul.quicklinkcolumn__links
{
  margin-top: 15px;
  font: var(--rtd-text-compact-font);
  color: var(--color-theme);
}

ul.quicklinkcolumn__links li
{
  display: block;
  padding-left: var(--answer-indent);
}

ul.quicklinkcolumn__links a
{
  display: flex;
  align-items: baseline;

  text-decoration: none;
  color: inherit;
}

ul.quicklinkcolumn__links a span
{
  display: block;
  padding: 6px 0;
  width: 100%;
}

ul.quicklinkcolumn__links li + li a span
{
  border-top: 1px solid #CCCCCC;
}

ul.quicklinkcolumn__links a:hover
{
  background-color: #F8F8F8;
}

ul.quicklinkcolumn__links a:hover span
{
  text-decoration: underline;
}

ul.quicklinkcolumn__links a::before
{
  flex: none;
  width: var(--answer-indent);
  margin-left: calc(var(--answer-indent) * -1);

  font: var(--fontawesome-light);
  content: var(--fa-long-arrow-right);
  font-size: 20px;
}
