/*@import "../shared/config.scss";*/

.sidebar
{
  /* NEW settings (SU + ACOi/ZVI) */
  --sidebar-menu-itemhover-background:     transparent;
  --sidebar-menu-itemhover-textcolor:      var(--color-theme);
  --sidebar-menu-itemhover-textdecoration: solid underline var(--color-theme) 2px;


  --sidemainmenu-padding-top:     5px;
  --sidemainmenu-padding-right:  24px;
  --sidemainmenu-padding-bottom: 24px;
  --sidemainmenu-padding-left:   24px;


  /* Sidebar header */
  --sidebar-logo-height: 30px;



  --sidebar-content-indent: 0px; /* To align content with the menu (in case the toggle icons are in front of the menu item titles) *//

  --sidebar-closebutton-color: var(--color-purple1);
  /*--sidebar-closebutton-size:  24px;*/
  --sidebar-closebutton-size:  20px;


  /*
  --sidebar-secondarylinks-color:    #000000;
  --sidebar-secondarylinks-font:     16px var(--rtd-heading-fontfamily);
  */
  --sidebar-secondarylinks-color:    var(--color-theme);
  --sidebar-secondarylinks-font:     15px var(--rtd-text-fontfamily);

  --sidemainmenu-toggler-width:  25px;
  --sidemainmenu-toggler-margin: 20px;
  --sidebar-menu-toggle-color:       var(--color-purple1);
  --sidebar-menu-toggle-color-hover: var(--color-purple1);
  --sidebar-menu-toggle-fontsize:    16px;



  /* Togglers level 1 */
  --sidebar-menu-level1-toggleicon-font:              var(--fontawesome-regular);
  --sidebar-menu-level1-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level1-toggleicon-expanded-content:  var(--fa-chevron-down);
  --sidebar-menu-level1-toggleicon-fontsize:          14px;

  /* Togglers level 2 */
  --sidebar-menu-level2-toggleicon-font:              var(--fontawesome-regular);
  --sidebar-menu-level2-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level2-toggleicon-expanded-content:  var(--fa-chevron-down);
  --sidebar-menu-level2-toggleicon-fontsize:          14px;

  /* Togglers level 3 */
  --sidebar-menu-level3-toggleicon-font:              var(--fontawesome-regular);
  --sidebar-menu-level3-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level3-toggleicon-expanded-content:  var(--fa-chevron-down);
  --sidebar-menu-level3-toggleicon-fontsize:          14px;



  /* Togglers level 1 */
  --sidebar-menu-level1-font:      16px/19px var(--rtd-heading-fontfamily);
  --sidebar-menu-level1-padding:   8px 0px 8px 0px;
  --sidebar-menu-level1-expandedbgcolor: transparent;
  --sidebar-menu-level1-textcolor: var(--color-purple1);

  /* Menu item at level 2 */
  /* Levels 2, 3 and 4 are within the pulldown */
  --sidebar-menu-level2-font:      14px/19px var(--rtd-heading-fontfamily);
  --sidebar-menu-level2-padding:   8px 10px 8px 19px;
  --sidebar-menu-level2-expandedbgcolor: transparent;
  --sidebar-menu-level2-textcolor: #333333;

  /* Menu item at level 3 */
  --sidebar-menu-level3-font:      14px/19px var(--rtd-heading-fontfamily);
  --sidebar-menu-level3-padding:   8px 10px 8px 36px; /* + 17px */
  --sidebar-menu-level3-textcolor: var(--color-purple-dark);

  /* Menu item at level 4 */
  --sidebar-menu-level4-font:      12px/16px var(--rtd-heading-fontfamily);
  --sidebar-menu-level4-padding:   8px 10px 8px 53px; /* + 17px */
  --sidebar-menu-level4-textcolor: var(--color-purple-dark);
}

.sidemainmenu__item__link.sidemainmenu__item__link
{
  /*
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: 2px;
  */
  text-underline-offset: 3px;

  display: flex;
}

.sidemainmenu__item__link.sidemainmenu__item__link > *
{
  flex: 1 1 0px;
}

.sidemainmenu__item__toggle.sidemainmenu__item__toggle
{
  flex: none;
  position: relative;
  order: 2;

  margin-left: auto;
  padding-left: 10px;

  display: block;
  overflow: hidden;
  text-underline-offset: 30px;
  text-decoration-color: transparent;
}

/*
  .sidemainmenu-level1__selected.sidemainmenu-level1__selected
, .sidemainmenu-level2__selected.sidemainmenu-level2__selected
, .sidemainmenu-level3__selected.sidemainmenu-level3__selected
, .sidemainmenu-level4__selected.sidemainmenu-level4__selected
{
  text-decoration:           underline;
  text-decoration-color:     var(--color-salmon);
  text-decoration-thickness: 2px;
  text-underline-offset:     4px;
}
*/

.sidebar__header.sidebar__header
{
  margin-left: 10px;
}

.sidebar__header__close:hover
{
  color: var(--color-theme);
}

.sidebar__header__close:hover::before
{
  font-weight: 900;
}

.sidebar__search
{
}


  .sidebar__search
, .sidebar__relatedwebsites
, .sidebar__languages
{
  margin-top: 20px;
}

.sidebar__secondarylinks
{
  margin-top: 46px;
}


/* When used in a tight horizontal space,
   ensure long words (which cannot wrap) get ellipsis. */
.sidemainmenu__item__link__text
{
/*  display: block;*/
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
}


/* Highlight the current path */

  .sidemainmenu-level2__itemlink.sidemainmenu--currentpath
, .sidemainmenu-level2__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level3__item
, .sidemainmenu-level2__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level4__item
, .page__currentlevel_nav .sidemainmenu-level1__item
{
  position: relative;
}

  .sidemainmenu-level2__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level3__item::after
/*, .page__currentlevel_nav .sidemainmenu-level1__item::after*/
, .page__currentlevel_nav .sidemainmenu-level1__item::after
{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
  border-left: 5px solid var(--color-purple3);
}

  .sidemainmenu-level2__itemlink.sidemainmenu--currentpath::after
/*, .content-sidemenu__currentlevel::after*/
, .page__currentlevel_nav .sidemainmenu-level1__item.sidemainmenu--currentpath::after
{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
  border-left: 5px solid var(--color-purple1);
}


.sidemainmenu-level3__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level4__item
{
/*  outline: 2px solid #F00;*/
}

.sidemainmenu-level3__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level4__item::after
{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
  border-left: 5px solid #F5F5F5; /* var(--color-purple3); */
}

.sidemainmenu-level4__item:first-child
{
  padding-top: 2px; /* instead of 9px */
}

.sidemainmenu-level4__item:last-child
{
  padding-bottom: 5px; /* instead of 9px */
}
